<template>
    <div class="segment-block mb-2" :class="processTagColor(segment, supportFlowStatus, previewSegment)">
        <div class="tick" v-if="segmentDraft(segment)">一時保存</div>
        <div class="tick" v-if="segmentConfirm(segment)">確定</div>
        <div class="header d-flex justify-space-between p-0 pl-4" :class="classSegment(segment)">
            <div @click="checkSetSegmentBlockL(segment)" class="segment-title cursor-pointer">
                <SegmentTitle :segment="segment" :splitFirstSupportFlag="segment.split_first_support_flag" />
            </div>
            <span class="nav-link dropdown-toggle " id="select" data-bs-placement="top" data-bs-toggle="dropdown" aria-expanded="false"></span>
            <ul class="dropdown-menu" aria-labelledby="select">
                <li class="dropdown-item" v-if="canAssignTask(segment)" @click="onHandleClickDropdownBlock(0, segment)">{{ this.$t("dashboard_support.support_flow.task_assigment") }}</li>
                <li class="dropdown-item" v-if="canShowInBlock(segment)" @click="onHandleClickDropdownBlock(1, segment)">{{ this.$t("dashboard_support.support_flow.column_support_1") }}</li>
                <li class="dropdown-item" v-if="canShowInBlock(segment)" @click="onHandleClickDropdownBlock(2, segment)">{{ this.$t("dashboard_support.support_flow.column_support_2") }}</li>
                <li class="dropdown-item" v-if="checkSupportFinal(segment) && supportByPreviewSegment(previewSegment, segment) && isHealthGuidanceDivAssertive" @click="onHandleClickDropdownBlock(3, segment)">直前支援をもとに評価</li>
                <li class="dropdown-item" v-if="canStatusSettingAction(segment)" @click="settingStatus(segment)">ステータス設定</li>
            </ul>
        </div>
        <span v-if="checkShowSettingStatus(segment)" class="position-absolute text-danger rounded pl-1 pr-1" style="left:43%;top:13%; border: 1px solid red;">督促</span>
        <div class="body p-2 pl-4">
          <div class="infoSegmentRow">
            <div class="infoSegmentRowDetail">
              <div class="w-25-p position-relative">
                予定 <span v-if="segment?.support_stop_flag == false && typeof segment?.support_assign_memo === 'string'">
                  <i class="fas fa-pencil cursor-pointer position-absolute" @click="showDialog = !showDialog"></i>
                </span>
              </div>
            </div>
            <div class="infoSegmentRowDetail">
              <div class="w-25-p">実施</div>
            </div>
          </div>

          <div class="infoSegmentRow">
            <div class="infoSegmentRowDetail">
              <div class="label">
                依頼者:
              </div>
              <div class="contentSegment">
                {{supportAssignorUser}}
              </div>
            </div>

            <div class="infoSegmentRowDetail">
            </div>
          </div>

          <div class="infoSegmentRow">
            <div class="infoSegmentRowDetail">
              <div class="label">
                担当者:
              </div>
              <div class="contentSegment">
                {{scheduleSupportStaffUser}}
              </div>
            </div>

            <div class="infoSegmentRowDetail">
              <div class="label">
                担当者:
              </div>
              <div class="contentSegment">
                {{supportStaffUser}}
              </div>
            </div>
          </div>

          <div class="infoSegmentRow">
            <div class="infoSegmentRowDetail">
              <div class="label">
                <template v-if="segment?.support_stop_flag === 0
                                && [PLAN].indexOf(parseInt(segment?.support_progress_status)) >= 0
                                && segment?.scheduled_support_datetime !== ''">
                  <span class="text-schedule-date">計画日:</span>
                </template>
                <template v-if="segment?.support_stop_flag === 0
                                && [SCHEDULE, IN_PROGRESS, CONFIRM].indexOf(parseInt(segment?.support_progress_status)) >= 0
                                && segment?.plan_support_datetime !== ''">
                  予定日:
                </template>
              </div>
              <div class="contentSegment">
                <template v-if="segment?.support_stop_flag === 0
                                && [PLAN].indexOf(parseInt(segment?.support_progress_status)) >= 0
                                && segment?.scheduled_support_datetime != ''">
                    <span class="text-schedule-date">
                        {{planSupportTimeDisp}}
                    </span>
                </template>
                <template v-if="segment?.support_stop_flag === 0
                                && [SCHEDULE, IN_PROGRESS, CONFIRM].indexOf(parseInt(segment?.support_progress_status)) >= 0
                                && segment?.plan_support_datetime != ''">
                    {{scheduleSupportTimeDisp}}
                </template>
              </div>
            </div>

            <div class="infoSegmentRowDetail">
              <div class="label">
                実施日:
              </div>
              <div class="contentSegment">
              {{
                  segment?.support_date && segment?.support_stop_flag == false && segment?.support_progress_status == 40
                  ? (segment?.support_date).substr(-5).replace('-', '/') : "" || '-'
              }}
              </div>
            </div>
          </div>

          <div class="infoSegmentRow">
            <div class="infoSegmentRowDetail">
              <div class="label" v-if="!checkSupportFinal(segment)">
                形態:
              </div>
              <div class="contentSegment" v-if="!checkSupportFinal(segment)">
                <template v-if="checkSupportFirst(segment) || checkSupportFinal(segment)">
                  {{ segment?.health_support_form?.support_form_name_disp_02 || '-'}}
                </template>

                <template v-else-if="checkSupportA(segment) || checkSupportB(segment) || checkMidEval(segment)">
                    <template v-if="segment?.health_support_form?.support_form_name_disp_01 || segment?.support_amount">
                      {{ segment?.health_support_form?.support_form_name_disp_01 }}({{ segment?.scheduled_support_amount }}{{ segment?.health_support_form?.support_amount_unit }})
                    </template>
                    <template v-else>
                            -
                    </template>
                </template>
              </div>

            </div>

            <div class="infoSegmentRowDetail">
              <div class="label">
                形態:
              </div>
              <div class="contentSegment">
                <template v-if="checkSupportFirst(segment) || checkSupportFinal(segment)">

                  <template v-if="segmentDraft(segment) || segmentConfirm(segment)">
                    {{
                        (segment?.support_stop_flag == false && segment?.support_progress_status == 40) ?
                          segment?.health_support_form?.support_form_name_disp_02 : '-'
                    }}
                  </template>
                  <template v-else>-</template>

                </template>

                <template v-else-if="checkSupportA(segment) || checkSupportB(segment) || checkMidEval(segment)">
                          <template v-if="segment?.support_stop_flag == false && codeSupportProgressConfirm == segment?.support_progress_status && (segment?.health_support_form?.support_form_name_disp_01 || segment?.support_amount)" >
                              {{ segment?.health_support_form?.support_form_name_disp_01 }}({{ segment?.support_progress_status == 40 ? segment?.support_amount : segment?.scheduled_support_amount }}{{ segment?.health_support_form?.support_amount_unit }})
                          </template>
                          <template v-else>
                              -
                            </template>
                </template>

              </div>
            </div>
          </div>

          <div class="infoSegmentRow" v-if="checkSupportFirst(segment)">
            <div class="infoSegmentRowDetail">
              <div class="label">
                場所:
              </div>
              <div class="contentSegment">
                {{segment?.scheduled_support_location?.item_name || '-'}}
              </div>
            </div>

            <div class="infoSegmentRowDetail">
            </div>
          </div>
          <div class="infoSegmentRow" v-if="!checkSupportFirst(segment)  && !checkSupportFinal(segment)">
            <div class="infoSegmentRowDetail">
              <div class="label">
                {{ $t('dashboard_support.support_flow.point') }}:
              </div>
              <div class="contentSegment">
                {{segment?.scheduled_support_point || '-'}}<span v-if="segment?.scheduled_support_point">pt</span>
              </div>
            </div>

            <div class="infoSegmentRowDetail">
              <div class="label">
                {{ $t('dashboard_support.support_flow.point') }}:
              </div>
              <div class="contentSegment">
                {{ segment?.support_point && segment?.support_stop_flag == false && segment?.support_progress_status == 40 ? segment?.support_point : '-' }}<span v-if=" segment?.support_point && segment?.support_stop_flag == false && segment?.support_progress_status == 40">pt</span>
              </div>
            </div>
          </div>

            <div class="d-flex justify-content-end align-items-center icon-error" v-if="isShowOverdue">
                <div>
                    <v-icon color="red" large>mdi-alert-outline</v-icon>
                </div>
            </div>
        </div>
        <div class="footer">

        </div>
        <Dialog
            :isShow="showDialog"
            :maxWidth="400"
            persistent
        >
            <v-card>
                <v-container>
                    <v-card-title>メモ</v-card-title>
                    <div class="m-3">{{segment?.support_assign_memo}}</div>
                    <v-card-actions class="d-flex align-content-center justify-content-center mr-2 ml-2">
                        <button type="button" class="btn btn-secondary" @click="showDialog = false">閉じる</button>
                    </v-card-actions>
                </v-container>
            </v-card>
        </Dialog>
        <Dialog :maxWidth="600" :isShow="showDialogSettingStatus">
          <v-card>
        <v-container>
          <div class="m-3">
            <div class="form-check">
                <input type="checkbox" v-model="dataSegment.segment_status_01" class="form-check-input" id="督促">
                <label for="督促" class="form-check-label">督促</label>
            </div>
          </div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button
              type="button"
              class="btn btn-secondary"
              @click="showDialogSettingStatus = false"
            >
              キャンセル
            </button>
            <button @click="saveSettingStatus(); showDialogSettingStatus = false" type="button" class="btn btn-primary float-right">
              設定
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
        </Dialog>
        <Dialog :maxWidth="600" :isShow="isShowConfirmForEvalByPrevSupport" persistent>
              <v-card>
                  <v-container>
                      <v-card-title>
                          確認
                      </v-card-title>
                      <div class="m-3">
                          直前支援をもとに評価処理を実行します。よろしいですか？
                      </div>
                      <v-card-actions
                          class="
                                d-flex
                                align-content-center
                                justify-content-between
                                mr-2
                                ml-2
                          "
                      >
                          <button
                              type="button"
                              class="btn btn-secondary"
                              @click="isShowConfirmForEvalByPrevSupport = false">
                              キャンセル
                          </button>
                          <button
                              type="button"
                              class="btn btn-primary"
                              @click="copySegmentEval()">
                              OK
                          </button>
                      </v-card-actions>
                  </v-container>
              </v-card>
          </Dialog>
          <div class="black-mask" v-if="isCanceled">
            <div class="cancel-message">
              キャンセルされました
            </div>
          </div>
    </div>
</template>

<style scoped lang="sass">
    .dropdown-toggle::after
        color: #333
    ul
        margin: 0
        padding: 0
    .segment-block
        min-width: 450px
        max-width: 450px
        min-height: 175px
        height: 175px
        max-height: 175px
        border: 1px solid #cdcdcd
        position: relative
        > .tick
            position: absolute
            top: 22px
            right: 5px
            transform: rotate(-0deg)
            border: 1px solid rgb(0 0 255 / 50%)
            color: rgb(0 0 255 / 90%)
            background: rgba(255,255,255,0.7)
            padding: 2px
            border-radius: 5px

    .segment-title
        width: 90%
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
    .cursor-pointer
        cursor: pointer
    .ellipsis
        text-overflow: ellipsis
    .w-25-p
        width: 170px
        border-bottom: 1px solid #333
    .block-segment-white
      padding-left: 6px
      position: relative
      &:after
          content: ""
          position: absolute
          top: 0
          left: 0
          width: 17px
          height: 100%
          background: #fff
          border: 1px solid #333
    .block-segment-gray
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: gray
            border: 1px solid #333

    .block-segment-blue
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: #eedcf2
            color: #3b043b
            border: 1px solid #333
    .block-segment-pink
        padding-left: 6px
        position: relative
        &:after
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 17px
            height: 100%
            background: pink
            border: 1px solid #333

    .block-segment-green
      padding-left: 6px
      position: relative
      &:after
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 17px
        height: 100%
        background: green
        border: 1px solid #333
    .infoSegmentRow
      display: flex
      justify-content: space-between
      .infoSegmentRowDetail
        width: 49%
        display: flex
        .label
          width: 37%
        .contentSegment
          flex: 1
          text-overflow: ellipsis
          /* Needed to make it work */
          overflow: hidden
          white-space: nowrap

    .icon-error
        margin-top: -7px

    .fa-pencil
        right: 0
        top: 30%

    .black-mask
      background-color: rgba(0, 0, 0, 0.6)
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      display: flex

    .cancel-message
      color: white
      margin: auto

</style>

<script>

import SegmentTitle from "@/components/dashboard/SegmentTitle";
import {mapMutations} from "vuex";
import mixins from "@/mixins";
import moment from "moment";
import Dialog from "@/components/common/Dialog";
import DashboardService from "@/services/api/DashboardRepository";
import {
    HEALTH_GUIDANCE_DIV_ASSERTIVE,
} from "@/common/constants";

export default {
    name: "SegmentBlock",
    props: {
        reception: null,
        segment: null,
        previewSegment: null,
        nextSegment: null,
        currentDataFlowSegment: null,
        supportFlowStatus: null,
        systemUserRole: null,
    },
    components: {
        SegmentTitle,
        Dialog
    },
    mixins: [
        mixins
    ],
    data() {
        return {
            dataSegment: {
                id: null,
                segment_status_01: false,
            },
            isShowConfirmForEvalByPrevSupport: false,
            showDialog: false,
            showDialogSettingStatus: false,
            codeSupportProgressConfirm: 40,
            listTitleSupport: [
                {
                    text: this.$t("dashboard_support.support_flow.task_assigment"),
                    value: 0
                },
                {
                    text: this.$t("dashboard_support.support_flow.column_support_1"),
                    value: 1
                },
                {
                    text: this.$t("dashboard_support.support_flow.column_support_2"),
                    value: 2
                },
                {
                    text: "直前支援をもとに評価",
                    value: 3
                }
            ],
            codeStatusSupportFirst: 10,
            codeStatusSupportA: 20,
            codeStatusSupportB: 30,
            codeStatusSupportFinal: 40,

            CODE_SUPPORT_EXEC_DIV_FIRST: 10,
            CODE_SUPPORT_EXEC_DIV_CONTINUE: 20,
            CODE_SUPPORT_EXEC_DIV_FINISH: 30,

            //support_progress_status_code
            PLAN: 10,
            SCHEDULE: 20,
            IN_PROGRESS: 30,
            CONFIRM: 40,
            tabs: {
                "support_input": true,
                "audience_response": true,
                "message": true,
                "call_record": true,
            },
        };
    },
    computed: {
        isShowOverdue() {
            const dateCurrent = moment(new Date()).format("YYYY-MM-DD")
            if (parseInt(this.segment?.support_progress_status) === this.PLAN && this.segment?.support_stop_flag != 1) {
                if (!this.segment || !this.segment.plan_support_date) return false
                return this.segment?.plan_support_date < dateCurrent
            }

            if ([this.SCHEDULE, this.IN_PROGRESS].includes(parseInt(this.segment?.support_progress_status)) && this.segment?.support_stop_flag != 1) {
                return this.segment?.scheduled_support_date < dateCurrent
            }

            return false
        },
        // NOTE: おそらく未使用
        listDropdown() {
            return this.listTitleSupport
        },
        planSupportTimeDisp() {
            if (!this.segment?.plan_support_datetime) return "-";
            let stringDate = this.segment?.plan_support_date ? (this.segment?.plan_support_date).substr(-5).replace("-", "/") : "-";
            let stringTime = this.segment?.plan_support_time ? (this.segment?.plan_support_time).substr(0,5) : "";
            return stringDate + " " + stringTime
        },

        scheduleSupportTimeDisp() {
            if (!this.segment?.scheduled_support_datetime) return "-";
            let stringScheduledSpDate = this.segment?.scheduled_support_date ? (this.segment?.scheduled_support_date).substr(-5).replace("-", "/") : "-"
            let stringScheduledSpTime = this.segment?.scheduled_support_time ? (this.segment?.scheduled_support_time).substr(0,5) : ""
            return stringScheduledSpDate + " " + stringScheduledSpTime;
        },
        supportAmountDisp() {
            let sp_form_name = this.segment?.health_support_form?.support_form_name_disp || "-";
            let sp_amount = this.segment?.support_amount || "-"
            let unit = this.segment?.health_support_form?.support_amount_unit || "-"

            if (sp_form_name == sp_amount) return "-"

            return sp_form_name + "、" + sp_amount + unit
        },
        scheduleAmountDisp() {
            let sp_form_name = this.segment?.health_support_form?.support_form_name_disp || "-";
            let sp_amount = this.segment?.scheduled_support_amount || "-"
            let unit = this.segment?.health_support_form?.support_amount_unit || "-"

            if (sp_form_name == sp_amount) return "-"

            return sp_form_name + "、" + sp_amount + unit
        },
        supportAssignorUser() {
            if (this.segment?.support_assignor_user) {
                return this.segment?.support_assignor_user?.user_name_disp
            }
            return "-"
        },
        scheduleSupportStaffUser() {
            if (this.segment?.schedule_support_staff_user) {
                return this.segment?.schedule_support_staff_user?.user_name_disp
            }
            return "-"
        },
        supportStaffUser() {
            if (this.segment?.support_staff_user && this.segment?.support_stop_flag == false && this.segment?.support_progress_status == 40) {
                return this.segment?.support_staff_user?.user_name_disp
            }
            return "-"
        },
        // キャンセルされた
        isCanceled() {
            return parseInt(this.segment.support_progress_status) == 50
        },

        // 積極的支援かどうか
        isHealthGuidanceDivAssertive() {
            if (this.reception && this.reception.health_guidance_div == HEALTH_GUIDANCE_DIV_ASSERTIVE) {
                return true
            } else {
                return false;
            }
        },
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            openSlide: "showSlide",
            setSegmentBlockA: "dashboard/setSegmentBlockA",
            setSegmentBlockB: "dashboard/setSegmentBlockB",
            setSegmentBlockL: "dashboard/setSegmentBlockL",
            setSegment: "dashboard/setSegment",
            setSegmentCurrentPage: "dashboard/setSegmentCurrentPage",
        }),
        /**
         * 督促アイコンを表示させる場合true.
         * @param {*} segment 
         */
        checkShowSettingStatus(segment){
            // セグメントステータス01がON
            // かつ、支援中止フラグOFF
            // かつ、支援進捗が[計画]or[予定]or[実施中]の場合
            if(segment.segment_status_01 && 
              !segment.support_stop_flag && 
              (segment.support_progress_status == 10 || segment.support_progress_status == 20 || segment.support_progress_status == 30)){
                return true;
            }
            return false;
        },
        /**
         * ステータス設定ダイアログ表示.
         * @param {*} segment 
         */
        settingStatus(segment){
            this.showDialogSettingStatus = true
            this.dataSegment = {
                id: segment.id,
                segment_status_01: !segment.segment_status_01 ? 0 : 1,
            }
        },
        /**
         * ステータス設定 保存.
         */
        saveSettingStatus(){
            DashboardService.settingStatusSegment(this.dataSegment).then(res => {
                const { data, status, error } = res;
                if (status !== 200) {
                    this.$toast.open({
                        message: error.message,
                        type: "error",
                    });
                } else {
                    this.$toast.open({
                        message: "ステータス設定の登録が完了しました。",
                        type: "success",
                    });
                    this.$root.$emit("Dashboard.ReloadDataReception");
                }
            })
        },
        checkSetSegmentBlockL(data){
            this.$emit("resetPoint");
            this.$root.$emit("actionTabs", { tab: null, tabControl: null})
            // this.setSegmentBlockL(null);
            this.setSegment(data);
            this.setSegmentCurrentPage({ "previous": this.previewSegment, "next": this.nextSegment, "current_segment": this.currentDataFlowSegment })
            if (this.checkSplitFirstSupportSecond(data)) {
                // 初回面接　分割実施2回目
                this.$root.$emit("segmentBlockChange", "L2");
                this.handleActivationControl("support_first_split");
                this.$root.$emit("actionTabs", { tab: "support_first_split", tabControl: this.tabs})
            } else if (this.checkSupportFirst(data)) {
                // 初回面接
                this.$root.$emit("segmentBlockChange", "L1");
                this.handleActivationControl("support_first");
                this.$root.$emit("actionTabs", { tab: "support_first", tabControl: this.tabs})
            } else if (this.checkSupportFinal(data)) {
                // 最終評価
                this.$root.$emit("segmentBlockChange", "L3");
                this.handleActivationControl("support_last");
                this.$root.$emit("actionTabs", { tab: "support_last", tabControl: this.tabs})
            } else {
            // else if (this.checkSupportA(data) || this.checkSupportB(data) || this.checkMidEval(data)) {
                // 継続支援・中間評価
                this.$root.$emit("segmentBlockChange", "L2");
                this.handleActivationControl("support_continuous");
                this.$root.$emit("actionTabs", { tab: "support_continuous", tabControl: this.tabs})
            }
            this.$root.$emit("tagColor", { classTag: this.processTagColor(this.segment, this.supportFlowStatus, this.previewSegment) });
        },
        handleActivationControl(tab) {
            switch (parseInt(this.supportFlowStatus)) {
            case 10: //支援開始前
                if (tab === "support_first" || tab === "support_first_split") {
                    this.tabs.support_input = this.handleStatusControlSupportInput();
                }
                // 対象セグメントが「確定」かつログインユーザの権限が「システム管理者」or「事務局」
                if (parseInt(this.segment?.support_progress_status) === this.CONFIRM
                    && (this.systemUserRole === 1 || this.systemUserRole === 4) ) {
                    this.tabs.message = false;
                    this.tabs.call_record = false;
                }
                break;
            case 20: //支援実施中
                // health_support_flows.support_flow_status = 20 is support in progress
                this.tabs.support_input = this.handleStatusControlSupportInput();
                // 対象支援セグメントが未確定
                // or
                // 対象セグメントが「確定」かつログインユーザの権限が「システム管理者」or「事務局」
                if (parseInt(this.segment?.support_progress_status) !== this.CONFIRM
                    ||
                    (parseInt(this.segment?.support_progress_status) === this.CONFIRM && (this.systemUserRole === 1 || this.systemUserRole === 4))
                ) {
                    this.tabs.message = false;
                    this.tabs.call_record = false;
                }
                break;
            case 30: //支援完了
                // 対象セグメントが「確定」かつログインユーザの権限が「システム管理者」or「事務局」
                if (
                    parseInt(this.segment?.support_progress_status) === this.CONFIRM &&
                      (this.systemUserRole === 1 || this.systemUserRole === 4)
                ) {
                    this.tabs.support_input = this.handleStatusControlSupportInput();
                    this.tabs.message = false;
                    this.tabs.call_record = false;
                }
                break;
            default: // 支援完了 or 途中終了 or  初回面接辞退
                // health_support_flows.support_flow_status other (30,40,50) then keep the default value
                break;
            }
            return this.tabs;
        },

        // handle support_input
        handleStatusControlSupportInput() {
            // condition support_progress_status and systemUserRole is the same so just check previewSegment support_progress_status
            // confirm 21/11, handle * 2 always is true
            return false;
        },
        onHandleClickDropdownBlock(index, data) {
            switch (index) {
            case 0:
                this.setSegment(data);
                this.$emit("showAssignTask", data);
                break;
            case 1:
                this.setSegmentBlockA(data);
                this.$root.$emit("setSegmentBlockA", data)
                break;
            case 2:
                this.setSegmentBlockB(data);
                this.$root.$emit("setSegmentBlockB", data)
                break;
            case 3:
                this.confirmCopySegmentEval();
                break;
            }
        },
        checkSupportFirst(segment) {
            return parseInt(segment?.support_exec_div) === this.codeStatusSupportFirst;
        },
        checkSupportA(segment) {
            return parseInt(segment?.support_exec_div) === this.codeStatusSupportA;
        },
        checkSupportB(segment) {
            return parseInt(segment?.support_exec_div) === this.codeStatusSupportB;
        },
        checkMidEval(segment) {
            return segment?.mid_eval_flg;
        },
        /**
         * ステータス設定可能な場合true.
         * @param {*} segment 
         */
        canStatusSettingAction(segment) {
            return segment.support_stop_flag === 0 &&
                [this.PLAN, this.SCHEDULE, this.IN_PROGRESS].indexOf(parseInt(segment.support_progress_status)) >= 0
        },
        /**
         * タスク割当可能な場合true.
         * @param {*} segment 
         */
        canAssignTask(segment) {
            return segment.support_stop_flag === 0 &&
                [this.PLAN, this.SCHEDULE, this.IN_PROGRESS].indexOf(parseInt(segment.support_progress_status)) >= 0
        },
        /**
         * 支援欄に表示可能な場合true.
         * @param {*} segment 
         */
        canShowInBlock(segment) {
            return parseInt(segment.support_progress_status) === this.CONFIRM
        },
        /**
         * 直前支援のチェック.
         * @param {*} previewSegment 
         * @param {*} segment 
         */
        supportByPreviewSegment(previewSegment, segment) {
            // 直前支援が
            //   ・継続支援
            //   ・進捗が確定
            // 対象セグメントが
            //   ・「計画」or「予定」
            //   ・支援中止フラグがOFF
            if (
                parseInt(previewSegment.support_exec_div) === this.CODE_SUPPORT_EXEC_DIV_CONTINUE &&
                parseInt(previewSegment.support_progress_status) === this.CONFIRM &&
                segment.support_stop_flag === 0 &&
                (parseInt(segment.support_progress_status) === this.PLAN || parseInt(segment.support_progress_status) === this.SCHEDULE || parseInt(segment.support_progress_status) === this.IN_PROGRESS)
            ) {
                return true;
            }
            return false
        },
        /**
         *  「直前支援をもとに評価」実行前確認ダイアログ.
         */
        confirmCopySegmentEval() {
            this.isShowConfirmForEvalByPrevSupport = true;
        },
        /**
         * 直前支援をもとに評価.
         */
        async copySegmentEval() {

            this.isShowConfirmForEvalByPrevSupport = false;

            try {

                this.setIsLoading(true);

                await DashboardService.copySegmentEval(this.previewSegment.id, this.segment.id).then(res => {
                    this.setIsLoading(false);
                    if (res.data.data) {
                        this.$root.$emit("Dashboard.ReloadDataReception");
                        this.notifySuccess("直前支援をもとに評価処理が完了しました。")
                    } else {
                        this.notifyError("直前支援をもとに評価でエラーが発生しました。")
                    }
                })
            } catch (e) {
                this.notifyError("処理中に予期しないエラーが発生しました。");
                return this.setErrors(e);
            }
        },
        setErrors(e) {
            console.log(e);
        },
    }
}
</script>
