<template>

    <div v-if="checkShowDashboard" class="dashboard">
      <div>
        <div style="float: left;">
        <span style="cursor: pointer;" @click="switchDispInfo"><i class="fa-solid fa-arrows-spin fa-2x text-primary"></i></span>
      </div>
      <div style="float: right;">
        <span>{{ title_information }}</span>
      </div>
      </div>

      <div class="container-fluid m-0 p-0 h-100">
        <div class="infoDashboard row no-gutters h-100">
          <!-- ########## 対象者/受付情報/健診結果 ########## -->
          <div class="infoDashboardLeft col-3" id="infoDashboardLeft">
            <div class="d-flex h-100">
              <div class="flex-column w-100">
                <div class="h-50">
                  <PersonInformation :reception_id="reception_id"></PersonInformation>
                </div>
                <div class="h-50">
                  <HealthResult :reception_id="reception_id"></HealthResult>
                </div>
              </div>
            </div>
          </div>
          <!-- ########## 支援情報欄 ########## -->
          <div class="infoDashboardMid col-3" id="infoDashboardMid">
            <div class="h-100">
              <SupportInfo></SupportInfo>
            </div>
          </div>
          <!-- ########## 支援領域 ########## -->
          <div class="infoDashboardRight col-6" id="infoDashboardRight">
            <SupportFlow
                v-if="health_support_flows"
                :flows="health_support_flows"
                :scheduled-first-support-datetime="patient.scheduled_first_support_datetime"
                :reception="reception"
                :insurerId="patient.insurer_id"
                :systemUserRole="systemUserRole"
                :planningPoint="planningPoint"
                @changePoint="onChangePoint"
            ></SupportFlow>

            <div class="d-flex flex-column h-100" v-if="block">

              <div>
                <TitleL :classTagColor="classTagColor" :title="block" :segment="segment" :healthSupportFlows="health_support_flows" :class="classSegment(segment)"></TitleL>

                <div v-if="segment" class="pt-2">
                  <div class="d-flex">
                    <label v-if="status_progress == IN_PROGRESS" class="btn btn-outline-primary no-hover mr-5">一時保存</label>
                    <label v-if="status_progress == CONFIRM" class="btn btn-outline-primary no-hover">確定</label>
                  </div>

                  <div class="d-flex flex-row-reverse">
                    <button type="button" @click="isShowClearData = true" v-if="buttonStatus" class="btn btn-outline-secondary">支援内容クリア</button>
                  </div>
                </div>
              </div>

              <!-- v-if="!tabControl.support_input"  -->
              <div class="d-flex h-100 gap-4">
                <div class="w-50 pt-2">
                  <L1
                      v-if="'L1' === block"
                      :roleSystem="roleSystem"
                      :previousSupportProgressStatus="previousSupportProgressStatus"
                      :nextSupportProgressStatus="nextSupportProgressStatus"
                      :showTabs="showTabs"
                      :classTagColor="classTagColor"
                      :isViewSupportInput="tabControl.support_input"
                      @btnClearStatus="btnClearStatus"
                      :support_flow_status="supportFlow?.support_flow_status"
                      :patient="patient"
                      :reception="reception"
                      @changePoint="onChangePoint"
                  ></L1>
                  <L2
                      v-if="'L2' === block"
                      :roleSystem="roleSystem"
                      :previousSupportProgressStatus="previousSupportProgressStatus"
                      :nextSupportProgressStatus="nextSupportProgressStatus"
                      :statusProgress="parseInt(status_progress)"
                      :classTagColor="classTagColor"
                      :showTabs="showTabs"
                      :isViewSupportInput="tabControl.support_input"
                      :supportFlow="supportFlow"
                      :support_flow_status="supportFlow?.support_flow_status"
                      :reception="reception"
                      :patient="patient"
                      :firstSegment="firstSegment"
                      :firstSegmentSplit="firstSegmentSplit"
                      @btnClearStatus="btnClearStatus"
                      @changePoint="onChangePoint"
                  ></L2>
                  <L3
                      v-if="'L3' === block"
                      :roleSystem="roleSystem"
                      :previousSupportProgressStatus="previousSupportProgressStatus"
                      :nextSupportProgressStatus="nextSupportProgressStatus"
                      :classTagColor="classTagColor"
                      :showTabs="showTabs"
                      :isViewSupportInput="tabControl.support_input"
                      :reception="reception"
                      :patient="patient"
                      :supportFlow="supportFlow"
                      @btnClearStatus="btnClearStatus"
                      @changePoint="onChangePoint"
                      :support_flow_status="supportFlow?.support_flow_status"
                  ></L3>
                </div>

                <div class="w-50 pt-2">
                  <ManagerSupport
                      v-if="segment"
                      :patient="patient"
                      :showTabs="showTabs"
                      :tabControl="tabControl"
                  ></ManagerSupport>
                </div>
              </div>
            </div >
          </div>
        </div>
      </div>
        <Dialog :maxWidth="600" :isShow="isShowClearData">
          <v-card>
        <v-container>
          <div class="m-3">
            支援内容クリア処理を実行します。よろしいですか？
          </div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button
              type="button"
              class="btn btn-secondary"
              @click="isShowClearData = false"
            >
              キャンセル
            </button>
            <button @click="btnClearL(); isShowClearData = false" type="button" class="btn btn-primary float-right">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
        </Dialog>
        <Dialog :maxWidth="400" :isShow="isShowFinalSupportMessage">
          <v-card>
          <v-container>
              <v-card-text class="text-center mt-3">
              最終評価をしてください
              </v-card-text>
              <v-card-actions>
                <v-btn @click="isShowFinalSupportMessage = false" class="mx-auto" width="150">
                  OK
                </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
        </Dialog>
    </div>
    <div v-else>
      指定された対象者は参照できません。
    </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import mixins from "@/mixins";
import PersonInformation from "@/pages/Dashboard/PersonInformation";
import SupportFlow from "@/components/dashboard/FlowSupport";
import SupportInfo from "@/components/dashboard/SupportInfo";
import HealthResult from "@/pages/Dashboard/HealthResult";
import { ROLE_CODE_USER, BTN_CLEAR } from "@/common/constants";
import {SUCCESS, ADMIN_REPOSITORY, INTERNAL_SERVER_ERROR} from "@/constants";
import ManagerSupport from "@/pages/Dashboard/ManagerSupport";
import L1 from "@/pages/Dashboard/Components/L1";
import L2 from "@/pages/Dashboard/Components/L2";
import L3 from "@/pages/Dashboard/Components/L3";
import TitleL from "@/pages/Dashboard/Components/TitleL";
import AuthRepository from "@/services/api/AuthRepository";
import Repository from "@/services/api/Repository";
import Dialog from "@/components/common/Dialog";

import {
    CODE_HEALTH_GUIDANCE_DIV,
    CODE_GENDER,
    TOAST_TIME_OUT
} from "@/common/constants";
import moment from "moment";

import DashboardService from "@/services/api/DashboardRepository";
import Vue from "vue";

export default {
    name: "Dashboard",
    data() {
        return {
            checkShowDashboard: false,
            isShowClearData: false,
            isShowFinalSupportMessage: false,
            reception_id: null,
            block: null,
            health_support_flows: null,
            patient: null,
            reception: null,
            status_progress: null,
            buttonStatus: true,

            // support_progress_status_code
            PLAN: 10,
            SCHEDULE: 20,
            IN_PROGRESS: 30,
            CONFIRM: 40,
            CANCEL: 50,

            // user roles
            userPermission: false,
            systemUserRole: null,
            nextSupportProgressStatus: null,
            previousSupportProgressStatus: null,
            roleSystem: null,
            USER_SUPPORTER: 2,
            USER_BACK_OFFICE: 4,
            classTagColor: "",
            code_health_guidance_div: [],
            patient_gender: [],
            title_information: null,
            showTabs: "",
            tabControl: {},
            display_mode: 0,

            // 加算予定ポイント
            planningPoint: {
                process: 0,
                outcome: 0,
            }
        };
    },
    mixins: [
        mixins
    ],
    components: {
        PersonInformation,
        SupportFlow,
        SupportInfo,
        HealthResult,
        L1,
        L2,
        L3,
        ManagerSupport,
        TitleL,
        Dialog
    },
    async created() {
        let that = this;
        window.scroll({
            top: 0,
            behavior: "smooth"
        }),

        this.$root.$on("checkSupportProgressStatus", function(e) {
            that.status_progress = e.status;
        })

        this.$root.$on("tagColor", function(e) {
            that.classTagColor = e.classTag;
        })

        // handle logic show tab and control tab
        this.$root.$on("actionTabs", function(e) {
            that.showTabs = e.tab;
            that.tabControl = e.tabControl;
        })

        this.$root.$on("segmentBlockChange", function(e) {
            that.block = e;
        })

        this.patient_gender = await this.getCodeDefine(CODE_GENDER, "acronym");
        this.code_health_guidance_div = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_DIV);
        this.userPermission = await this.getCodeDefine(ROLE_CODE_USER);
        this.buttonPermission();
    },
    computed: {
        ...mapGetters({
            receptionId: "dashboard/getReceptionId",
            segment: "dashboard/getSegment",
            segmentBlockL: "dashboard/getSegmentBlockL",
            getSegmentCurrentPage: "dashboard/getSegmentCurrentPage",
            user: "common/getUser"
        }),

        supportFlow() {
            return this.health_support_flows ? this.health_support_flows[0] : null;
        },


        /**
         * 初回面談
         */
        firstSegment() {
            if (this.health_support_flows.length == 0) {
                return false;
            }
            const flow = this.health_support_flows[0];
            for(let segment of flow.health_support_flow_segments) {
                if (this.checkSupportFirst(segment) && !this.checkSplitFirstSupportSecond(segment)) {
                    return segment;
                }
            }
            return null;
        },


        /**
         * 初回面談(分割実施2回目)
         */
        firstSegmentSplit() {
            if (this.health_support_flows.length == 0) {
                return false;
            }
            const flow = this.health_support_flows[0];
            for(let segment of flow.health_support_flow_segments) {
                if (this.checkSplitFirstSupportSecond(segment)) {
                    return segment;
                }
            }
            return null;
        },


        /**
         * 初回面談から３ヶ月経過しているか
         */
        isPassed3MonthsFromFirstSupport() {
            // ex) 初回面談 2020/01/02 の場合
            // 2020/04/02 以降は true
            // 2020/04/01 以前は false
            if (this.health_support_flows.length == 0) {
                return false;
            }
            if (!this.firstSegment && !this.firstSegmentSplit) {
                return false;
            }
            try {
                // 分割実施の場合は2回目の支援日を基準にする
                const firstSegment = this.firstSegmentSplit || this.firstSegment;
                if (!firstSegment.support_date) {
                    return false;
                }
                const today = moment();
                const firstSupportDate = moment(firstSegment.support_date, "YYYY-MM-DD");
                const thresholdDate = firstSupportDate.add(3, "months").add(1, "days");
                const result = today.isSameOrAfter(thresholdDate);
                return result;
            } catch (e) {
                return false;
            }
        },
    },
    watch: {
        segmentBlockL(data) {
            this.block = data;
        },
        getSegmentCurrentPage(data) {
            this.btnPermission(data);
        },
    },
    mounted() {
        this.$root.$on("Dashboard.ReloadDataReception", () => {
            this.getReceptionFlowSegments(this.reception_id)
            this.setSegmentBlockL("");
            this.block = null;
        });

        this.$root.$on("Dashboard.LoadPages", (e) => {
            this.getReceptionFlowSegments(this.reception_id)
            this.$root.$emit("clickPages", {id: e.id})
            this.setSegmentBlockL("");
            this.block = null;
        });

        this.reception_id = this.$route.query.reception_id;
        this.getReceptionFlowSegments(this.reception_id)
        this.healthGuidanceReceptionDetail(this.reception_id)
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            setSegmentBlockL: "dashboard/setSegmentBlockL",
        }),

        async healthGuidanceReceptionDetail(reception_id) {
            return await DashboardService.healthGuidanceReceptionDetail(reception_id).then(res => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                const roleUser = this.user.user_sys_auth_div.split(",");
                const SUPPORTER = 2;
                // 支援担当者ロールのみが設定されている場合.
                if(roleUser.filter(x => x == SUPPORTER).length > 0 && roleUser.length == 1){
                    if(res.data.data.health_support_flows.length > 0){
                        const health_support_flow_segments = res.data.data.health_support_flows[0].health_support_flow_segments;
                        // いずれかの支援セグメントの支援担当者or支援予定担当者に指定されている場合は閲覧可能.
                        for (let i = 0; i < health_support_flow_segments.length; i++) {
                            if(this.user.id == health_support_flow_segments[i].support_staff_id ||
                              this.user.id == health_support_flow_segments[i].scheduled_support_staff_id) {
                                this.checkShowDashboard = true;
                                break;
                            }
                        }
                    }
                } else{
                    // システム管理者 or 事務局の場合、無条件で閲覧可能.
                    this.checkShowDashboard = true;
                }

                this.healthGuidanceReception = res.data.data;
                this.patient = this.healthGuidanceReception?.patients;
                let code_health_guidance_div = this.code_health_guidance_div[this.patient.health_guidance_div] ?? "";

                this.title_information = [
                    `${this.healthGuidanceReception.contract_fiscal_year}年度`,
                    `[${String(this.patient.id).padStart(10, "0")}]`,
                    `${this.patient.patient_name_kana}/${this.patient.patient_name}`.replace("　", " "),
                    this.patient.patient_birthday,
                    this.patient_gender[this.patient.patient_gender],
                    code_health_guidance_div,
                ].join(" ");
            })
        },

        async buttonPermission() {
            this.setIsLoading(true);

            await AuthRepository.checkAuth()
                .then((res) => {
                    const { data, status } = res;
                    this.setIsLoading(false);
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) return;

                    const handlePermissions = data.user.user_sys_auth_div;
                    const dataUserRole = handlePermissions.split(",");

                    for (const [key] of Object.keys(this.userPermission)) {
                        if (dataUserRole.includes(String(key))) {
                            if (this.systemUserRole == null
                                || this.systemUserRole > parseInt(key)) {
                                this.systemUserRole = parseInt(key);
                            }
                        }
                    }
                }).catch((err) => {
                    this.setIsLoading(false);
                });
        },
        async getReceptionFlowSegments(reception_id) {
            this.reception = null;
            this.health_support_flows = null;
            this.patient = null;
            this.planningPoint.process = 0;
            this.planningPoint.outcome = 0;
            return await DashboardService.getReceptionFlowSegments(reception_id).then(res => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.reception = res.data.data;
                this.health_support_flows = res.data.data.health_support_flows;
                this.patient = res.data.data.patients;

                const flow = this.health_support_flows[0]
                const segments = flow.health_support_flow_segments;
                const finalSegment = segments.filter((segment) => parseInt(segment.support_exec_div) == this.FINAL_EVALUATION)[0];

                // 最終評価以外の支援が全て完了 (ステータスが全て終了orキャンセル)
                const isCompleteAllSegments = segments
                    .filter((segment) => parseInt(segment.support_exec_div) != this.FINAL_EVALUATION)
                    .every((segment) => {
                        const status = parseInt(segment.support_progress_status);
                        return [this.CONFIRM, this.CANCEL].includes(status);
                    });

                if (finalSegment
                && isCompleteAllSegments
                && this.isPassed3MonthsFromFirstSupport
                && parseInt(finalSegment.support_progress_status)!=this.CONFIRM) {
                // 「最終評価をしてください」のメッセージを表示する
                    this.isShowFinalSupportMessage = true;
                }
            })
        },
        btnClearL() {
            this.setIsLoading(true);
            DashboardService.btnFormSupport(this.segment.id, BTN_CLEAR).then(res => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.$root.$emit("reloadFormSupport", { type: "clear" });
                this.$root.$emit("Dashboard.LoadPages", {id: this.segment.id})
                this.$toast.open({
                    message: "支援内容のクリアが完了しました。",
                    type: "success",
                });
                this.buttonStatus = false;
                this.setIsLoading(false);
            }).catch((err) => {
                this.setIsLoading(false);
            });
        },
        btnClearStatus(status) {
            this.buttonStatus = status;
        },
        // Input support area permission processing matrix
        async btnPermission(data) {
            if (data) {
                if (this.systemUserRole !== 8) {
                    this.roleSystem = this.systemUserRole;
                    this.previousSupportProgressStatus = data.previous?.support_progress_status;
                    this.nextSupportProgressStatus = data.next?.support_progress_status;
                }
            }
        },
        switchDispInfo() {
            const left = $("#infoDashboardLeft");
            const mid = $("#infoDashboardMid");
            const right = $("#infoDashboardRight");

            left.removeClass("col-3");
            mid.removeClass("col-3");
            right.removeClass("col-6");
            right.removeClass("col-9");
            right.removeClass("col");

            switch (this.display_mode) {
            case 0:
                mid.addClass("col-3");
                right.addClass("col-9");
                left.hide();
                mid.show();
                this.display_mode = 1;
                break;
            case 1:
                left.addClass("col-3");
                right.addClass("col-9");
                left.show();
                mid.hide();
                this.display_mode = 2;
                break;
            case 2:
                right.addClass("col");
                left.hide();
                mid.hide();
                this.display_mode = 3;
                break;
            default:
                left.addClass("col-3");
                mid.addClass("col-3");
                right.addClass("col-6");
                left.show();
                mid.show();
                this.display_mode = 0;
                break;
            }
        },

        /**
         * ポイントを変更
         */
        onChangePoint({ outcome = null, process = null }) {
            if (process !== null) {
                this.planningPoint.process = process;
            }
            if (outcome !== null) {
                this.planningPoint.outcome = outcome;
            }
        },
    }
}
</script>
<style lang="scss">
.no-hover {
  pointer-events: none;
}
.dashboard-l .bootstrap-datetimepicker-widget {
    position: sticky;
}
.dashboard{
  margin-top: -15px;
  height: 100%;

  .infoDashboard {
    height: 100%;

    .infoDashboardRight {
      display: flex;
      flex-direction: column;
    }
    .infoDashboardMid {
      border-right: 1px solid #dee2e6
    }
  }
}
</style>
