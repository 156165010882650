<template>
    <!--  case showTabs can not check also because L2 default support continuous -->
  <div class="h-100" v-if="showTabs === 'support_continuous' || showTabs === 'support_first_split'">

  <div class="dashboard-assistance-area" >

    <div class="d-flex flex-column contentForm"  :class="classTagColor ? [classTagColor, 'dashboard-block-segment'] : ''">
        <TabContentInputSupport />
        <template v-if="isPassed3MonthsFromFirstSupport && useOutcomeEval">
            <div class="outcome-available-message mx-4 my-1">※目標達成状況チェックしてください</div>
        </template>
        <div id="myTabContent" class="p-3">
            <div class="tab-pane fade show active"
                    id="a"
                    role="tab"
                    aria-labelledby="a-tab">
                <!-- 実施日 -->
                <div class="form-group">
                    <label for="start_date" class="required">実施日</label>
                    <date-picker
                        autocomplete="off"
                        :disabled="isViewSupportInput"
                        :config="optionDate"
                        name="start_date"
                        v-model="formData.start_date"
                        format="YYYY-MM-DD"
                        value-type="YYYY-MM-DD"
                        class="w-100"
                        input-class="form-control"
                    >
                    </date-picker>
                    <span class="text-danger">{{ errors.start_date }}</span>
                </div>
                <!-- 支援担当者 -->
                <div class="form-group mt-3">
                    <label for="system_users_id" class="required">支援担当者</label>
                    <select :disabled="isViewSupportInput" v-model="formData.system_users_id" name="system_users_id"
                            id="system_users_id" class="form-select">
                        <option :value="null"></option>
                        <option v-for="item in optionLeader" :key="item.value" :value="item.value">
                        {{ item.text }}
                        </option>
                    </select>
                    <span class="text-danger">{{ errors.system_users_id }}</span>
                    </div>
                    <!-- 実施者 -->
                    <div class="form-group mt-3">
                    <label for="system_users_leader_id" class="required">実施者</label>
                    <select :disabled="isViewSupportInput" v-model="formData.system_users_leader_id"
                            class="form-select">
                        <option :value="null"></option>
                        <option
                            v-for="option in optionExecutor"
                            :key="option.value"
                            :value="(option.value == 99 ? 90 : option.value)"
                        >
                        {{ option.text }}
                        </option>
                    </select>
                    <span class="text-danger">{{ errors.system_users_leader_id }}</span>
                </div>

                <!-- 支援形態 -->
                <div class="form-group mt-3">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            支援形態<span class="required"></span>
                        </div>
                        <div class="col-md-6 col-sm-12 text-right">
                        <input
                                :disabled="isViewSupportInput"
                                v-model="formData.health_support_form_id_blank"
                                type="checkbox"
                                class="form-check-input"
                                id="変更する"
                        />
                        <label class="ml-3" for="変更する">変更する</label>
                    </div>
                    </div>
                    <select :disabled="isViewSupportInput || !formData.health_support_form_id_blank"
                            v-model="formData.health_support_form_id"
                            name="health_support_form_id" id="health_support_form_id" class="form-select">
                        <option v-for="healthSupportForm in optionHealthSupport" :key="healthSupportForm.value"
                                :value="healthSupportForm.value">
                        {{ healthSupportForm.text }}
                        </option>
                    </select>
                    <div class="text-danger">{{ errors.health_support_form_id }}</div>
                </div>

                <!-- 支援実施量 -->
                <div class="form-group mt-3">
                    <label for="kcal_input" class="required">実施({{ support_amount_unit }})</label>
                    <input type="number" v-model="formData.kcal_input" @blur="handlePoint"
                            :disabled="isViewSupportInput || !canEditSupportVolume" :min="minSupportAmount"
                            class="form-control" name="kcal_input"/>
                    <div class="text-danger">{{ errors.kcal_input }}</div>
                </div>

                <template v-if="!isFirstSupport">
                    <!-- 支援ポイント -->
                    <div class="form-group mt-3">
                        <label for="point" class="required">ポイント</label>
                        <input :disabled="isViewSupportInput" type="number" v-model="formData.point"
                                class="form-control" name="point" min="0" max="9999" step="1"
                                @change="onHandleChangeProcessEvalPoint"
                        />
                        <div class="text-danger">{{ errors.point }}</div>
                    </div>

                    <!-- 体重 -->
                    <div class="form-group mt-3">
                        <div class="d-flex justify-content-between">
                            <label for="weight" class="required">体重(kg)</label>
                            <span class="text-gray">健診時体重：{{ diagnosticWeight }} (kg)</span>
                        </div>
                        <input
                            type="number"
                            class="form-control"
                            name="weight"
                            step="0.1"
                            min="0.0"
                            max="999.9"
                            v-model="formData.weight"
                            :disabled="isViewSupportInput"
                            @blur="handleWeight"
                        />
                        <span class="text-danger">{{ errors.weight }}</span>
                        <span class="text-danger" v-if="warns.weight != null"><br />{{ warns.weight }}</span>
                    </div>

                    <!-- 腹囲 -->
                    <div class="form-group mt-3">
                        <div class="d-flex justify-content-between">
                            <label for="waist" class="required">腹囲(cm)</label>
                            <span class="text-gray">健診時腹囲：{{ diagnosticWaist }} (cm)</span>
                        </div>
                        <input
                            type="number"
                            class="form-control"
                            name="waist"
                            step="0.1"
                            min="0.0"
                            max="999.9"
                            v-model="formData.waist"
                            :disabled="isViewSupportInput"
                            @blur="handleWaist"
                        />
                        <span class="text-danger">{{ errors.waist }}</span>
                        <span class="text-danger" v-if="warns.waist != null"><br />{{ warns.waist }}</span>
                    </div>

                    <template v-if="useOutcomeEval">
                        <div class="form-group">
                            <input
                                id="archive-1cm-1kg"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_1cm_1kg"
                                :disabled="true"
                            />
                            <label class="ml-1" for="archive-1cm-1kg">目標達成 (1cm・1kg)</label>
                        </div>
                        <div class="form-group mb-3">
                            <input
                                id="archive-2cm-2kg"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_2cm_2kg"
                                :disabled="true"
                            />
                            <label class="ml-1" for="archive-1cm-1kg">目標達成 (2cm・2kg)</label>
                        </div>
                    </template>

                    <!-- 食生活 -->
                    <div class="form-group mt-3 mb-1">
                        <label for="plan_eating" class="required">
                            <template v-if="is4th">
                                行動目標（食習慣）について
                            </template>
                            <template v-else>
                                食生活に変化があったか
                            </template>
                        </label>
                        <select :disabled="isViewSupportInput" v-model="formData.plan_eating" name="plan_eating"
                                id="plan_eating" class="form-select">
                            <option :value="null"></option>
                            <option v-for="planEating in optionPlanEating" :key="planEating.code"
                                    :value="planEating.code">
                            {{ planEating.name }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.plan_eating }}</span>
                    </div>
                    <template v-if="useOutcomeEval">
                        <div class="text-gray" v-if="firstPlans.activity_plan_eating">
                            計画：{{ firstPlans.activity_plan_eating }}
                        </div>
                        <div class="form-group mb-3">
                            <input
                                id="archive-eating"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_eating"
                                :disabled="!isPassed3MonthsFromFirstSupport || isViewSupportInput"
                                @change="onHandleChangeAchievedPlanEating"
                            />
                            <label class="ml-1" for="archive-eating">目標達成 (食習慣の改善)</label>
                        </div>
                    </template>

                    <!-- 運動 -->
                    <div class="form-group mt-3 mb-1">
                        <label for="plan_activity" class="required">
                            <template v-if="is4th">
                                行動目標（運動習慣）について
                            </template>
                            <template v-else>
                                運動について変化があったか
                            </template>
                        </label>
                        <select :disabled="isViewSupportInput" v-model="formData.plan_activity" name="plan_activity"
                                id="plan_activity" class="form-select">
                            <option :value="null"></option>
                            <option v-for="planActivity in optionPlanActivity" :key="planActivity.code"
                                    :value="planActivity.code">
                            {{ planActivity.name }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.plan_activity }}</span>
                    </div>
                    <template v-if="useOutcomeEval">
                        <div class="text-gray" v-if="firstPlans.activity_plan_exercise">
                            計画：{{ firstPlans.activity_plan_exercise }}
                        </div>
                        <div class="form-group mb-3">
                            <input
                                id="archive-exercise"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_exercise"
                                :disabled="!isPassed3MonthsFromFirstSupport || isViewSupportInput"
                                @change="onHandleChangeAchievedPlanExercise"
                            />
                            <label class="ml-1" for="archive-exercise">目標達成 (運動習慣の改善)</label>
                        </div>
                    </template>

                    <!-- 喫煙状況 -->
                    <div class="form-group mt-3 mb-1">
                        <label for="plan_smocking" class="required">
                            <template v-if="is4th">
                                行動目標（喫煙習慣）について
                            </template>
                            <template v-else>
                                喫煙状況について変化があったか
                            </template>
                        </label>
                        <select :disabled="isViewSupportInput" v-model="formData.plan_smocking" name="plan_smocking"
                                id="plan_smocking" class="form-select">
                                <option :value="null"></option>
                            <option v-for="smokeChange in optionsSmokeChange" :key="smokeChange.code"
                                    :value="smokeChange.code">
                            {{ smokeChange.name }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.plan_smocking }}</span>
                    </div>
                    <template v-if="useOutcomeEval">
                        <div class="text-gray" v-if="firstPlans.activity_plan_smoking">
                            計画：{{ firstPlans.activity_plan_smoking }}
                        </div>
                        <div class="form-group mb-3">
                            <input
                                id="archive-smoking"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_smoking"
                                :disabled="!isPassed3MonthsFromFirstSupport || isViewSupportInput"
                                @change="onHandleChangeAchievedPlanSmoking"
                            />
                            <label class="ml-1" for="archive-smoking">目標達成 (喫煙習慣の改善)</label>
                            </div>
                    </template>


                    <!-- 休養 -->
                    <template v-if="useOutcomeEval">
                        <div class="form-group mt-3 mb-1">
                            <label for="plan-rest" class="required">
                                行動目標（休養習慣）について
                            </label>
                            <select
                                id="plan-rest"
                                :disabled="isViewSupportInput"
                                v-model="formData.plan_rest"
                                name="plan_rest"
                                class="form-select"
                            >
                                <option :value="null"></option>
                                <option v-for="option in optionPlanActivity" :key="option.code" :value="option.code">
                                    {{ option.name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.plan_rest }}</span>
                        </div>
                        <template v-if="useOutcomeEval">
                            <div class="text-gray" v-if="firstPlans.activity_plan_rest">
                                計画：{{ firstPlans.activity_plan_rest }}
                            </div>
                            <div class="form-group mb-3">
                                <input
                                    id="archive-rest"
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="formData.is_achieved_plan_rest"
                                    :disabled="!isPassed3MonthsFromFirstSupport || isViewSupportInput"
                                    @change="onHandleChangeAchievedPlanRest"
                                />
                                <label class="ml-1" for="archive-rest">目標達成 (休養習慣の改善)</label>
                            </div>
                        </template>
                    </template>

                    <!-- その他の生活習慣 -->
                    <template v-if="useOutcomeEval">
                        <div class="form-group mt-3 mb-1">
                            <label for="plan-else" class="required">行動目標（その他の生活習慣）について</label>
                            <select
                                id="plan-else"
                                :disabled="isViewSupportInput"
                                v-model="formData.plan_else"
                                name="plan_else"
                                class="form-select"
                            >
                                <option :value="null"></option>
                                <option v-for="option in optionPlanActivity" :key="option.code" :value="option.code">
                                    {{ option.name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.plan_else }}</span>
                        </div>
                        <template v-if="useOutcomeEval">
                            <div class="text-gray" v-if="firstPlans.activity_plan_else">
                                計画：{{ firstPlans.activity_plan_else }}
                            </div>
                            <div class="form-group mb-3">
                                <input
                                    id="archive-else"
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="formData.is_achieved_plan_else"
                                    :disabled="!isPassed3MonthsFromFirstSupport || isViewSupportInput"
                                    @change="onHandleChangeAchievedPlanElse"
                                />
                                <label class="ml-1" for="archive-else">目標達成 (その他生活習慣の改善)</label>
                            </div>
                        </template>
                    </template>
                </template>

                <!-- 支援内容 -->
                <div class="form-group mt-3">
                    <label for="change_plan" class="form-label required">支援内容</label><span class="ml-5">( {{ formData.change_plan?.length ?? 0 }} / {{ CHANGE_PLAN_MAX_LEN }} )</span>
                    <textarea :disabled="isViewSupportInput" :maxlength="CHANGE_PLAN_MAX_LEN" v-model="formData.change_plan"
                                name="change_plan" class="form-control" id="change_plan" cols="30"
                                rows="10">
                    </textarea>
                    <div class="text-danger">{{ errors.change_plan }}</div>
                </div>
            </div>
        </div>
        <footer>
            <div class="footer-button">
                <v-template v-if="btnSaveDraftStatus">
                    <v-btn color="default" @click="handleBeforeDialog('isDialogSaveDraft')">一時保存</v-btn>
                </v-template>
                <v-template v-if="btnDeleteStatus">
                    <v-btn color="error" @click="handleBeforeDialog('isDialogDelete')">確定解除</v-btn>
                </v-template>
                <v-template v-if="btnEditStatus">
                    <v-btn color="primary" @click="handleBeforeDialog('isDialogEdit')">修正</v-btn>
                </v-template>
                <v-template  v-if="btnConfirmStatus">
                    <v-btn color="primary"  @click="handleBeforeDialog('isDialogConfirm')">確定</v-btn>
                </v-template>
                <v-template v-if="isFirstSupport && btnConfirmStatus" @click="showIncompleteFirstSupportDialog = true">
                    <v-btn color="success">初回未完了</v-btn>
                </v-template>
            </div>
        </footer>
    </div>
  </div>
    <Dialog :isShow="isDialogSaveDraft" persistent :maxWidth="600">
    <DialogSaveDraft @saveDraft="saveDraft" @closeDialog="onHandleCloseDialog('isDialogSaveDraft')" />
    </Dialog>
    <Dialog :isShow="isDialogDelete" persistent :maxWidth="600">
    <DialogDelete @deleteSegment="deleteSegment" @closeDialog="onHandleCloseDialog('isDialogDelete')" />
    </Dialog>
    <Dialog :isShow="isDialogEdit" persistent :maxWidth="600">
    <DialogEdit @editSegment="editSegment" @closeDialog="onHandleCloseDialog('isDialogEdit')" />
    </Dialog>
    <Dialog :isShow="isDialogConfirm" persistent :maxWidth="600">
    <DialogConfirm proc_name="確定" @confirmDialog="confirmDialog" @closeDialog="onHandleCloseDialog('isDialogConfirm')" />
    </Dialog>

    <Dialog :isShow="showIncompleteFirstSupportDialog" persistent :maxWidth="400">
        <v-card>
            <v-card-title class="text-center">初回未完了</v-card-title>
            <v-card-text>
                <div>
                    <v-checkbox v-model="checkIncompleteFirstSupport" label="初回支援を未完了にする" />
                </div>
                <div>
                    <label>確定日</label>
                        <span v-if="checkIncompleteFirstSupport" class="required"></span>
                    </div>
                <div>
                    <date-picker
                        name="incomplete-first-support-date"
                        autocomplete="off"
                        placeholder="日付を選択して下さい"
                        v-model="incompleteDate"
                        :config="optionDate"
                        :disabled="!checkIncompleteFirstSupport"
                    />
                </div>
                <div class="mt-3">
                    <label class="form-label">
                        理由
                        <span v-if="checkIncompleteFirstSupport" class="required"></span>
                    </label>
                </div>
                <div>
                    <v-textarea
                        outlined
                        v-model="incompleteReason"
                        :disabled="!checkIncompleteFirstSupport"
                        :counter="200"
                        rows="3"
                        auto-grow
                    />
                </div>
            </v-card-text>
            <v-card-actions class="px-5 pb-5">
                <v-btn @click="showIncompleteFirstSupportDialog = false" color="default">閉じる</v-btn>
                <v-btn @click="saveIncompleteFirstSupport()" class="ml-auto" color="primary">保存</v-btn>
            </v-card-actions>
        </v-card>
    </Dialog>
</div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import mixins from "@/mixins";
import Dialog from "@/components/common/Dialog";
import DialogConfirm from "@/components/dashboard/DialogConfirm";
import DialogDelete from "@/components/dashboard/DialogDelete";
import DialogEdit from "@/components/dashboard/DialogEdit";
import DialogSaveDraft from "@/components/dashboard/DialogSaveDraft";
import L1 from "@/services/api/L1";
import L2 from "@/services/api/L2";
import {INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import CheckBox from "@/components/common/CheckBox";
import {
    BTN_SAVE_DRAFT,
    BTN_CONFIRM,
    BTN_EDIT,
    BTN_DELETE,
    BTN_CLEAR,
    ENVS,
    SUPPORT_PROGRESS_PLAN,
    SUPPORT_PROGRESS_SCHEDULE,
    SUPPORT_PROGRESS_IN_PROGRESS,
    SUPPORT_PROGRESS_CONFIRM,
    TOAST_TIME_OUT
} from "@/common/constants";

import TabContentInputSupport from "@/components/common/TabContentInputSupport";
import DashboardService from "@/services/api/DashboardRepository";
import DatePicker from "vue2-datepicker";

import StringUtil from "@/functions/StringUtil";
const {
    toFullWidthString,
} = StringUtil();

// import ToastHelper from "@/functions/ToastHelper";
// const {
//     showWarnToast,
//     showErrorToast,
//     showSuccessToast,
//     showInputInvalidToast,
//     showInputWarnToast,
// } = ToastHelper();


export default {
    name: "L2",
    data() {
        return {
            optionLeader: [],
            ArrayLeader: [],
            optionExecutor: [],
            firstPlans: {},
            formData: {
                start_date: null,
                system_users_id: null,
                system_users_leader_id: null,
                health_support_form_id: null,
                kcal_input: 0,
                point: 0,
                weight: "0.0",
                waist: "0.0",
                plan_eating: null,
                plan_activity: null,
                plan_smocking: null,
                plan_rest: null,
                plan_else: null,
                change_plan: null,
                health_support_form_id_blank: false,
                is_achieved_plan_1cm_1kg: false,
                is_achieved_plan_2cm_2kg: false,
                is_achieved_plan_eating: false,
                is_achieved_plan_exercise: false,
                is_achieved_plan_smoking: false,
                is_achieved_plan_rest: false,
                is_achieved_plan_else: false,
            },
            errors: {
                change_plan: null,
                health_support_form_id: null,
                kcal_input: null,
                plan_activity: null,
                plan_eating: null,
                plan_smocking: null,
                plan_rest: null,
                plan_else: null,
                point: null,
                system_users_id: null,
                system_users_leader_id: null,
                waist: null,
                weight: null
            },
            warns: {
                waist: null,
                weight: null
            },
            optionHealthSupport: [],
            disableForm: false,
            isDialogSaveDraft: false,
            isDialogDelete: false,
            isDialogEdit: false,
            isDialogConfirm: false,

            // 未完了初回支援
            showIncompleteFirstSupportDialog: false,
            checkIncompleteFirstSupport: this.supportFlow.incomplete_first_support_flag,
            incompleteDate: this.supportFlow.incomplete_first_support_date ? new Date(this.supportFlow.incomplete_first_support_date) : null,
            incompleteReason: this.supportFlow.incomplete_first_support_reason,

            segmentData: [],
            optionPlanEating: [],
            optionPlanActivity: [],
            optionsSmokeChange: [],
            schemaValidate: undefined,

            // support_progress_status_code
            PLAN: 10,
            SCHEDULE: 20,
            IN_PROGRESS: 30,
            CONFIRM: 40,

            CHANGE_PLAN_MAX_LEN: 250,

            // button status
            btnSaveDraftStatus: false,
            btnConfirmStatus: false,
            btnEditStatus: false,
            btnDeleteStatus: false,
            getSupportProgressStatus: null,

            validateFormSupport: false,
            codeStatusSupportA: 20,
            codeStatusSupportB: 30,
            minSupportAmount: 0,
            flagCheckMin: true,

            // 初回支援時の体重と腹囲
            waistFirst: 0,
            weightFirst: 0,

            flagLoadFirst: true,
            isFormDataLoading: false,
            checkSystemUsersIdLoadFirst: true,
            map_system_code_03: null,
            version: "v1",
            eval_div: null,
            evaluator_div: null,
            canEditSupportVolume: false,
            support_amount_unit: null,
            // 健診結果(検査項目)
            diagnosticList: [],

            // このセグメントでのポイント (付与済のポイント)
            currentSegmentProcessPoint: 0,
            currentSegmentOutcomePoint: 0,
        };
    },
    mixins: [
        mixins
    ],
    components: {
        Dialog,
        DialogSaveDraft,
        DialogEdit,
        DialogConfirm,
        DialogDelete,
        TabContentInputSupport,
        DatePicker
    },
    props: {
        roleSystem: [String, Number],
        previousSupportProgressStatus: [String, Number],
        nextSupportProgressStatus: [String, Number],
        classTagColor: [String],
        showTabs: [String],
        support_flow_status: [String],
        isViewSupportInput: [Boolean],
        supportFlow: [Object, null],
        reception: [Object, null],
        patient: [Object, Number],
        firstSegment: [Object, null],
        firstSegmentSplit: [Object, null],
    },
    async created() {
        let that = this;
        await L2.getVersion("L1",this.segment).then((res) => {
            const { data, status } = res;
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            if (status !== SUCCESS) return;
            this.version = data.data.form_version;
            this.eval_div = data.data.support_eval_div;
            this.evaluator_div = data.data.evaluator_div;
        });
        await L2.getOptionByCode(null, this.eval_div, this.evaluator_div).then((res) => {
            const data = res.data.data;
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            var optionExecutor = "0001000032";
            this.optionExecutor = data[optionExecutor];
        });
        L2.getFirstPlansBySegmentId(this.segment.id)
            .then((res) => {
                if (res.data) {
                    this.firstPlans = res.data;
                }
            });

        DashboardService.getHealthResult(this.patient.id).then(
            (result) => {
                this.diagnosticList = result.data.data.diagnostic_list;
            }
        );

        await this.getHealthSupport();
        await this.getOptionPlan();
        await this.getList();
        await this.getDatafirst();
        await this.getLeader();

        this.$root.$on("reloadFormSupport", function(e) {
            switch (e.type) {
            case BTN_CLEAR:
                that.clearSegment();
                break;
            }
        })

        this.getSupportProgressStatus = null;
        L2.getSegmentDetail(this.segment.id).then((res) => {
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            if (res.status !== SUCCESS) return;
            this.getSupportProgressStatus = res.data.data.support_progress_status;
            this.checkPermissionButton(this.roleSystem);
        });
    },
    watch: {
        async segment(data) {
            if (this.segment.id) {
                this.minSupportAmount = 0
                this.flagCheckMin= true
                this.waistFirst = 0
                this.weightFirst = 0
                this.flagLoadFirst = true
                this.checkSystemUsersIdLoadFirst = true
                await this.clearSegment();
                await this.getHealthSupport();
                await this.getOptionPlan();
                await this.getList();
                await this.getDatafirst();
                await this.getLeader();
            }
            this.getSupportProgressStatus = null;
            L2.getSegmentDetail(data.id).then((res) => {
                if(res.errorCode === INTERNAL_SERVER_ERROR) {
                    this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                    return;
                }
                this.getSupportProgressStatus = res.data.data.support_progress_status;
                this.checkPermissionButton(this.roleSystem);
            });
        },
        "formData.system_users_id"() {
            if(!this.checkSystemUsersIdLoadFirst){
                if(this.formData.system_users_id && this.formData.system_users_id != undefined) {
                    let arrayLeader = [...this.ArrayLeader];
                    this.formData.system_users_leader_id = parseInt(arrayLeader.filter(
                        (x) => x.id == this.formData.system_users_id
                    )[0]?.user_occupation);
                }
            }else{
                this.formData.health_support_form_id = this.optionHealthSupport.filter(x => x.map_system_code_03 == this.map_system_code_03)[0]?.value
                this.checkSystemUsersIdLoadFirst = false;
            }
        },
        /**
         * 支援形態変更時.
         */
        "formData.health_support_form_id"() {
            if(!this.flagLoadFirst) {
                // 支援形態が選択されている場合.
                if(this.formData.health_support_form_id && this.formData.health_support_form_id != undefined) {
                    // 選択された支援形態マスタを取得.
                    let arrOptionHealthSupport = [...this.optionHealthSupport];
                    let dataOptionHealthSupport = arrOptionHealthSupport.filter(
                        (x) => x.value == this.formData.health_support_form_id
                    )[0];

                    this.flagCheckMin = dataOptionHealthSupport.status
                    // 介入量 活性化
                    this.canEditSupportVolume = dataOptionHealthSupport.status ? false : true;
                    // 単位
                    this.support_amount_unit = dataOptionHealthSupport.support_amount_unit
                    // 最低介入量
                    this.minSupportAmount = dataOptionHealthSupport.min
                    this.formData.kcal_input = dataOptionHealthSupport.min
                    // 基準支援量
                    let basis_support_amount = dataOptionHealthSupport.basis_support_amount;
                    //　基準ポイント
                    let basis_point = dataOptionHealthSupport.basis_point;
                    // 上限ポイント
                    let max_point = dataOptionHealthSupport.max_point;
                    // ポイント算出
                    // MAX( (RoundDown(入力介入量 / 基準介入量) * 基準ポイント), 上限ポイント )
                    this.formData.point = Math.min(parseInt(this.formData.kcal_input / basis_support_amount) * basis_point, max_point)
                    this.handleChangeProcessEvalPoint(this.formData.point);
                } else {
                    // 最低介入量
                    this.minSupportAmount = 0
                    this.flagCheckMin = true
                    this.flagLoadFirst = false
                    // 進捗が「計画」or「予定」ならば、支援フローセグメント値を設定.
                    if (this.segment?.support_progress_status == SUPPORT_PROGRESS_PLAN
                        || 
                        this.segment?.support_progress_status == SUPPORT_PROGRESS_SCHEDULE
                    ) {
                        this.formData.kcal_input = this.segment.scheduled_support_amount;
                        this.formData.point = this.segment.scheduled_support_point;
                        this.handleChangeProcessEvalPoint(this.formData.point);
                    }
                }
            }else{
                // 選択された支援形態マスタを取得.
                let arrOptionHealthSupport = [...this.optionHealthSupport];
                let dataOptionHealthSupport = arrOptionHealthSupport.filter(
                    (x) => x.value == this.formData.health_support_form_id
                )[0];

                this.flagCheckMin = true

                // 介入量 活性化
                this.canEditSupportVolume = dataOptionHealthSupport?.status ? false: true;
                // 単位
                this.support_amount_unit = dataOptionHealthSupport?.support_amount_unit
                // 最低介入量
                this.minSupportAmount = dataOptionHealthSupport?.min || 0
               
                // 進捗が「計画」or「予定」ならば、支援フローセグメント値を設定.
                if (
                    this.segment?.support_progress_status == SUPPORT_PROGRESS_PLAN
                    || 
                    this.segment?.support_progress_status == SUPPORT_PROGRESS_SCHEDULE
                ) {
                    this.formData.kcal_input = this.segment.scheduled_support_amount;
                    this.formData.point = this.segment.scheduled_support_point;
                    this.handleChangeProcessEvalPoint(this.formData.point);
                }

                this.flagLoadFirst = false
            }
        },
        "formData.change_plan": {
            handler: function(newVal, oldVal) {
                if (!this.isFormDataLoading){
                    if (newVal != null) {
                        this.formData.change_plan = toFullWidthString(newVal);
                    }
                }
            },
            deep : false, //ネストされたオブジェクトも監視する deepがfalseだとオブジェクト内の値が変更されても処理が実行されない
            immediate: false // 初期読み込み時にも呼び出す
        },
    },
    computed: {
        optionDate() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        ...mapGetters({
            "segment": "dashboard/getSegment",
            user: "common/getUser"
        }),

        segments() {
            if (!this.supportFlow) {
                return [];
            }
            return this.supportFlow.health_support_flow_segments;
        },

        /**
         * 初回面談から３ヶ月+1日経過しているか
         */
        isPassed3MonthsFromFirstSupport() {
            // ex) 初回面談 2020/01/02 の場合
            // 2020/04/02 以降は true
            // 2020/04/01 以前は false
            if (this.segments.length==0) {
                return false;
            }
            if (!this.formData.start_date) {
                return false;
            }
            if (!this.firstSegment && !this.firstSegmentSplit) {
                return false;
            }
            try {
                // 分割実施の場合は2回目の支援日を基準にする
                const firstSegment = this.firstSegmentSplit || this.firstSegment;
                if (!firstSegment.support_date) {
                    return false;
                }
                const currentSupportDate = moment(this.formData.start_date, "YYYY-MM-DD");
                const firstSupportDate = moment(firstSegment.support_date, "YYYY-MM-DD");
                const thresholdDate = firstSupportDate.add(3, "months").add(1, "days");
                const result = currentSupportDate.isSameOrAfter(thresholdDate);
                return result;
            } catch (e) {
                return false;
            }
        },

        /**
         * 第4期以降
         */
        is4th() {
            if (!this.reception) {
                return false;
            }
            return this.reception.health_guidance_period >= 4;
        },

        /**
         * アウトカム評価を使用するかどうか
         * 第4期以降
         */
        useOutcomeEval() {
            if (!this.reception) {
                return false;
            }
            return this.reception.health_guidance_period >= 4;
        },


        /**
         * 健診時の体重
         */
        diagnosticWeight() {
            if (this.diagnosticList.length == 0) {
                return 0;
            }
            const obj = this.diagnosticList.find((item) => item.healthexam_item_code == "9N006000000000001");
            if (obj && obj.healthexam_item_disp) {
                return Number(obj.healthexam_item_disp.replace("kg", "").replace(/ /, ""));
            }
            return null;
        },

        /**
         * 健診時の腹囲
         */
        diagnosticWaist() {
            if (this.diagnosticList.length == 0) {
                return 0;
            }
            const obj = this.diagnosticList.find((item) => item.healthexam_item_code == "9N016160100000001");
            if (obj && obj.healthexam_item_disp) {
                return Number(obj.healthexam_item_disp.replace("cm", "").replace(/ /, ""));
            }
            return null;
        },


        /**
         * 初回支援フラグ (分割実施2回目)
         */
        isFirstSupport() {
            return this.showTabs === "support_first_split";
        },
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        /**
         * ワーニングクリア.
         */
        clearValidationWarns() {
            for (const prop in this.warns) {
                this.warns[prop] = null;
            }
        },
        /**
         * エラークリア.
         */
        clearValidationErrors() {
            for (const prop in this.errors) {
                this.errors[prop] = null;
            }
        },
        /**
         * バリデーションエラーが存在する場合true.
         */
        hasValidationError() {
            for (const prop in this.errors) {
                if (this.errors[prop] != null) return true;
            }
            return false;
        },
        /**
         * バリデーション.
         */
        validate() {
            this.clearValidationErrors();
            this.clearValidationWarns();

            // 実施日
            if (!this.formData.start_date) {
                this.errors.start_date = "実施日を入力してください。";
            }
            // 支援担当者
            if (!this.formData.system_users_id) {
                this.errors.system_users_id = "支援担当者を選択してください。";
            }
            // 実施者
            if (!this.formData.system_users_leader_id) {
                this.errors.system_users_leader_id = "実施者を選択してください。";
            }
            // 支援形態
            if (!this.formData.health_support_form_id) {
                this.errors.health_support_form_id = "支援形態を選択してください。";
            }

            // 支援実施量
            if (!this.formData.kcal_input) {
                this.errors.kcal_input = "支援実施量を入力してください。";
            }
            //  支援実施量が入力可能な場合.
            // if(!this.flagCheckMin && this.minSupportAmount != undefined && this.formData.kcal_input < this.minSupportAmount) {
            if(this.minSupportAmount != undefined && this.formData.kcal_input < this.minSupportAmount) {
                this.errors.kcal_input = "支援実施量は"+ this.minSupportAmount +"以上を入力してください";
            }

            // 以下、継続支援のみ
            if (!this.isFirstSupport) {
                // 実施ポイント
                if (this.formData.point === "") {
                    this.errors.point = "実施ポイントを入力してください。";
                }
                if (this.formData.point) {
                    let dataOptionHealthSupport = [...this.optionHealthSupport].filter(
                        (x) => x.value == this.formData.health_support_form_id
                    )[0];
                    if (this.formData.point < 0 || this.formData.point > dataOptionHealthSupport.max_point) {
                        this.errors.point = `実施ポイントは[0〜${dataOptionHealthSupport.max_point}]の範囲で入力してください。`;
                    }
                }

                // 体重
                if (this.formData.weight === "") {
                    this.errors.weight = "体重を入力してください。";
                }
                if (this.formData.weight && (this.formData.weight < 0.1 || this.formData.weight > 999.9)) {
                    this.errors.weight = "体重は[0.1〜999.9]の範囲で入力してください。";
                }
                // if(this.formData.weight && this.formData.weight - this.weightFirst < this.environments.warnInputDiffWeight) {
                //     this.errors.weight = "初回支援時の体重と比較して大きな差があります。";
                // }

                // 腹囲
                if (this.formData.waist === "") {
                    this.errors.waist = "腹囲を入力してください。";
                }
                if (this.formData.waist && (this.formData.waist < 0.1 || this.formData.waist > 999.9)) {
                    this.errors.waist = "腹囲は[0.1〜999.9]の範囲で入力してください。";
                }
                // if(this.formData.waist && this.formData.waist - this.waistFirst < this.environments.warnInputDiffWaist) {
                //     this.errors.waist = "初回支援時の腹囲と比較して大きな差があります。";
                // }

                // 食生活について
                if (!this.formData.plan_eating) {
                    this.errors.plan_eating = "食生活について変化があったかを選択してください。";
                }
                // 運動について
                if (!this.formData.plan_activity) {
                    this.errors.plan_activity = "運動について変化があったかを選択してください。";
                }
                // 喫煙状況について
                if (!this.formData.plan_smocking) {
                    this.errors.plan_smocking = "喫煙状況について変化があったかを選択してください。";
                }
                if (this.useOutcomeEval) {
                    // 休養状況について
                    if (!this.formData.plan_rest) {
                        this.errors.plan_rest = "休養状況について変化があったかを選択してください。";
                    }
                    // その他の生活習慣について
                    if (!this.formData.plan_else) {
                        this.errors.plan_else = "その他の生活習慣について変化があったかを選択してください。";
                    }
                }
                // 支援内容
                if (!this.formData.change_plan) {
                    this.errors.change_plan = "支援内容を入力してください。";
                }
                if (this.formData.change_plan?.length > this.CHANGE_PLAN_MAX_LEN) {
                    this.errors.change_plan = `支援内容は${this.CHANGE_PLAN_MAX_LEN}文字以下で入力してください。`;
                }
            }

            const isValid = !this.hasValidationError();

            if (!isValid) {
                this.notifyInputInvalidToast();
            }

            // バリデーションエラーが存在しなければtrue(バリデーションOK).
            return isValid;
        },


        /**
         * プロセス評価ポイントの変更を反映
         */
        handleChangeProcessEvalPoint(point) {
            if (!this.isFirstSupport) {
                this.$emit("changePoint", {
                    process: point - this.currentSegmentProcessPoint,
                });
            }
        },


        /**
         * アウトカム評価ポイントの変更を反映
         */
        handleChangeOutcomeEvalPoint(point) {
            if (!this.isFirstSupport) {
                this.$emit("changePoint", {
                    outcome: point - this.currentSegmentOutcomePoint
                });
            }
        },


        /**
         * アウトカム評価ポイントを計算
         */
        calcOutcomeEvalPoint({
            is_achieved_plan_1cm_1kg = this.formData.is_achieved_plan_1cm_1kg,
            is_achieved_plan_2cm_2kg = this.formData.is_achieved_plan_2cm_2kg,
            is_achieved_plan_eating = this.formData.is_achieved_plan_eating,
            is_achieved_plan_exercise = this.formData.is_achieved_plan_exercise,
            is_achieved_plan_smoking = this.formData.is_achieved_plan_smoking,
            is_achieved_plan_rest = this.formData.is_achieved_plan_rest,
            is_achieved_plan_else = this.formData.is_achieved_plan_else,
        }) {
            let point = 0;
            if (is_achieved_plan_1cm_1kg) {
                point += this.OUTCOME_EVAL_POINT_1CM_1KG;
            }
            if (is_achieved_plan_2cm_2kg) {
                point += this.OUTCOME_EVAL_POINT_2CM_2KG;
            }
            if (is_achieved_plan_eating) {
                point += this.OUTCOME_EVAL_POINT_EATING;
            }
            if (is_achieved_plan_exercise) {
                point += this.OUTCOME_EVAL_POINT_EXERCISE;
            }
            if (is_achieved_plan_smoking) {
                point += this.OUTCOME_EVAL_POINT_SMOKING;
            }
            if (is_achieved_plan_rest) {
                point += this.OUTCOME_EVAL_POINT_REST;
            }
            if (is_achieved_plan_else) {
                point += this.OUTCOME_EVAL_POINT_ELSE;
            }
            return point;
        },

        /**
         */
        onHandleChangeProcessEvalPoint(event) {
            this.handleChangeProcessEvalPoint(Number(event.target.value));
        },

        /**
         * (目標達成の変更) 食事
         */
        onHandleChangeAchievedPlanEating(event) {
            const outcomeEvalPoint= this.calcOutcomeEvalPoint({ is_achieved_plan_eating: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * (目標達成の変更) 運動
         */
        onHandleChangeAchievedPlanExercise(event) {
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({ is_achieved_plan_exercise: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * (目標達成の変更) 喫煙
         */
        onHandleChangeAchievedPlanSmoking(event) {
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({ is_achieved_plan_smoking: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * (目標達成の変更) 休養
         */
        onHandleChangeAchievedPlanRest(event) {
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({ is_achieved_plan_rest: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * (目標達成の変更) その他の生活習慣
         */
        onHandleChangeAchievedPlanElse(event) {
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({ is_achieved_plan_else: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * 体重・腹囲の達成を計算
         */
        calcOutcomeEvalPointWeightAndWaist({weightCurrent, waistCurrent}) {
            if (this.useOutcomeEval) {
                const currentWeight = Math.round(Number(weightCurrent) * 100) / 100;
                const currentWaist = Math.round(Number(waistCurrent) * 100) / 100;

                // [計算対象外]
                // - 3ヶ月経過していない
                // - 体重・腹囲が未入力
                if (!this.isPassed3MonthsFromFirstSupport || !currentWeight || !currentWaist) {
                    this.formData.is_achieved_plan_1cm_1kg = false;
                    this.formData.is_achieved_plan_2cm_2kg = false;
                    const outcomeEvalPoint = this.calcOutcomeEvalPoint({
                        is_achieved_plan_1cm_1kg: false,
                        is_achieved_plan_2cm_2kg: false,
                    });
                    this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                    return;
                }

                // JSの浮動小数点演算の誤差を丸める
                const weightDiff = Math.round((this.diagnosticWeight - currentWeight) * 100) / 100;
                const waistDiff = Math.round((this.diagnosticWaist - currentWaist) * 100) / 100;

                // 1cm・1kg達成
                const isAchievedPlan1 = weightDiff >= 1 && waistDiff >= 1;

                // 2cm・2kg達成
                // (当該年の健診時の体重の値に、0.024を乗じた体重（kg）以上かつ同体重と同じ値の腹囲（cm）以上減少している)
                const targetDiff = Math.min(this.diagnosticWeight * 0.024, 2);
                const isAchievedPlan2 = (
                    (weightDiff >= targetDiff) && (waistDiff >= targetDiff)
                );

                this.formData.is_achieved_plan_1cm_1kg = isAchievedPlan1;
                this.formData.is_achieved_plan_2cm_2kg = isAchievedPlan2;
                const outcomeEvalPoint = this.calcOutcomeEvalPoint({
                    is_achieved_plan_1cm_1kg: isAchievedPlan1,
                    is_achieved_plan_2cm_2kg: isAchievedPlan2,
                });
                this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
            }
        },

        /**
         * 体重 blur
         */
        handleWeight(e) {
            // 初回支援時との差分
            const diff = Math.abs(e.target.value - this.weightFirst);
            if(e.target.value != "" && (ENVS.WARN_INPUT_DIFF_WEIGHT < diff)) {
                this.warns.weight = "初回支援時の体重と比較して大きな差があります。";
                this.notifyInputWarnToast("体重");
            } else {
                this.warns.weight = null;
            }
            this.calcOutcomeEvalPointWeightAndWaist({
                weightCurrent: e.target.value,
                waistCurrent: this.formData.waist,
            });
        },

        /**
         * 腹囲 blur
         */
        handleWaist(e) {
            // 初回支援時との差分
            const diff = Math.abs(e.target.value - this.waistFirst);
            if(e.target.value != "" && (ENVS.WARN_INPUT_DIFF_WAIST < diff)) {
                this.warns.waist = "初回支援時の腹囲と比較して大きな差があります。";
                this.notifyInputWarnToast("腹囲");
            } else {
                this.warns.waist = null;
            }
            this.calcOutcomeEvalPointWeightAndWaist({
                weightCurrent: this.formData.weight,
                waistCurrent: e.target.value,
            });
        },


        /**
         * 支援実施量 blur 支援ポイント算出
         * 
         * 
         * @param {*} e 
         */
        handlePoint(e) {
            if(e.target.value != "") {

                let arrOptionHealthSupport = [...this.optionHealthSupport];
                let dataOptionHealthSupport = arrOptionHealthSupport.filter(
                    (x) => x.value == this.formData.health_support_form_id
                )[0];
                let basis_support_amount = dataOptionHealthSupport.basis_support_amount;
                let basis_point = dataOptionHealthSupport.basis_point;
                let max_point = dataOptionHealthSupport.max_point;
                this.formData.point = Math.min(parseInt(event.target.value / basis_support_amount) * basis_point, max_point)
                this.handleChangeProcessEvalPoint(this.formData.point);
            }
        },
        handleBeforeDialog(dialog_name) {
            if (dialog_name) {
                this[dialog_name] = true;
            }
        },

        // Input support area permission processing matrix
        // Current processing status: this.getSupportProgressStatus
        /**
         * 
         * 入力フォームボタン 画面下部ボタン表示制御.
         * 
         * @param {*} roleSystem 
         */
        async checkPermissionButton(roleSystem) {
            if (this.getSupportProgressStatus) {
                if (this.support_flow_status == 40 || this.support_flow_status == 50) {
                    // 途中終了 or 初回面接辞退の場合.
                    // × 一時保存
                    // × 確定
                    // × 修正
                    // × 確定解除
                    // × 支援内容クリア 
                    this.setPermissionButton(false, false, false, false, false);
                } else {
                    // 支援開始前 or 支援実施中 or 支援完了
                    if (this.isSupporter(true)) {
                        // 支援担当者の場合
                        // role is Person responsible for support
                        if (parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                            // 対象支援セグメントが確定されている場合.
                            // × 一時保存
                            // × 確定
                            // × 修正
                            // × 確定解除
                            // × 支援内容クリア 
                            this.setPermissionButton(false, false, false, false, false);
                        } else {
                            // 対象支援セグメントが「計画」or「予定」or「実施中」されている場合.
                            if (parseInt(this.previousSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                                // 直前セグメントが確定されている場合.
                                // ◯ 一時保存
                                // ◯ 確定
                                // × 修正
                                // × 確定解除
                                // × 支援内容クリア 
                                this.setPermissionButton(true, true, false, false, false);

                            } else {
                                // 直前セグメントが確定されていない場合.
                                // ◯ 一時保存
                                // × 確定
                                // × 修正
                                // × 確定解除
                                // × 支援内容クリア 
                                this.setPermissionButton(true, false, false, false, false);
                            }
                        }
                    } else {
                        // 「事務局」or「システム管理者」の場合
                        // role is system admin OR backoffice
                        if (parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                            // 対象支援セグメントが確定されている場合.
                            // × 一時保存
                            // × 確定
                            // ◯ 修正
                            // ◯ 確定解除
                            // × 支援内容クリア 
                            this.setPermissionButton(false, false, false, !(parseInt(this.nextSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM), true);
                        } else if (parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_IN_PROGRESS) {
                            // 対象セグメントが「実施中」
                            if (parseInt(this.previousSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                                //直前セグメントが確定されている場合.
                                // case o => previous is confirm
                                // ◯ 一時保存
                                // ◯ 確定
                                // × 修正
                                // × 確定解除
                                // ◯ 支援内容クリア 
                                this.setPermissionButton(true, true, true, false, false);
                            } else {
                                //直前セグメントが確定されていない場合.
                                // case x => previous is not confirm
                                // ◯ 一時保存
                                // × 確定
                                // × 修正
                                // × 確定解除
                                // ◯ 支援内容クリア 
                                this.setPermissionButton(true, false, true, false, false);
                            }
                        } else if (parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_PLAN
                            || parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_SCHEDULE) {
                            // 対象セグメントが「計画」or「予定」
                            if (parseInt(this.previousSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                                //直前セグメントが確定されている場合.
                                // case o => previous is confirm
                                // ◯ 一時保存
                                // ◯ 確定
                                // × 修正
                                // × 確定解除
                                // × 支援内容クリア 
                                this.setPermissionButton(true, true, false, false, false);
                            } else {
                                //直前セグメントが確定されていない場合.
                                // case x => previous is not confirm
                                // ◯ 一時保存
                                // × 確定
                                // × 修正
                                // × 確定解除
                                // × 支援内容クリア 
                                this.setPermissionButton(true, false, false, false, false);
                            }
                        }
                    }
                }
            }
        },

        /**
         * フォーム下部のボタン表示制御
         * 
         * 
         * @param {*} save_draft_status 
         * @param {*} confirm_status 
         * @param {*} clear_status 
         * @param {*} delete_status 
         * @param {*} edit_status 
         */
        setPermissionButton(save_draft_status, confirm_status, clear_status, delete_status, edit_status) {
            this.btnSaveDraftStatus = save_draft_status;
            this.btnConfirmStatus = confirm_status;
            this.$emit("btnClearStatus", clear_status)
            this.btnDeleteStatus = delete_status;
            this.btnEditStatus = edit_status;
        },
        async getHealthSupport() {
            const supportFormDiv = parseInt(this.segment?.health_support_form?.support_form_div);

            let typeSupport = "A_SUPPORT";

            if (supportFormDiv === this.codeStatusSupportFirst) {
                // 分割実施 初回面接2回目
                typeSupport = "FIRST_SUPPORT"
            } else if (this.useOutcomeEval) {
                // 4期以降
                typeSupport = "CONTINUING_SUPPORT";
            } else if (supportFormDiv === this.codeStatusSupportB) {
                // 3期以前 (支援B)
                typeSupport = "B_SUPPORT"
            }

            await L2.getHealthSupport(typeSupport, this.segment.mid_eval_flg).then((res) => {
                this.optionHealthSupport = [];
                if(res.errorCode === INTERNAL_SERVER_ERROR) {
                    this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                    return;
                }
                let data = res.data.data;
                if (typeSupport === "FIRST_SUPPORT") {
                    data = data.filter(x => x.map_system_code_04 == "2");
                }
                data.forEach((x) => {
                    this.optionHealthSupport.push({
                        text: x.support_form_name_disp_01,
                        value: x.id,
                        status: x.support_amount_fix_flg,
                        min: x.min_support_amount,
                        map_system_code_03: x.map_system_code_03,
                        basis_support_amount: x.basis_support_amount,
                        basis_point: x.basis_point,
                        max_point: x.max_point,
                        support_amount_unit: x.support_amount_unit
                    });
                });
                if(this.formData.health_support_form_id && this.formData.health_support_form_id != undefined) {
                    let arrOptionHealthSupport = [...this.optionHealthSupport];
                    let dataOptionHealthSupport = arrOptionHealthSupport.filter(
                        (x) => x.value == this.formData.health_support_form_id
                    )[0];
                    this.flagCheckMin = dataOptionHealthSupport.status
                    if(!dataOptionHealthSupport.status) {
                        this.minSupportAmount = dataOptionHealthSupport.min
                    } else {
                        this.minSupportAmount = 0
                    }
                }
            }) .catch((err) => {
                // to do
            });
        },
        async getLeader() {
            await L2.getListSupportPerson().then((res) => {
                this.optionLeader = [];
                const data = [];
                this.ArrayLeader = res.data.data;
                if(res.errorCode === INTERNAL_SERVER_ERROR) {
                    this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                    return;
                }

                if(this.isSystemAdminOrBackOffice()){
                    // システム管理者 or 事務局
                    res.data.data.map((x) => {
                        data.push({
                            text: `[${"0".repeat((7 - String(x.id).length))}${x.id}] ${x.user_name_disp}`,
                            value: x.id,
                            user_occupation: x.user_occupation,
                        });
                    });
                } else if(this.isSupporter(true)){ // 支援担当者の場合
                    // ログインユーザ.
                    this.user.text = `[${"0".repeat((7 - String(this.user.id).length))}${this.user.id}] ${this.user.user_name_disp}`,
                    this.user.value = this.user.id;
                    data.push(this.user);

                    // 支援予定者.
                    const user_scheduled_support_staff_id = res.data.data.filter(x => x.id == this.segment.scheduled_support_staff_id);
                    if(user_scheduled_support_staff_id.length > 0){
                        let a = user_scheduled_support_staff_id[0];
                        a.text = `[${"0".repeat((7 - String(a.id).length))}${a.id}] ${a.user_name_disp}`;
                        a.value = a.id;
                        a.user_occupation = a.user_occupation;
                        data.push(a);
                    }

                    //　支援実施者.
                    const user_support_staff_id = res.data.data.filter(x => x.id == this.segment.support_staff_id);
                    if(user_support_staff_id.length > 0){
                        let b = user_support_staff_id[0];
                        b.text = `[${"0".repeat((7 - String(b.id).length))}${b.id}] ${b.user_name_disp}`;
                        b.value = b.id;
                        b.user_occupation = b.user_occupation;
                        data.push(b);
                    }
                }

                this.optionLeader = _.uniqBy(data,"value");

                if(!this.formData.system_users_leader_id){
                    let arrayLeader = [...this.ArrayLeader];
                    this.formData.system_users_leader_id = arrayLeader.filter(
                        (x) => x.id == this.formData.system_users_id
                    )[0]?.user_occupation;
                }
            });
        },
        async getDatafirst() {
            await L2.getDatafirst(this.segment.id).then((res) => {
                this.waistFirst = res.data.data.waist;
                this.weightFirst = res.data.data.weight;
                if(!this.formData.plan_smocking) {
                    this.formData.plan_smocking = res.data.data.plan_smocking;
                }
            }).catch((err) => {
                // to do
            });
        },
        async getOptionPlan() {
            L2.getOptionPlan(
                {
                    segment_id: this.segment.id
                }).then((res) => {
                if(res.errorCode === INTERNAL_SERVER_ERROR) {
                    this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                    return;
                }
                this.optionPlanEating = res.data.data.plan_eating;
                this.optionPlanActivity = res.data.data.plan_activity;
                this.optionsSmokeChange = res.data.data.plan_smocking;
                // TODO
            });

        },
        /**
         * フォームデータロード.
         */
        async getList(){
            this.setIsLoading(true);

            await L2.list(this.segment.id)
                .then((res) => {
                    const { data, status } = res;
                    this.setIsLoading(false);
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }
                    if (status !== SUCCESS) return;

                    this.isFormDataLoading = true;

                    if (this.segment?.support_progress_status == SUPPORT_PROGRESS_PLAN
                        || this.segment?.support_progress_status == SUPPORT_PROGRESS_SCHEDULE) {

                        // 実施日の初期値：入力日
                        this.formData.start_date = moment(new Date()).format("YYYY-MM-DD");
                        this.formData.health_support_form_id = this.segment?.health_support_form_id;

                        this.formData.kcal_input = this.segment.scheduled_support_amount;
                        this.formData.point = this.segment.scheduled_support_point;
                    }
                    this.map_system_code_03 = null;

                    if (data.data.segment_item) {
                        const segmentItem = data.data.segment_item;
                        this.formData.system_users_id = segmentItem.system_users_id;
                        this.formData.start_date = segmentItem.start_date ?? null;
                        this.formData.system_users_leader_id = segmentItem.system_users_leader_id ?? null;
                        this.formData.health_support_form_id = this.optionHealthSupport.filter(x => x.map_system_code_03 == segmentItem.map_system_code_03)[0]?.value
                        this.formData.kcal_input = segmentItem.kcal_input ?? 0;
                        this.formData.point = segmentItem.point ?? 0;
                        this.formData.weight = segmentItem.weight ? parseFloat(segmentItem.weight).toFixed(1): "0.0";
                        this.formData.waist = segmentItem.waist ? parseFloat(segmentItem.waist).toFixed(1): "0.0";
                        this.formData.plan_eating = segmentItem.plan_eating ?? null;
                        this.formData.plan_activity = segmentItem.plan_activity ?? null;
                        this.formData.plan_smocking = segmentItem.plan_smocking ?? null;
                        this.formData.plan_rest = segmentItem.plan_rest ?? null;
                        this.formData.plan_else = segmentItem.plan_else ?? null;
                        this.formData.change_plan = segmentItem.change_plan ?? null;
                        this.map_system_code_03 = segmentItem.map_system_code_03;

                        // 中間評価のみアウトカム評価の計算
                        // if (this.segment.mid_eval_flg) {
                        let outcomeEvalPoint = 0;
                        if (this.isPassed3MonthsFromFirstSupport) {
                            this.formData.is_achieved_plan_1cm_1kg = this.segment.is_achieved_plan_1cm_1kg ?? false;
                            this.formData.is_achieved_plan_2cm_2kg = this.segment.is_achieved_plan_2cm_2kg ?? false;
                            this.formData.is_achieved_plan_eating = this.segment.is_achieved_plan_eating ?? false;
                            this.formData.is_achieved_plan_exercise = this.segment.is_achieved_plan_exercise ?? false;
                            this.formData.is_achieved_plan_smoking = this.segment.is_achieved_plan_smoking ?? false;
                            this.formData.is_achieved_plan_rest = this.segment.is_achieved_plan_rest ?? false;
                            this.formData.is_achieved_plan_else = this.segment.is_achieved_plan_else ?? false;

                            outcomeEvalPoint = this.calcOutcomeEvalPoint({
                                is_achieved_plan_1cm_1kg: this.formData.is_achieved_plan_1cm_1kg,
                                is_achieved_plan_2cm_2kg: this.formData.is_achieved_plan_2cm_2kg,
                                is_achieved_plan_eating: this.formData.is_achieved_plan_eating,
                                is_achieved_plan_exercise: this.formData.is_achieved_plan_exercise,
                                is_achieved_plan_smoking: this.formData.is_achieved_plan_smoking,
                                is_achieved_plan_rest: this.formData.is_achieved_plan_rest,
                                is_achieved_plan_else: this.formData.is_achieved_plan_else,
                            });
                        }

                        // 確定済の場合、現在のポイントをセット
                        if (this.segment?.support_progress_status == SUPPORT_PROGRESS_CONFIRM) {
                            if (!this.isFirstSupport) {
                                this.currentSegmentProcessPoint = segmentItem.point;
                                this.currentSegmentOutcomePoint = outcomeEvalPoint;
                            }
                            // 差分が0になるようにする
                            this.handleChangeProcessEvalPoint(this.currentSegmentProcessPoint);
                            this.handleChangeOutcomeEvalPoint(this.currentSegmentOutcomePoint);
                        } else {
                            this.handleChangeProcessEvalPoint(this.formData.point);
                            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                        }
                    }

                    this.flagLoadFirst = true
                    if(!this.formData.system_users_id){
                        this.checkSystemUsersIdLoadFirst = false;
                        this.formData.system_users_id = this.user.id;
                    }
                })
                .catch((err) => {
                    this.setIsLoading(false);
                })
                .finally(v=>{
                    this.isFormDataLoading = false;
                });
        },

        /**
         * 一時保存。
         */
        async saveDraft() {
            // ダイアログ閉じる.
            this.isDialogSaveDraft = false;
            // ローディング状態設定.
            this.setIsLoading(true);

            // バリデーションなしでバックエンド呼出.
            await L2.segmentContinue(this.segment.id, BTN_SAVE_DRAFT, this.formData)
                .then((res) => {
                    const { data, status, error } = res;

                    // ローディング状態設定解除.
                    this.setIsLoading(false);
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }

                    if (status !== SUCCESS) {
                        return this.$toast.open({
                            message: error.message,
                            type: "warning",
                        });
                    };

                    this.$toast.open({
                        message: "一時保存の登録が完了しました。",
                        type: "success",
                    });
                    this.getList();
                    this.$root.$emit("Dashboard.LoadPages", {id: this.segment.id})
                })
                .catch((err) => {
                    // ローディング状態設定解除.
                    this.setIsLoading(false);

                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                    this.$toast.open({
                        message: "error",
                        type: "error",
                    });
                });
        },

        /**
         * 確定解除.
         */
        async deleteSegment() {
            // ダイアログ閉じる.
            this.isDialogDelete = false;
            // ローディング状態設定.
            this.setIsLoading(true);

            // バリデーションなしでバックエンド呼出.
            await L2.segmentContinue(this.segment.id, BTN_DELETE, this.formData)
                .then((res) => {
                    const { data, status, error } = res;
                    // ローディング状態解除.
                    this.setIsLoading(false);
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }

                    if (status !== SUCCESS) {
                        return this.$toast.open({
                            message: error.message,
                            type: "warning",
                        });
                    };
                    this.$toast.open({
                        message: "確定解除処理が完了しました。",
                        type: "success",
                    });
                    this.$root.$emit("Dashboard.LoadPages", {id: this.segment.id})
                })
                .catch((err) => {
                    // ローディング状態解除.
                    this.setIsLoading(false);

                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                    this.$toast.open({
                        message: "error",
                        type: "error",
                    });
                });
        },

        /**
         * フォームデータクリア.
         */
        async clearSegment() {
            this.formData.start_date = new Date();
            this.formData.system_users_id = null;
            this.formData.system_users_leader_id = null;
            this.formData.health_support_form_id = null;
            this.formData.kcal_input = 0;
            this.formData.point = 0;
            this.formData.weight = "0.0";
            this.formData.waist = "0.0";
            this.formData.plan_eating = null;
            this.formData.plan_activity = null;
            this.formData.plan_smocking = null;
            this.formData.plan_rest = null;
            this.formData.plan_else = null;
            this.formData.change_plan = null;
            this.formData.health_support_form_id_blank = false;
            this.formData.is_achieved_plan_1cm_1kg = false;
            this.formData.is_achieved_plan_2cm_2kg = false;
            this.formData.is_achieved_plan_eating = false;
            this.formData.is_achieved_plan_exercise = false;
            this.formData.is_achieved_plan_smoking = false;
            this.formData.is_achieved_plan_rest = false;
            this.formData.is_achieved_plan_else = false;
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({
                is_achieved_plan_1cm_1kg: false,
                is_achieved_plan_2cm_2kg: false,
                is_achieved_plan_eating: false,
                is_achieved_plan_exercise: false,
                is_achieved_plan_smoking: false,
                is_achieved_plan_rest: false,
                is_achieved_plan_else: false,
            });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
            this.handleChangeProcessEvalPoint(0);

            if(!this.formData.system_users_id){
                this.checkSystemUsersIdLoadFirst = false;
                this.formData.system_users_id = this.user.id;
            }
        },

        /**
         * 修正.
         */
        async editSegment() {
            // ダイアログ閉じる.
            this.isDialogEdit = false;

            if(this.validate()) {
                // ローディング状態設定.
                this.setIsLoading(true);
                // バックエンド呼出し.
                await L2.segmentContinue(this.segment.id, BTN_EDIT, this.formData)
                    .then((res) => {
                        const { data, status, error } = res;
                        // ローディング状態解除.
                        this.setIsLoading(false);
                        if(res.errorCode === INTERNAL_SERVER_ERROR) {
                            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                            return;
                        }

                        if (status !== SUCCESS) {
                            return this.$toast.open({
                                message: error.message,
                                type: "warning",
                            });
                        };
                        this.$toast.open({
                            message: "修正処理が完了しました。",
                            type: "success",
                        });
                        this.getList();
                        this.$root.$emit("Dashboard.LoadPages", {id: this.segment.id});
                    })
                    .catch((err) => {
                        // ローディング状態解除.
                        this.setIsLoading(false);

                        err.inner.forEach((error) => {
                            this.errors[error.path] = error.message;
                        });
                        this.$toast.open({
                            message: "error",
                            type: "error",
                        });
                    });
            }
        },

        /**
         * 確定.
         */
        async confirmDialog() {
            // ダイアログ閉じる.
            this.isDialogConfirm = false;

            if(this.validate()) {
                // ローディング状態設定.
                this.setIsLoading(true);
                await L2.segmentContinue(this.segment.id, BTN_CONFIRM, this.formData)
                    .then((res) => {
                        const { data, status, error } = res;
                        // ローディング状態解除.
                        this.setIsLoading(false);
                        if(res.errorCode === INTERNAL_SERVER_ERROR) {
                            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                            return;
                        }

                        if (status !== SUCCESS) {
                            return this.$toast.open({
                                message: error.message,
                                type: "warning",
                            });
                        };
                        this.$toast.open({
                            message: "確定処理が完了しました。",
                            type: "success",
                        });
                        this.$root.$emit("Dashboard.LoadPages", {id: this.segment.id})
                    })
                    .catch((err) => {
                        // ローディング状態解除.
                        this.setIsLoading(false);

                        err.inner.forEach((error) => {
                            this.errors[error.path] = error.message;
                        });
                    });
            }
        },
        onHandleCloseDialog(dialog_name) {
            if (dialog_name) {
                this[dialog_name] = false;
            }
        },

        /**
         * 初回未完了の保存
         */
        async saveIncompleteFirstSupport() {
            // if (this.checkIncompleteFirstSupport) {
            this.setIsLoading(true);
            await L2.saveIncompleteFirstSupport(this.supportFlow.id, {
                isIncomplete: this.checkIncompleteFirstSupport,
                date: this.incompleteDate ? moment(new Date(this.incompleteDate)).format("YYYY-MM-DD") : null,
                reason: this.incompleteReason
            }).then(() => {
                if (this.checkIncompleteFirstSupport) {
                    this.$toast.open({
                        message: "初回支援未完了の登録が完了しました。",
                        type: "success",
                    });
                } else {
                    this.$toast.open({
                        message: "初回支援未完了の解除が完了しました。",
                        type: "success",
                    });
                }
                // this.checkIncompleteFirstSupport = false;
                // this.incompleteDate = null;
                // this.incompleteReason = "";
                this.showIncompleteFirstSupportDialog = false;
                // TODO: support flowを再取得
            })

            this.setIsLoading(false);
            // }
        }
    },
};
</script>

<style scoped lang="scss">
/*.dashboard-assistance-area {*/
/*  min-height: 300px;*/
/*  max-height: 300px;*/
/*  overflow: scroll;*/
/*  overflow-x: hidden;*/
/*}*/

.footer-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  padding-right: 20px;
  padding-left: 20px;
}
.dashboard-block-segment {
  padding-left: 6px;
  position: relative;
}
.dashboard-block-segment .position-relative {
  margin-left: 10px!important;
}
.dashboard-block-segment:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 100%;
}

.block-segment-gray:after {
  background: gray;
  border: 1px solid #333
}
.block-segment-blue:after {
  background: #eedcf2;
  border: 1px solid #333
}
.block-segment-pink:after {
  background: pink;
  border: 1px solid #333
}
.block-segment-green:after {
  background: green;
  border: 1px solid #333
}
.block-segment-white:after {
  background: white;
  border: 1px solid #333
}
.dashboard-assistance-area {
  display: flex;
  flex-direction: column;
  height: 100%;

  .contentForm {
    flex: 1 1 0;
  }

  #myTabContent {
    overflow: auto;
    flex: 1 1 0;
  }

  .required:after {
    content: " *";
    color: red;
    font-weight: 100;
  }
}
.text-gray {
    color: #777777;
    font-size: small;
}

.outcome-available-message {
    border: 1px solid red;
    color: red;
    font-size: 12px;
    padding: 2px;
}

</style>
