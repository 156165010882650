<template>
    <div class="h-100" v-if="showTabs === 'support_first'">
        <!--  case showTabs can not check also because L1 default support first -->
        <div
            :class="
                classTagColor ? [classTagColor, 'dashboard-block-segment'] : ''
            "
            class="dashboard-create-first-support form-l1"
        >
            <div class="d-flex flex-column contentForm">
                <TabContentInputSupport />
                <div id="myTabContent">
                    <div
                        class="tab-pane fade show active"
                        id="a"
                        role="tab"
                        aria-labelledby="a-tab"
                    >
                        <div class="d-flex flex-column">
                            <!-- 実施日 -->
                            <div class="row">
                                <div
                                    class="col-md-12 col-sm-12 d-flex flex-column"
                                >
                                    <div class="d-flex">
                                        <span
                                            >実施日<span class="required"></span
                                        ></span>
                                        <template v-if="healthExamDate">
                                            <span class="ml-auto"
                                                >健診日:
                                                {{ healthExamDate }}</span
                                            >
                                        </template>
                                    </div>
                                    <date-picker
                                        autocomplete="off"
                                        :disabled="isViewSupportInput"
                                        name="tel_datetime"
                                        format="YYYY-MM-DD"
                                        value-type="YYYY-MM-DD"
                                        input-class="form-control"
                                        v-model="dataForm.start_date"
                                        @change="handleChangeStartDate"
                                    >
                                    </date-picker>
                                    <span class="text-danger">{{
                                        this.validateError.start_date
                                            ? this.validateError.start_date
                                            : ""
                                    }}</span>
                                </div>
                            </div>
                            <!-- 実施時間 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    実施時間<span class="required"></span>
                                    <div class="row d-flex align-items-center">
                                        <div
                                            class="pl-0 col-md-4 col-sm-12 time"
                                        >
                                            <date-picker
                                                autocomplete="off"
                                                :disabled="isViewSupportInput"
                                                name="tel_datetime"
                                                type="time"
                                                value-type="HH:mm"
                                                format="HH:mm"
                                                v-model="dataForm.start_time"
                                                class="w-100"
                                                input-class="form-control"
                                            >
                                            </date-picker>
                                        </div>
                                        <div
                                            class="col-md-1 col-sm-12 text-center"
                                        >
                                            -
                                        </div>
                                        <div class="col-md-4 col-sm-12 time">
                                            <date-picker
                                                autocomplete="off"
                                                :disabled="isViewSupportInput"
                                                name="tel_datetime2"
                                                type="time"
                                                format="HH:mm"
                                                value-type="HH:mm"
                                                v-model="dataForm.end_time"
                                                class="w-100"
                                                input-class="form-control"
                                            >
                                            </date-picker>
                                        </div>
                                        <div
                                            class="pr-0 col-md-3 col-sm-12 d-flex align-items-center"
                                        >
                                            ({{ this.support_amount }}分間)
                                        </div>
                                        <span class="text-danger">{{
                                            this.validateError.end_time
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- 指導員 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    指導員<span class="required"></span>
                                    <select
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.system_users_id"
                                        class="form-select"
                                    >
                                        <option :value="null"></option>
                                        <option
                                            v-for="option in optionLeader"
                                            :key="option.value"
                                            :value="option.value"
                                        >
                                            {{ option.text }}
                                        </option>
                                    </select>
                                    <span class="text-danger">{{
                                        this.validateError.system_users_id
                                    }}</span>
                                </div>
                            </div>
                            <!-- 実施者 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    実施者 <span class="required"></span>
                                    <select
                                        v-model="
                                            dataForm.system_users_leader_id
                                        "
                                        class="form-select"
                                        :disabled="isViewSupportInput"
                                    >
                                        <option :value="null"></option>
                                        <option
                                            v-for="option in optionExecutor"
                                            :key="option.value"
                                            :value="
                                                option.value == 99
                                                    ? 90
                                                    : option.value
                                            "
                                        >
                                            {{ option.text }}
                                        </option>
                                    </select>
                                    <span class="text-danger">{{
                                        this.validateError
                                            .system_users_leader_id
                                    }}</span>
                                </div>
                            </div>
                            <!-- 支援形態 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 pl-0">
                                            支援形態<span
                                                class="required"
                                            ></span>
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-12 text-right"
                                        >
                                            <input
                                                :disabled="isViewSupportInput"
                                                v-model="
                                                    dataForm.health_support_form_id_blank
                                                "
                                                type="checkbox"
                                                class="form-check-input"
                                                id="変更する"
                                            />
                                            <label class="ml-3" for="変更する"
                                                >変更する</label
                                            >
                                        </div>
                                    </div>
                                    <select
                                        :disabled="
                                            isViewSupportInput ||
                                            !dataForm.health_support_form_id_blank
                                        "
                                        v-model="
                                            dataForm.health_support_form_id
                                        "
                                        class="form-select"
                                    >
                                        <option
                                            v-for="option in optionFirstTimeSupport"
                                            :key="option.value"
                                            :value="option.value"
                                        >
                                            {{ option.text }}
                                        </option>
                                    </select>
                                    <span class="text-danger">{{
                                        this.validateError
                                            .health_support_form_id
                                    }}</span>
                                </div>
                            </div>

                            <!-- 行動変容ステージモデル -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    行動変容ステージモデル<span
                                        class="required"
                                    ></span>
                                    <select
                                        v-model="dataForm.code_change_activity"
                                        class="form-select"
                                        :disabled="isViewSupportInput"
                                    >
                                        <option :value="null"></option>
                                        <option
                                            v-for="option in optionCodeChangeActivity"
                                            :key="option.value"
                                            :value="option.value"
                                        >
                                            {{ option.text }}
                                        </option>
                                    </select>
                                    <span class="text-danger">{{
                                        this.validateError.code_change_activity
                                    }}</span>
                                </div>
                            </div>

                            <!-- 初回時の体重 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    初回時の体重(kg)<span
                                        class="required"
                                    ></span>
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.start_width"
                                        type="number"
                                        class="form-control"
                                        @change="changeDataFormStartWidth"
                                        step="0.1"
                                        min="0.0"
                                        max="999.9"
                                        maxlength="5"
                                    />
                                </div>
                                <span class="text-danger">{{
                                    message_warning_target_width
                                }}</span>
                                <span class="text-danger">{{
                                    this.validateError.start_width
                                }}</span>
                            </div>

                            <!-- 初回時の腹囲 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    初回時の腹囲(cm)<span
                                        class="required"
                                    ></span>
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.start_waist"
                                        type="number"
                                        class="form-control"
                                        @change="changeDataFormStartWaist"
                                        step="0.1"
                                        min="0.0"
                                        max="999.9"
                                        maxlength="5"
                                    />
                                </div>
                                <span class="text-danger">{{
                                    message_warning_target_waist
                                }}</span>
                                <span class="text-danger">{{
                                    this.validateError.start_waist
                                }}</span>
                            </div>

                            <!-- 行動目標：何kg/cm減らす -->
                            <div class="row" v-if="!is4th">
                                <div class="col-md-12 col-sm-12">
                                    <span>目標(何kg/cm減らす)</span
                                    ><span class="required"></span>
                                    <input
                                        v-model="dataForm.target"
                                        type="number"
                                        class="form-control"
                                        @change="changeDataFormTarget"
                                        :disabled="isViewSupportInput"
                                        step="0.1"
                                        min="0.0"
                                        max="999.9"
                                        maxlength="5"
                                    />
                                </div>
                                <span class="text-danger">{{
                                    this.validateError.target
                                }}</span>
                            </div>

                            <!-- 目標体重 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    目標体重(kg)<span class="required"></span>
                                    <div
                                        class="text-gray"
                                        v-if="diagnosticWeight && is4th"
                                    >
                                        健診時体重({{
                                            diagnosticWeight
                                        }}kg)との差「{{
                                            (
                                                Number(dataForm.target_width) -
                                                diagnosticWeight
                                            ).toFixed(1)
                                        }}」kg
                                    </div>
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.target_width"
                                        type="number"
                                        class="form-control"
                                        step="0.1"
                                        min="0.0"
                                        max="999.9"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.target_width
                                    }}</span>
                                </div>
                            </div>

                            <!-- 目標腹囲 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    目標腹囲(cm)<span class="required"></span>
                                    <div
                                        class="text-gray"
                                        v-if="diagnosticWaist && is4th"
                                    >
                                        健診時腹囲({{
                                            diagnosticWaist
                                        }}cm)との差「{{
                                            (
                                                Number(dataForm.target_waist) -
                                                diagnosticWaist
                                            ).toFixed(1)
                                        }}」cm
                                    </div>
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.target_waist"
                                        type="number"
                                        class="form-control"
                                        step="0.1"
                                        min="0.0"
                                        max="999.9"
                                        maxlength="5"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.target_waist
                                    }}</span>
                                </div>
                            </div>

                            <!-- 喫煙関連 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    喫煙関連<span class="required"></span>
                                    <select
                                        v-model="dataForm.smocking"
                                        class="form-select"
                                        :disabled="isViewSupportInput"
                                    >
                                        <option :value="null"></option>
                                        <option
                                            v-for="option in optionSmocking"
                                            :key="option.value"
                                            :value="option.value"
                                        >
                                            {{ option.text }}
                                        </option>
                                    </select>
                                    <span class="text-danger">{{
                                        this.validateError.smocking
                                    }}</span>
                                </div>
                            </div>

                            <!-- 飲酒関連 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    飲酒関連<span class="required"></span>
                                    <select
                                        v-model="dataForm.alcohol"
                                        class="form-select"
                                        :disabled="isViewSupportInput"
                                    >
                                        <option :value="null"></option>
                                        <option
                                            v-for="option in optionAlcohol"
                                            :key="option.value"
                                            :value="option.value"
                                        >
                                            {{ option.text }}
                                        </option>
                                    </select>
                                    <span class="text-danger">{{
                                        this.validateError.alcohol
                                    }}</span>
                                </div>
                            </div>

                            <!-- 受診勧奨とその結果 -->
                            <div class="row no-gutters mt-5">
                                <div class="pl-3 col-md-12 col-sm-12">
                                    <span class="">受診勧奨とその結果</span
                                    ><span class="required"></span>
                                    <div class="pl-5">
                                        <select
                                            v-model="dataForm.code_result"
                                            class="form-select"
                                            :disabled="isViewSupportInput"
                                        >
                                            <option :value="null"></option>
                                            <option
                                                v-for="option in optionCodeResult"
                                                :key="option.value"
                                                :value="option.value"
                                            >
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <span class="text-danger">{{
                                            this.validateError.code_result
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row pl-5">
                                <!-- 受診勧奨とその結果 血圧 -->
                                <div
                                    class="col-md-3 col-sm-4 align-self-center pr-0"
                                >
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.blood_pressure_result"
                                        type="checkbox"
                                        class="form-check-input"
                                        id="血圧1"
                                    />
                                    <label class="ml-1" for="血圧1">血圧</label>
                                </div>
                                <!-- 受診勧奨とその結果 血糖値 -->
                                <div
                                    class="col-md-3 col-sm-4 align-self-center pr-0"
                                >
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.blood_result"
                                        type="checkbox"
                                        class="form-check-input"
                                        id="血糖値1"
                                    />
                                    <label class="ml-1" for="血糖値1"
                                        >血糖値</label
                                    >
                                </div>
                                <!-- 受診勧奨とその結果 脂質 -->
                                <div
                                    class="col-md-3 col-sm-4 align-self-center pr-0"
                                >
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.lipid_result"
                                        type="checkbox"
                                        class="form-check-input"
                                        id="脂質1"
                                    />
                                    <label class="ml-1" for="脂質1">脂質</label>
                                </div>
                            </div>
                            <div class="row pl-5">
                                <!-- 受診勧奨とその結果 その他 -->
                                <div
                                    class="col-auto col-sm-auto align-self-center pr-0"
                                >
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.other_result"
                                        type="checkbox"
                                        class="form-check-input"
                                        id="その他1"
                                    />
                                    <label class="ml-1" for="その他1"
                                        >その他</label
                                    >
                                </div>
                                <!-- 受診勧奨とその結果 その他 入力 -->
                                <div class="col-md-9 col-sm-8">
                                    <span
                                        v-if="dataForm.other_result"
                                        class="required"
                                    ></span>
                                    <input
                                        :disabled="
                                            isViewSupportInput ||
                                            (!isViewSupportInput &&
                                                !dataForm.other_result)
                                        "
                                        v-model="dataForm.other_text_result"
                                        type="text"
                                        class="form-control"
                                        :maxlength="OTHER_TEXT_RESULT"
                                    />
                                </div>
                                <span class="text-danger">{{
                                    this.validateError
                                        .blood_pressure_result_validate
                                }}</span>
                                <br />
                                <span class="text-danger">{{
                                    this.validateError.other_text_result
                                }}</span>
                            </div>

                            <div class="row">
                                <div class="col-12 pl-0">
                                    <!-- 服薬状況 -->
                                    <div class="row no-gutters">
                                        <div class="col-auto">
                                            <span class="pl-3">服薬状況</span>
                                        </div>
                                        <!-- 服薬状況 服薬なし -->
                                        <div
                                            class="col-md-3 col-sm-4 align-self-center pr-0 ml-3"
                                        >
                                            <input
                                                :disabled="isViewSupportInput"
                                                v-model="dataForm.no_medication"
                                                type="checkbox"
                                                class="form-check-input"
                                                id="服薬状況_服薬なし"
                                            />
                                            <label
                                                class="ml-1"
                                                for="服薬状況_服薬なし"
                                                >服薬なし</label
                                            >
                                        </div>
                                    </div>

                                    <div class="row pl-5">
                                        <!-- 服薬状況 血圧 -->
                                        <div
                                            class="col-md-3 col-sm-4 align-self-center pr-0"
                                        >
                                            <input
                                                :disabled="isViewSupportInput"
                                                v-model="
                                                    dataForm.blood_pressure_status
                                                "
                                                type="checkbox"
                                                class="form-check-input"
                                                id="血圧2"
                                            />
                                            <label class="ml-1" for="血圧2"
                                                >血圧</label
                                            >
                                        </div>
                                        <!-- 服薬状況 血糖値 -->
                                        <div
                                            class="col-md-3 col-sm-4 align-self-center pr-0"
                                        >
                                            <input
                                                :disabled="isViewSupportInput"
                                                v-model="dataForm.blood_status"
                                                type="checkbox"
                                                class="form-check-input"
                                                id="血糖値2"
                                            />
                                            <label class="ml-1" for="血糖値2"
                                                >血糖値</label
                                            >
                                        </div>
                                        <!-- 服薬状況 脂質 -->
                                        <div
                                            class="col-md-3 col-sm-4 align-self-center pr-0"
                                        >
                                            <input
                                                :disabled="isViewSupportInput"
                                                v-model="dataForm.lipid_status"
                                                type="checkbox"
                                                class="form-check-input"
                                                id="脂質2"
                                            />
                                            <label class="ml-1" for="脂質2"
                                                >脂質</label
                                            >
                                        </div>
                                    </div>
                                    <div class="row pl-5">
                                        <!-- 服薬状況 その他 -->
                                        <div
                                            class="col-auto col-sm-auto align-self-center pr-0"
                                        >
                                            <input
                                                :disabled="isViewSupportInput"
                                                v-model="dataForm.other_status"
                                                type="checkbox"
                                                class="form-check-input"
                                                id="その他2"
                                            />
                                            <label class="ml-1" for="その他2"
                                                >その他</label
                                            >
                                        </div>
                                        <!-- 服薬状況 その他 フリー入力 -->
                                        <div class="col-md-9 col-sm-8">
                                            <span
                                                v-if="dataForm.other_status"
                                                class="required"
                                            ></span>
                                            <input
                                                :disabled="
                                                    isViewSupportInput ||
                                                    (!isViewSupportInput &&
                                                        !dataForm.other_status)
                                                "
                                                v-model="
                                                    dataForm.other_text_status
                                                "
                                                type="text"
                                                class="form-control"
                                                :maxlength="OTHER_TEXT_STATUS"
                                            />
                                        </div>
                                        <span class="text-danger">{{
                                            this.validateError.other_text_status
                                        }}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- 受診状況、服薬状況等 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    受診状況、服薬状況等
                                    <span
                                        v-if="examinationStatusRequired()"
                                        class="required"
                                    ></span>
                                    <span class="ml-5"
                                        >(
                                        {{
                                            dataForm.examination_status
                                                ?.length ?? 0
                                        }}
                                        /
                                        {{ EXAMINATION_STATUS_MAX_LEN }} )</span
                                    >
                                    <textarea
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.examination_status"
                                        cols="30"
                                        rows="10"
                                        class="form-control"
                                        :maxlength="EXAMINATION_STATUS_MAX_LEN"
                                    >
                                    </textarea>
                                    <span class="text-danger">{{
                                        this.validateError.examination_status
                                    }}</span>
                                </div>
                            </div>

                            <!-- 運動で減らすkcal/日 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    運動で減らすkcal/日<span
                                        class="required"
                                    ></span>
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.activity_kcal_reduce"
                                        type="number"
                                        class="form-control"
                                        :maxlength="ACTIVITY_KCAL_REDUCE"
                                        step="1"
                                        min="0"
                                        max="9999"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.activity_kcal_reduce
                                    }}</span>
                                </div>
                            </div>

                            <!-- 食事で減らすkcal/日 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    食事で減らすkcal/日<span
                                        class="required"
                                    ></span>
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.eat_kcal_reduce"
                                        type="number"
                                        class="form-control"
                                        :maxlength="EAT_KCAL_REDUCE"
                                        step="1"
                                        min="0"
                                        max="9999"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.eat_kcal_reduce
                                    }}</span>
                                </div>
                            </div>

                            <!-- 行動計画 -->
                            <!-- 第4期以降 -->
                            <template v-if="splitActivityPlan">
                                <div class="row">
                                    <div class="font-weight-bold mt-3">
                                        行動計画
                                    </div>
                                    <p class="small">
                                        行動計画は1枠に１つ入力してください。２つ目を入力する場合は、＋ボタンを押して新しい枠に入力してください
                                    </p>
                                </div>
                                <div
                                    class="row"
                                    v-for="(
                                        activityPlanType, index
                                    ) in activityPlanTypes"
                                    :key="index"
                                >
                                    <div class="col-md-12 col-sm-12">
                                        {{ activityPlanType.label }}
                                        <v-template v-if="!isViewSupportInput">
                                            <v-btn
                                                v-if="
                                                    useSecondActivityPlanFlags[
                                                        activityPlanType.key
                                                    ]
                                                "
                                                icon
                                                small
                                                color="error"
                                                @click="
                                                    removeSecondActivityPlan(
                                                        activityPlanType.key
                                                    )
                                                "
                                            >
                                                <v-icon medium
                                                    >mdi-minus</v-icon
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-else
                                                icon
                                                small
                                                color="success"
                                                @click="
                                                    addSecondActivityPlan(
                                                        activityPlanType.key
                                                    )
                                                "
                                            >
                                                <v-icon medium>mdi-plus</v-icon>
                                            </v-btn>
                                        </v-template>

                                        <!-- <span class="ml-5">( {{ dataForm[activityPlanType.key]?.length ?? 0 }} / {{ ACTIVITY_PLAN_MAX_LEN }} )</span> -->
                                        <textarea
                                            :disabled="isViewSupportInput"
                                            v-model="
                                                dataForm[activityPlanType.key]
                                            "
                                            name="tel_memo"
                                            cols="30"
                                            rows="1"
                                            class="form-control"
                                        />
                                        <textarea
                                            v-if="
                                                useSecondActivityPlanFlags[
                                                    activityPlanType.key
                                                ]
                                            "
                                            :disabled="isViewSupportInput"
                                            v-model="
                                                dataForm[
                                                    `${activityPlanType.key}2`
                                                ]
                                            "
                                            name="tel_memo"
                                            cols="30"
                                            rows="1"
                                            class="form-control mt-1"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <span class="text-danger">{{
                                        validateError.activity_plan_else
                                    }}</span>
                                </div>
                            </template>
                            <!-- 第3期まで -->
                            <template v-else>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        行動計画<span class="required"></span
                                        ><span class="ml-5"
                                            >(
                                            {{
                                                dataForm.activity_plan_1
                                                    ?.length ?? 0
                                            }}
                                            /
                                            {{ ACTIVITY_PLAN_MAX_LEN }} )</span
                                        >
                                        <textarea
                                            :disabled="isViewSupportInput"
                                            v-model="dataForm.activity_plan_1"
                                            name="tel_memo"
                                            cols="30"
                                            rows="10"
                                            max="255"
                                            class="form-control"
                                            :maxlength="ACTIVITY_PLAN_MAX_LEN"
                                        >
                                        </textarea>
                                        <span class="text-danger">{{
                                            this.validateError.activity_plan_1
                                        }}</span>
                                    </div>
                                </div>
                            </template>
                            <!-- 食生活・運動関連 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    食生活・運動関連<span
                                        class="required"
                                    ></span
                                    ><span class="ml-5"
                                        >(
                                        {{
                                            dataForm.activity_and_eat?.length ??
                                            0
                                        }}
                                        / {{ ACTIVITY_AND_EAT_MAX_LEN }} )</span
                                    >
                                    <textarea
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.activity_and_eat"
                                        name="tel_memo"
                                        cols="30"
                                        rows="10"
                                        class="form-control"
                                        :maxlength="ACTIVITY_AND_EAT_MAX_LEN"
                                    >
                                    </textarea>
                                    <span class="text-danger">{{
                                        this.validateError.activity_and_eat
                                    }}</span>
                                </div>
                            </div>
                            <!-- </template> -->
                            <!-- 自由欄 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    自由欄<span class="ml-5"
                                        >( {{ dataForm.free?.length ?? 0 }} /
                                        {{ FREE_MAX_LEN }} )</span
                                    >
                                    <textarea
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.free"
                                        name="tel_memo"
                                        cols="30"
                                        rows="10"
                                        class="form-control"
                                        :maxlength="FREE_MAX_LEN"
                                    >
                                    </textarea>
                                    <span class="text-danger">{{
                                        this.validateError.free
                                    }}</span>
                                </div>
                            </div>
                            <!-- 電話連絡先(携帯) -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    電話連絡先(携帯)
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.patient_tel_01"
                                        type="text"
                                        class="form-control"
                                        :maxlength="TEL_MAX_LEN"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.patient_tel_01
                                    }}</span>
                                </div>
                            </div>
                            <!-- 電話連絡先(自宅) -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    電話連絡先(自宅)
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.patient_tel_02"
                                        type="text"
                                        class="form-control"
                                        :maxlength="TEL_MAX_LEN"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.patient_tel_02
                                    }}</span>
                                </div>
                            </div>
                            <!-- 電話連絡先(職場) -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    電話連絡先(職場)
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.patient_tel_03"
                                        type="text"
                                        class="form-control"
                                        :maxlength="TEL_MAX_LEN"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.patient_tel_03
                                    }}</span>
                                </div>
                            </div>
                            <!-- 電話連絡先(その他) -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    電話連絡先(その他)
                                    <input
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.patient_tel_04"
                                        type="text"
                                        class="form-control"
                                        :maxlength="TEL_MAX_LEN"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.patient_tel_04
                                    }}</span>
                                </div>
                            </div>
                            <!-- メールアドレス -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 pl-0">
                                            メールアドレス
                                            <span
                                                v-if="
                                                    !dataForm.mail_address_leave_blank
                                                "
                                                class="required"
                                            ></span>
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-12 text-right"
                                        >
                                            <input
                                                :disabled="isViewSupportInput"
                                                v-model="
                                                    dataForm.mail_address_leave_blank
                                                "
                                                type="checkbox"
                                                class="form-check-input"
                                                id="空欄にする"
                                            />
                                            <label class="ml-3" for="空欄にする"
                                                >空欄にする</label
                                            >
                                        </div>
                                    </div>
                                    <input
                                        :disabled="
                                            isViewSupportInput ||
                                            dataForm.mail_address_leave_blank
                                        "
                                        v-model="dataForm.patient_mail_01"
                                        type="text"
                                        class="form-control"
                                        :maxlength="MAIL_MAX_LEN"
                                    />
                                    <span class="text-danger">{{
                                        this.validateError.mail
                                    }}</span>
                                </div>
                            </div>
                            <!-- LINE -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    LINE ID
                                    <input
                                        :disabled="isViewSupportInput"
                                        type="text"
                                        class="form-control dots-the-end"
                                        id="lineUserName"
                                        v-model="lineUserName"
                                        @click="isDialogLine = true"
                                        readonly
                                    />
                                </div>
                            </div>

                            <!-- 連絡手段 希望 -->
                            <div class="row">
                                <div class="col-12 pl-0">
                                    <div class="row no-gutters mb-0">
                                        <div class="col-auto">
                                            <span class="pl-3"
                                                >連絡手段 希望</span
                                            >
                                        </div>
                                    </div>
                                    <!-- 連絡手段 第１希望 -->
                                    <div class="row pl-5 mt-0">
                                        <div
                                            class="col-auto col-sm-auto align-self-center pr-0"
                                        >
                                            <span>第１希望</span
                                            ><span class="required"></span>
                                        </div>
                                        <div class="col-md-9 col-sm-8">
                                            <select
                                                :disabled="isViewSupportInput"
                                                v-model="
                                                    dataForm.contact_way_priority_01
                                                "
                                                class="form-select"
                                            >
                                                <option :value="null"></option>
                                                <option
                                                    v-for="option in optionContactWayPriority01"
                                                    :key="option.value"
                                                    :value="option.value"
                                                >
                                                    {{ option.text }}
                                                </option>
                                            </select>
                                            <span class="text-danger">{{
                                                this.validateError
                                                    .contact_way_priority_01
                                            }}</span>
                                        </div>
                                    </div>
                                    <!-- 連絡手段 第２希望 -->
                                    <div class="row pl-5">
                                        <div
                                            class="col-auto col-sm-auto align-self-center pr-0"
                                        >
                                            <span class="pr-2">第２希望</span>
                                        </div>
                                        <div class="col-md-9 col-sm-8">
                                            <select
                                                :disabled="isViewSupportInput"
                                                v-model="
                                                    dataForm.contact_way_priority_02
                                                "
                                                class="form-select"
                                            >
                                                <option :value="null"></option>
                                                <option
                                                    v-for="option in optionContactWayPriority02"
                                                    :key="option.value"
                                                    :value="option.value"
                                                >
                                                    {{ option.text }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- 連絡手段 第３希望 -->
                                    <div class="row pl-5">
                                        <div
                                            class="col-auto col-sm-auto align-self-center pr-0"
                                        >
                                            <span class="pr-2">第３希望</span>
                                        </div>
                                        <div class="col-md-9 col-sm-8">
                                            <select
                                                :disabled="isViewSupportInput"
                                                v-model="
                                                    dataForm.contact_way_priority_03
                                                "
                                                class="form-select"
                                            >
                                                <option :value="null"></option>
                                                <option
                                                    v-for="option in optionContactWayPriority03"
                                                    :key="option.value"
                                                    :value="option.value"
                                                >
                                                    {{ option.text }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 継続支援伝達事項 継続支援辞退 -->
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    継続支援伝達事項
                                    <div class="row">
                                        <div
                                            class="col-sm-12 align-self-center"
                                        >
                                            <input
                                                :disabled="isViewSupportInput"
                                                v-model="
                                                    dataForm.denied_support
                                                "
                                                id="血圧"
                                                type="checkbox"
                                                class="form-check-input"
                                            />
                                            <label for="血圧" class="ml-3"
                                                >継続支援辞退</label
                                            >
                                        </div>
                                    </div>
                                    <span class="text-danger"></span>
                                </div>
                            </div>
                            <!-- 継続支援伝達事項 コンボボックス -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <select
                                        :disabled="isViewSupportInput"
                                        v-model="dataForm.code_denied_support"
                                        class="form-select"
                                    >
                                        <option :value="null"></option>
                                        <option
                                            v-for="option in codeDeniedSupport"
                                            :key="option.value"
                                            :value="option.value"
                                        >
                                            {{ option.text }}
                                        </option>
                                    </select>
                                    <span class="text-danger"></span>
                                </div>
                            </div>
                            <!-- 継続支援伝達事項 フリー入力 -->
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    継続支援伝達事項 備考（補足・希望時間など）
                                    <span
                                        v-if="exampleContinueSupportRequired()"
                                        class="required"
                                    ></span>
                                    <span class="ml-5"
                                        >(
                                        {{
                                            dataForm.example_continue_support
                                                ?.length ?? 0
                                        }}
                                        /
                                        {{ EXAMPLE_CONTINUE_SUPPORT_MAX_LEN }}
                                        )</span
                                    >
                                    <textarea
                                        :disabled="isViewSupportInput"
                                        v-model="
                                            dataForm.example_continue_support
                                        "
                                        name="tel_memo"
                                        cols="30"
                                        rows="10"
                                        class="form-control"
                                        :maxlength="
                                            EXAMPLE_CONTINUE_SUPPORT_MAX_LEN
                                        "
                                    >
                                    </textarea>
                                    <span class="text-danger">{{
                                        errors.errorRequiredExampleContinueSupport
                                    }}</span>
                                    <span class="text-danger">{{
                                        this.validateError
                                            .example_continue_support
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer class="ml-0 mr-2">
                <div class="row footer-button">
                    <div class="col-3 pr-0" v-if="btnSaveDraftStatus">
                        <button
                            @click="handleBeforeDialog('isDialogSaveDraft')"
                            type="button"
                            class="btn btn-secondary"
                        >
                            一時保存
                        </button>
                    </div>
                    <div class="col-3 pr-0" v-if="btnDeleteStatus">
                        <button
                            @click="handleBeforeDialog('isDialogDelete')"
                            type="button"
                            class="btn btn-danger"
                        >
                            確定解除
                        </button>
                    </div>
                    <div class="col-3 pl-0" v-if="btnEditStatus">
                        <button
                            @click="handleBeforeDialog('isDialogEdit')"
                            type="button"
                            class="btn btn-primary float-right"
                        >
                            修正
                        </button>
                    </div>
                    <div class="col-3 pl-0" v-if="btnConfirmStatus">
                        <button
                            type="button"
                            class="btn btn-primary float-right"
                            @click="handleBeforeDialog('isDialogConfirm')"
                        >
                            確定
                        </button>
                    </div>
                </div>
            </footer>
        </div>
        <Dialog :isShow="isDialogSaveDraft" persistent :maxWidth="600">
            <DialogSaveDraft
                @saveDraft="saveDraft"
                @closeDialog="onHandleCloseDialog('isDialogSaveDraft')"
            />
        </Dialog>
        <Dialog :isShow="isDialogDelete" persistent :maxWidth="600">
            <DialogDelete
                @deleteSegment="deleteSegment"
                @closeDialog="onHandleCloseDialog('isDialogDelete')"
            />
        </Dialog>
        <Dialog :isShow="isDialogEdit" persistent :maxWidth="600">
            <DialogEdit
                @editSegment="editSegment"
                @closeDialog="onHandleCloseDialog('isDialogEdit')"
            />
        </Dialog>
        <Dialog :isShow="isDialogConfirm" persistent :maxWidth="600">
            <DialogConfirm
                proc_name="確定"
                @confirmDialog="confirmDialog"
                @closeDialog="onHandleCloseDialog('isDialogConfirm')"
            />
        </Dialog>
        <Dialog :isShow="isDialogLine" persistent :maxWidth="600">
            <v-card>
                <v-container>
                    <div class="row">
                        <div class="col-12 d-flex">
                            LINE ID
                            <v-autocomplete
                                class="ml-3 col-7 container-autocomplete"
                                outlined
                                :disabled="isViewSupportInput"
                                v-model="lineUserAsync"
                                :items="itemsLineUser"
                                :search-input.sync="searchPatientLine"
                                hide-no-data
                                hide-details
                                no-filter
                                :loading="isLoading"
                                placeholder="ここに入力して検索"
                            />
                            <button
                                v-if="!isViewSupportInput"
                                style="height: 38px; margin-top: -5px"
                                class="ml-3 btn btn-info"
                                @click="syncLineUser"
                            >
                                紐付け
                            </button>
                        </div>
                    </div>
                    <div class="col-12">
                        <div
                            class="mt-2"
                            v-for="(item, index) in listContactLineOld"
                            :key="item.value + index + 'patient_line'"
                        >
                            <label
                                class="mr-8 ml-3 form-check-label"
                                :for="item.value"
                            >
                                {{ item.label }}
                            </label>
                            <button
                                @click="deleteContactLine(item.value)"
                                type="button"
                                class="btn btn-danger"
                            >
                                紐付け解除
                            </button>
                        </div>
                    </div>
                    <v-card-actions
                        class="d-flex align-content-center justify-content-between mr-2 ml-2"
                    >
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="isDialogLine = false"
                        >
                            キャンセル
                        </button>
                    </v-card-actions>
                </v-container>
            </v-card>
        </Dialog>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import L1 from "@/services/api/L1";
import moment from "moment";
import Dialog from "@/components/common/Dialog";
import DialogConfirm from "@/components/dashboard/DialogConfirm";
import DialogDelete from "@/components/dashboard/DialogDelete";
import DialogEdit from "@/components/dashboard/DialogEdit";
import DialogSaveDraft from "@/components/dashboard/DialogSaveDraft";
import { INTERNAL_SERVER_ERROR, SUCCESS } from "@/constants";
import _ from "lodash";
import DashboardService from "@/services/api/DashboardRepository";
import mixins from "@/mixins";

import {
    ACTIVITY_PLAN_SEPARATOR,
    BTN_SAVE_DRAFT,
    BTN_CONFIRM,
    BTN_EDIT,
    BTN_DELETE,
    ENVS,
    SUPPORT_PROGRESS_PLAN,
    SUPPORT_PROGRESS_SCHEDULE,
    SUPPORT_PROGRESS_IN_PROGRESS,
    SUPPORT_PROGRESS_CONFIRM,
    TOAST_TIME_OUT
} from "@/common/constants";
import DatePicker from "vue2-datepicker";

import TabContentInputSupport from "@/components/common/TabContentInputSupport";

import StringUtil from "@/functions/StringUtil";
const { toFullWidthString } = StringUtil();

export default {
    mixins: [mixins],
    name: "L1",
    components: {
        Dialog,
        DialogConfirm,
        DialogDelete,
        DialogEdit,
        DialogSaveDraft,
        TabContentInputSupport,
        DatePicker
    },
    data() {
        return {
            MAIL_MAX_LEN: 255,
            TEL_MAX_LEN: 15,
            OTHER_TEXT_RESULT: 30,
            OTHER_TEXT_STATUS: 30,
            ACTIVITY_KCAL_REDUCE: 4,
            EAT_KCAL_REDUCE: 4,
            EXAMINATION_STATUS_MAX_LEN: 500,
            ACTIVITY_PLAN_MAX_LEN: 250,
            ACTIVITY_AND_EAT_MAX_LEN: 250,
            FREE_MAX_LEN: 1000,
            EXAMPLE_CONTINUE_SUPPORT_MAX_LEN: 500,
            searchPatientLine: null,
            isLoading: false,
            isDialogLine: false,
            lineUserIdChoose: null,
            lineUserId: null,
            listContactLine: [
                {
                    value: 0,
                    label: ""
                }
            ],
            listContactLineOld: [
                {
                    value: 0,
                    label: "",
                    patient_id: ""
                }
            ],
            lineUserAsync: null,
            lineUserName: null,
            optionLeader: [],
            optionFirstTimeSupport: [],
            optionCodeChangeActivity: [],
            optionSmocking: [],
            optionAlcohol: [],
            optionCodeResult: [],
            optionCodeContinueSupport: [],
            optionContactWayPriority01: [],
            optionContactWayPriority02: [],
            optionContactWayPriority03: [],
            codeDeniedSupport: [],
            support_amount: null,
            dataForm: {
                start_date: new Date(),
                start_time: null,
                end_time: null,
                estimate_time: 0,
                system_users_leader_id: null,
                system_users_id: null,
                system_users_name: null,
                health_support_form_id: null,
                patient_tel_01: null,
                patient_tel_02: null,
                patient_tel_03: null,
                patient_tel_04: null,
                mail_address_leave_blank: false,
                health_support_form_id_blank: false,
                patient_mail_01: null,
                code_change_activity: null,
                start_width: null,
                start_waist: null,
                target: null,
                target_width: null,
                target_waist: null,
                smocking: null,
                alcohol: null,
                code_result: null,
                blood_pressure_result: false,
                blood_result: false,
                lipid_result: false,
                other_result: false,
                other_text_result: null,
                no_medication: false,
                blood_pressure_status: false,
                blood_status: false,
                lipid_status: false,
                other_status: false,
                other_text_status: null,
                examination_status: null,
                activity_kcal_reduce: 0,
                eat_kcal_reduce: 0,
                kcal_reduce: null,
                activity_plan_1: null,
                activity_plan_eating: null,
                activity_plan_exercise: null,
                activity_plan_smoking: null,
                activity_plan_rest: null,
                activity_plan_else: null,
                activity_plan_eating2: null,
                activity_plan_exercise2: null,
                activity_plan_smoking2: null,
                activity_plan_rest2: null,
                activity_plan_else2: null,
                activity_and_eat: null,
                free: null,
                denied_support: false,
                code_denied_support: null,
                example_continue_support: null,
                contact_way_priority_01: null,
                contact_way_priority_02: null,
                contact_way_priority_03: null
            },
            validateError: {
                start_date: null,
                start_time: null,
                end_time: null,
                system_users_leader_id: null,
                system_users_id: null,
                system_users_name: null,
                health_support_form_id: null,
                patient_tel_01: null,
                patient_tel_02: null,
                patient_tel_03: null,
                patient_tel_04: null,
                mail: null,
                code_change_activity: null,
                start_width: null,
                start_waist: null,
                target: null,
                target_width: null,
                target_waist: null,
                smocking: null,
                alcohol: null,
                code_result: null,
                blood_pressure_result_validate: null,
                activity_kcal_reduce: null,
                eat_kcal_reduce: null,
                activity_plan_1: null,
                activity_and_eat: null,
                activity_plan_else: null,
                other_text_status: null,
                other_text_result: null,
                examination_status: null,
                example_continue_support: null,
                free: null,
                contact_way_priority_01: null,
                contact_way_priority_02: null,
                contact_way_priority_03: null
            },
            validateWarn: {},
            errors: {
                end_time: "",
                errorRequired: "",
                errorRequiredResult: "",
                errorRequiredExaminationStatus: "",
                errorRequiredOtherTextResult: "",
                errorRequiredOtherTextStatus: "",
                errorRequiredExampleContinueSupport: ""
            },
            isDialogSaveDraft: false,
            isDialogDelete: false,
            isDialogEdit: false,
            isDialogConfirm: false,

            // button status
            btnSaveDraftStatus: false,
            btnConfirmStatus: false,
            btnEditStatus: false,
            btnDeleteStatus: false,
            getSupportProgressStatus: null,

            optionExecutorDefine: [],
            optionExecutor: [],
            healthExamDate: null,
            examWidth: 0,
            examWaist: 0,
            message_warning_target_width: null,
            message_warning_target_waist: null,
            start_width_old: null,
            start_waist_old: null,
            target_old: null,
            checkHealthSupportFormsBySegmentVal: null,
            flagLoadFirst: true,
            isFormDataLoading: false,
            version: "v1",
            eval_div: null,
            evaluator_div: null,
            data_patient: null,

            // このセグメントでのポイント (付与済のポイント)
            currentSegmentProcessPoint: 0,
            // 健診結果(検査項目)
            diagnosticList: [],
            // 2つ目の目標使用フラグ
            useSecondActivityPlanFlags: {
                activity_plan_eating: false,
                activity_plan_exercise: false,
                activity_plan_smoking: false,
                activity_plan_rest: false,
                activity_plan_else: false
            }
        };
    },
    props: {
        roleSystem: [String, Number],
        previousSupportProgressStatus: [String, Number],
        nextSupportProgressStatus: [String, Number],
        processingCurrentSegment: [String, Number],
        classTagColor: [String],
        showTabs: [String],
        support_flow_status: [String],
        isViewSupportInput: [Boolean],
        reception: [Object, null],
        patient: [Object, Number]
    },
    async created() {
        await L1.getVersion("L1", this.segment).then(res => {
            const { data, status } = res;
            if (res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(
                    `[${res?.error.messageID}] ${res?.error.message}`,
                    TOAST_TIME_OUT
                );
                return;
            }
            if (status !== SUCCESS) return;
            this.version = data.data.form_version;
            this.eval_div = data.data.support_eval_div;
            this.evaluator_div = data.data.evaluator_div;
        });

        await L1.getListSupportPerson().then(res => {
            this.optionLeader = [];
            const data = [];

            if (res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(
                    `[${res?.error.messageID}] ${res?.error.message}`,
                    TOAST_TIME_OUT
                );
                return;
            }

            if (this.isSystemAdminOrBackOffice()) {
                // システム管理者 or 事務局
                res.data.data.map(x => {
                    data.push({
                        text: `[${"0".repeat(7 - String(x.id).length)}${
                            x.id
                        }] ${x.user_name_disp}`,
                        value: x.id,
                        user_occupation: x.user_occupation
                    });
                });
            } else if (this.isSupporter(true)) {
                // 支援担当者の場合

                // ログインユーザ
                (this.user.text = `[${"0".repeat(
                    7 - String(this.user.id).length
                )}${this.user.id}] ${this.user.user_name_disp}`),
                    (this.user.value = this.user.id);
                data.push(this.user);

                // 支援予定者.
                const user_scheduled_support_staff_id = res.data.data.filter(
                    x => x.id == this.segment.scheduled_support_staff_id
                );
                if (user_scheduled_support_staff_id.length > 0) {
                    let a = user_scheduled_support_staff_id[0];
                    a.text = `[${"0".repeat(7 - String(a.id).length)}${a.id}] ${
                        a.user_name_disp
                    }`;
                    a.value = a.id;
                    a.user_occupation = a.user_occupation;
                    data.push(a);
                }

                // 支援実施者.
                const user_support_staff_id = res.data.data.filter(
                    x => x.id == this.segment.support_staff_id
                );
                if (user_support_staff_id.length > 0) {
                    let b = user_support_staff_id[0];
                    b.text = `[${"0".repeat(7 - String(b.id).length)}${b.id}] ${
                        b.user_name_disp
                    }`;
                    b.value = b.id;
                    b.user_occupation = b.user_occupation;
                    data.push(b);
                }
            }

            this.optionLeader = _.uniqBy(data, "value");
        });

        await L1.getFirstTimeSupport().then(res => {
            this.optionFirstTimeSupport = [];
            if (res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(
                    `[${res?.error.messageID}] ${res?.error.message}`,
                    TOAST_TIME_OUT
                );
                return;
            }

            let data = res.data.data;
            if (this.is4th) {
                data = data.filter(x => x.map_system_code_04 == "1");
            } else {
                data = data.filter(x => !x.map_system_code_04);
            }
            data.forEach(x => {
                this.optionFirstTimeSupport.push({
                    text: x.support_form_name_disp_02,
                    value: x.id,
                    min_support_amount: x.min_support_amount
                });
            });
        });
        await L1.getOptionByCode({
            eval_item_code: null,
            eval_div: this.eval_div,
            evaluator_div: this.evaluator_div,
            health_guidance_period: this.healthGuidancePeriod
        }).then(res => {
            const data = res.data.data;
            if (res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(
                    `[${res?.error.messageID}] ${res?.error.message}`,
                    TOAST_TIME_OUT
                );
                return;
            }
            const optionExecutor = "0001000032";
            const smocking = "0001000100";
            const alcohol = "0001000110";
            const code_result = "0001000120";
            const code_denied_support = "0001000251";
            const code_change_activity = "0001000050";
            const contact_way_priority_01 = "0001000270";
            const contact_way_priority_02 = "0001000271";
            const contact_way_priority_03 = "0001000272";
            this.optionCodeChangeActivity = data[code_change_activity];
            this.optionSmocking = data[smocking];
            this.optionAlcohol = data[alcohol];
            this.optionCodeResult = data[code_result];
            this.codeDeniedSupport = data[code_denied_support];
            this.optionExecutor = data[optionExecutor];
            this.optionExecutorDefine = data[optionExecutor];

            this.optionContactWayPriority01 = data[contact_way_priority_01];
            this.optionContactWayPriority02 = data[contact_way_priority_02];
            this.optionContactWayPriority03 = data[contact_way_priority_03];
        });

        let that = this;
        this.$root.$on("reloadFormSupport", function () {
            that.clearSegment();
        });

        this.getSupportProgressStatus = null;
        await L1.getSegmentDetail(this.segment.id).then(res => {
            if (res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(
                    `[${res?.error.messageID}] ${res?.error.message}`,
                    TOAST_TIME_OUT
                );
                return;
            }
            this.checkHealthSupportFormsBySegmentVal =
                res.data.data.health_support_form_id;
            this.getSupportProgressStatus =
                res.data.data.support_progress_status;
            this.currentSegmentProcessPoint = this.segment.support_point;
            if (
                this.getSupportProgressStatus == SUPPORT_PROGRESS_PLAN ||
                this.getSupportProgressStatus == SUPPORT_PROGRESS_SCHEDULE
            ) {
                let support_datetime = null;
                let support_date = null;
                let support_time = null;
                if (this.segment?.plan_support_datetime) {
                    support_datetime = this.segment?.plan_support_datetime;
                    support_date = this.segment?.plan_support_date;
                    support_time = this.segment?.plan_support_time;
                }
                if (this.segment?.scheduled_support_datetime) {
                    support_datetime = this.segment?.scheduled_support_datetime;
                    support_date = this.segment?.scheduled_support_date;
                    support_time = this.segment?.scheduled_support_time;
                }
                if (support_date) {
                    this.dataForm.start_date = support_date;
                    this.handleChangeStartDate(support_date);
                }

                this.support_amount =
                    this.segment?.health_support_form.min_support_amount;
                if (support_time) {
                    this.dataForm.start_time = support_time;
                    this.dataForm.end_time = moment(support_datetime)
                        .add(this.support_amount, "minutes")
                        .format("HH:mm");
                }
            } else {
                this.support_amount = res.data.data.support_amount;
            }
            this.checkPermissionButton();
        });

        await this.getList();

        await DashboardService.getContactLine()
            .then(response => {
                if (response.errorCode === INTERNAL_SERVER_ERROR) {
                    this.$toast.error(
                        `[${response?.error.messageID}] ${response?.error.message}`,
                        TOAST_TIME_OUT
                    );
                    return;
                }
                if (response && response.data && response.data.data) {
                    const {
                        data: { data }
                    } = response;

                    this.listContactLine = data?.map(contactIt => {
                        return {
                            patient_id: contactIt.patient_id,
                            value: contactIt.line_user_id,
                            label:
                                contactIt?.line_user_name +
                                " - " +
                                contactIt?.messages +
                                (contactIt?.patient_id ? " ー　連携済み" : "")
                        };
                    });
                    this.lineUserAsync = this.listContactLine.filter(
                        x => x.patient_id == this.data_patient.id
                    )[0]?.value;
                } else {
                    this.notifyError(
                        "LINEユーザー情報取得処理のエラーが発生しました。"
                    );
                }
            })
            .catch(error => {
                this.notifyError(
                    `LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`
                );
            });
        await this.getListContactLineOld();

        DashboardService.getHealthResult(this.patient.id).then(result => {
            this.diagnosticList = result.data.data.diagnostic_list;
        });
    },
    watch: {
        searchPatientLine(val) {
            if (val == null || val == "" || (val && val.trim().length == 0)) {
                this.listContactLine = [];
                return;
            }
            if (val && this.listContactLine.find(el => el.label === val))
                return;
            if (this.isLoading) return;
            this.isLoading = true;
            DashboardService.getContactLine(val)
                .then(response => {
                    this.isLoading = false;
                    if (response.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(
                            `[${response?.error.messageID}] ${response?.error.message}`,
                            TOAST_TIME_OUT
                        );
                        return;
                    }
                    if (response && response.data && response.data.data) {
                        const {
                            data: { data }
                        } = response;
                        this.listContactLine = data?.map(contactIt => {
                            return {
                                patient_id: contactIt.patient_id,
                                value: contactIt.line_user_id,
                                label:
                                    contactIt?.line_user_name +
                                    " - " +
                                    contactIt?.messages +
                                    (contactIt?.patient_id
                                        ? " ー　連携済み"
                                        : "")
                            };
                        });
                    } else {
                        this.notifyError(
                            "LINEユーザー情報取得処理のエラーが発生しました。"
                        );
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    this.notifyError(
                        `LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`
                    );
                });
        },
        segment(value) {
            this.flagLoadFirst = true;
        },

        /**
         * 実施時間 開始
         */
        "dataForm.start_time"() {
            if (
                this.dataForm.start_time == null ||
                this.dataForm.end_time == null
            ) {
                this.support_amount = 0;
            }

            if (
                this.dataForm.start_time != null &&
                this.dataForm.end_time != null
            ) {
                var date1 = new Date(
                    "07/30/2019" + " " + this.dataForm.start_time
                );
                var date2 = new Date(
                    "07/30/2019" + " " + this.dataForm.end_time
                );
                const amount =
                    (date2.getTime() - date1.getTime()) / (1000 * 60);
                this.support_amount = Math.max(0, amount);
            }
        },
        /**
         * 実施時間 終了
         */
        "dataForm.end_time"() {
            if (
                this.dataForm.start_time == null ||
                this.dataForm.end_time == null
            ) {
                this.support_amount = 0;
            }

            if (
                this.dataForm.start_time != null &&
                this.dataForm.end_time != null
            ) {
                var date1 = new Date(
                    "07/30/2019" + " " + this.dataForm.start_time
                );
                var date2 = new Date(
                    "07/30/2019" + " " + this.dataForm.end_time
                );
                const amount =
                    (date2.getTime() - date1.getTime()) / (1000 * 60);
                this.support_amount = Math.max(0, amount);
            }
        },
        "dataForm.system_users_id"() {
            if (!this.flagLoadFirst) {
                let options = this.optionLeader.filter(
                    x => x.value == this.dataForm.system_users_id
                );
                if (options.length > 0) {
                    let user_occupation = options[0].user_occupation;
                    this.dataForm.system_users_leader_id = parseInt(
                        this.optionExecutorDefine.filter(
                            x => x.value == user_occupation
                        )[0].value
                    );
                }
            } else {
                this.flagLoadFirst = false;
            }
        },
        /**
         * 食生活・運動関連
         */
        "dataForm.activity_and_eat": {
            handler: function (newVal, oldVal) {
                if (!this.isFormDataLoading) {
                    if (newVal != null) {
                        this.dataForm.activity_and_eat =
                            toFullWidthString(newVal);
                    }
                }
            },
            deep: false, //ネストされたオブジェクトも監視する deepがfalseだとオブジェクト内の値が変更されても処理が実行されない
            immediate: false // 初期読み込み時にも呼び出す
        }
    },
    computed: {
        ...mapGetters({
            segment: "dashboard/getSegment",
            user: "common/getUser"
        }),

        /**
         * 第4期以降
         */
        is4th() {
            if (!this.reception) {
                return false;
            }
            return this.reception.health_guidance_period >= 4;
        },

        option_time_from_to() {
            return {
                format: "HH:mm",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle"
                },
                locale: moment.locale("ja")
            };
        },

        option_datetime() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle"
                },
                locale: moment.locale("ja")
            };
        },

        option_time() {
            return {
                format: "HH:mm",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle"
                },
                locale: moment.locale("ja")
            };
        },

        itemsLineUser() {
            return this.listContactLine.map(contact => {
                return {
                    text: contact.label,
                    value: contact.value
                };
            });
        },

        // 期
        healthGuidancePeriod() {
            if (!this.reception) {
                return null;
            } else {
                return this.reception.health_guidance_period;
            }
        },

        // 行動計画タイプ
        activityPlanTypes() {
            return [
                { key: "activity_plan_eating", label: "食習慣の改善" },
                { key: "activity_plan_exercise", label: "運動習慣の改善" },
                { key: "activity_plan_smoking", label: "喫煙習慣の改善" },
                { key: "activity_plan_rest", label: "休養習慣の改善" },
                { key: "activity_plan_else", label: "その他の生活習慣の改善" }
            ];
        },

        // 行動計画を種類ごとに分ける (第4期から)
        splitActivityPlan() {
            return this.healthGuidancePeriod >= 4;
        },

        /**
         * 健診時の体重
         */
        diagnosticWeight() {
            if (this.diagnosticList.length == 0) {
                return null;
            }
            const obj = this.diagnosticList.find(
                item => item.healthexam_item_code == "9N006000000000001"
            );
            if (obj && obj.healthexam_item_disp) {
                return Number(
                    obj.healthexam_item_disp.replace("kg", "").replace(/ /, "")
                );
            }
            return null;
        },

        /**
         * 健診時の腹囲
         */
        diagnosticWaist() {
            if (this.diagnosticList.length == 0) {
                return null;
            }
            const obj = this.diagnosticList.find(
                item => item.healthexam_item_code == "9N016160100000001"
            );
            if (obj && obj.healthexam_item_disp) {
                return Number(
                    obj.healthexam_item_disp.replace("cm", "").replace(/ /, "")
                );
            }
            return null;
        }
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            segmentBlockL: "dashboard/getSegmentBlockL"
        }),

        /**
         * 受診勧奨済みの場合true.
         */
        doneMedicalExamRecommend(code_result) {
            return code_result && [10, 20].indexOf(parseInt(code_result)) != -1;
        },
        async getListContactLineOld() {
            await DashboardService.getContactLineOld(this.data_patient.id)
                .then(response => {
                    if (response.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(
                            `[${response?.error.messageID}] ${response?.error.message}`,
                            TOAST_TIME_OUT
                        );
                        return;
                    }
                    if (response && response.data && response.data.data) {
                        const {
                            data: { data }
                        } = response;
                        this.listContactLineOld = data?.map(contactIt => {
                            return {
                                patient_id: contactIt.patient_id,
                                value: contactIt.line_user_id,
                                label:
                                    contactIt?.line_user_name +
                                    " - " +
                                    contactIt?.messages
                            };
                        });
                        this.lineUserName = this.listContactLineOld
                            .map(contact => contact.label)
                            .join(", ");
                    } else {
                        this.notifyError(
                            "LINEユーザー情報取得処理のエラーが発生しました。"
                        );
                    }
                })
                .catch(error => {
                    this.notifyError(
                        `LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`
                    );
                });
        },
        async deleteContactLine(line_user_id) {
            await DashboardService.deleteContactLine(line_user_id)
                .then(response => {
                    if (response.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(
                            `[${response?.error.messageID}] ${response?.error.message}`,
                            TOAST_TIME_OUT
                        );
                        return;
                    }
                    if (response) {
                        this.getListContactLineOld();
                    } else {
                        this.notifyError(
                            "対象者のLINEアカウントへの紐付けが失敗しました。"
                        );
                    }
                })
                .catch(error => {
                    this.hideLoading();
                    this.notifyError(
                        `LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`
                    );
                });
        },
        choosePatientLine() {
            if (this.lineUserIdChoose) {
                this.lineUserId = this.lineUserIdChoose;
                let idInput = "#" + this.lineUserIdChoose;
                this.lineUserName = $(idInput).data("text");
            }
            this.isDialogLine = false;
        },
        async syncLineUser() {
            const syncLineUserData = {
                line_user_id: this.lineUserAsync,
                patient_id: this.data_patient.id
            };
            await DashboardService.syncLineContact(syncLineUserData)
                .then(response => {
                    if (response.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(
                            `[${response?.error.messageID}] ${response?.error.message}`,
                            TOAST_TIME_OUT
                        );
                        return;
                    }
                    if (response && response.data && response.data.data) {
                        this.isUserSyncLine = true;
                        this.notifySuccess(
                            "対象者のLINEアカウントに紐付けました。"
                        );
                        this.lineUserAsync = null;
                        this.getListContactLineOld();
                    } else {
                        this.notifyError(
                            "対象者のLINEアカウントへの紐付けが失敗しました。"
                        );
                    }
                })
                .catch(error => {
                    this.notifyError(
                        `対象者のLINEアカウントへの紐付けが失敗しました。原因は：${error}`
                    );
                });
        },

        /**
         * 生活習慣の改善の2つ目の行動計画を追加
         */
        addSecondActivityPlan(key) {
            this.useSecondActivityPlanFlags[key] = true;
        },

        /**
         *生活習慣の改善の2つ目の行動計画を削除
         */
        removeSecondActivityPlan(key) {
            this.dataForm[key + "2"] = null;
            this.useSecondActivityPlanFlags[key] = false;
        },

        /**
         * ワーニングクリア.
         */
        clearValidationWarns() {
            for (const prop in this.validateWarn) {
                this.validateWarn[prop] = null;
            }
        },
        /**
         * エラークリア.
         */
        clearValidationErrors() {
            for (const prop in this.validateError) {
                this.validateError[prop] = null;
            }
        },
        /**
         * バリデーションエラーが存在する場合true.
         */
        hasValidationError() {
            for (const prop in this.validateError) {
                if (this.validateError[prop] != null) {
                    return true;
                }
            }
            return false;
        },

        /**
         * バリデーション.
         */
        validate() {
            this.clearValidationErrors();
            this.clearValidationWarns();

            // バリデーション(個別)
            for (const [key, value] of Object.entries(this.dataForm)) {
                this.validateCheckRequire(this.dataForm, key);
            }

            // 実施時間
            if (this.dataForm.start_time && this.dataForm.end_time) {
                this.scheduled_date_to();
            } else {
                this.validateError.end_time = "実施時間を入力してください。";
            }

            // 連絡のいずれかは必須
            if (!this.validateError.patient_tel_04) {
                if (
                    !(
                        this.dataForm.patient_tel_01 ||
                        this.dataForm.patient_tel_02 ||
                        this.dataForm.patient_tel_03 ||
                        this.dataForm.patient_tel_04
                    )
                ) {
                    this.validateError.patient_tel_04 =
                        "連絡先の少なくともいずれか１つは必須です。";
                } else {
                    this.validateError.patient_tel_04 = null;
                }
            }

            // メールアドレス を空欄にする場合はメールアドレス情報をクリア.
            if (this.dataForm.mail_address_leave_blank) {
                this.validateError.mail = null;
                this.dataForm.patient_mail_01 = null;
            }

            // 受信勧奨チェック必須
            this.validateError.blood_pressure_result_validate = null;
            if (this.doneMedicalExamRecommend(this.dataForm.code_result)) {
                if (
                    !this.dataForm.blood_pressure_result &&
                    !this.dataForm.blood_result &&
                    !this.dataForm.lipid_result &&
                    !this.dataForm.other_result
                ) {
                    this.validateError.blood_pressure_result_validate =
                        "受信勧奨チェックボックスを選択してください。";
                }
            }

            // 受診状況、服薬状況等 必須
            // 受信勧奨済み、または服薬にチェックがある場合.
            this.validateError.examination_status = null;
            if (
                this.examinationStatusRequired() &&
                (!this.dataForm.examination_status ||
                    !this.dataForm.examination_status.length)
            ) {
                this.validateError.examination_status =
                    "受診状況、服薬状況等を入力してください。";
            }

            // 行動計画のいずれかは必須
            if (this.splitActivityPlan) {
                if (
                    !(
                        this.dataForm.activity_plan_eating ||
                        this.dataForm.activity_plan_exercise ||
                        this.dataForm.activity_plan_smoking ||
                        this.dataForm.activity_plan_rest ||
                        this.dataForm.activity_plan_else
                    )
                ) {
                    this.validateError.activity_plan_else =
                        "行動計画の少なくともいずれか１つは必須です。";
                } else {
                    this.validateError.activity_plan_else = null;
                }
            }

            const isValid = !this.hasValidationError();

            if (!isValid) {
                this.notifyInputInvalidToast();
            }

            // バリデーションエラーが存在しなければtrue(バリデーションOK).
            return isValid;
        },

        /**
         * 受診状況、服薬状況等 必須の場合true.
         */
        examinationStatusRequired() {
            return (
                this.doneMedicalExamRecommend(this.dataForm.code_result) ||
                this.dataForm.blood_pressure_status ||
                this.dataForm.blood_status ||
                this.dataForm.lipid_status ||
                this.dataForm.other_status
            );
        },
        /**
         * 継続支援伝達事項 備考 必須の場合true.
         */
        exampleContinueSupportRequired() {
            return (
                this.dataForm.denied_support ||
                this.dataForm.code_denied_support != null
            );
        },
        /**
         * 実施時間算出 及びバリデーション
         */
        scheduled_date_to() {
            this.validateError.end_time = null;

            let dateDefault = moment().format("YYYY-MM-DD");
            let dateFrom = moment(dateDefault + " " + this.dataForm.start_time);
            let dateTo = moment(dateDefault + " " + this.dataForm.end_time);
            let estimate_time = dateTo.diff(dateFrom, "minutes");

            if (estimate_time > 0) {
                // 実施時間が1以上の場合.
                this.dataForm.estimate_time = estimate_time;
                // 最低介入量取得.
                const min_support_amount = this.optionFirstTimeSupport.filter(
                    x => x.value == this.dataForm.health_support_form_id
                );
                if (min_support_amount.length > 0) {
                    var get_min_support_amount =
                        min_support_amount[0].min_support_amount;
                }
                // 最低介入量以上が入力されているかチェック.
                this.validateError.end_time = null;
                if (get_min_support_amount > this.support_amount) {
                    this.validateError.end_time = `実施時間は${get_min_support_amount}以上を入力してください。`;
                }
            } else {
                this.validateError.end_time =
                    "実施時間Toは実施時間From以上を入力してください。";
                this.dataForm.estimate_time = 0;
            }
        },
        /**
         * バリデーション(個別).
         *
         * @param {*} data
         * @param {*} column
         */
        validateCheckRequire(data, column) {
            switch (column) {
                case "start_date": // 実施日
                    this.validateError.start_date = null;
                    if (!data[column]) {
                        this.validateError.start_date =
                            "実施日を入力してください。";
                    }
                    break;
                case "system_users_id": // 指導員
                    this.validateError.system_users_id = null;
                    if (!data[column]) {
                        this.validateError.system_users_id =
                            "指導員を選択してください。";
                    }
                    break;
                case "system_users_leader_id": // 実施者
                    this.validateError.system_users_leader_id = null;
                    if (!data[column]) {
                        this.validateError.system_users_leader_id =
                            "実施者を選択してください。";
                    }
                    break;
                case "health_support_form_id": // 支援形態
                    this.validateError.health_support_form_id = null;
                    if (!data[column]) {
                        this.validateError.health_support_form_id =
                            "支援形態を選択してください。";
                    }
                    break;
                case "patient_tel_01": // 電話連絡先(携帯)
                    this.validateError.patient_tel_01 = null;
                    if (data[column]) {
                        if (!String(data[column]).match(/^[0-9]+$/)) {
                            this.validateError.patient_tel_01 =
                                "電話連絡先(携帯)は半角数字で入力してください。";
                        } else if (
                            data[column] &&
                            data[column].length > this.TEL_MAX_LEN
                        ) {
                            this.validateError.patient_tel_01 = `電話連絡先(携帯)は${this.TEL_MAX_LEN}文字以下で入力してください。`;
                        }
                    }
                    break;
                case "patient_tel_02": // 電話連絡先(自宅)
                    this.validateError.patient_tel_02 = null;
                    if (data[column]) {
                        if (!String(data[column]).match(/^[0-9]+$/)) {
                            this.validateError.patient_tel_02 =
                                "電話連絡先(自宅)は半角数字で入力してください。";
                        } else if (
                            data[column] &&
                            data[column].length > this.TEL_MAX_LEN
                        ) {
                            this.validateError.patient_tel_02 = `電話連絡先(自宅)は${this.TEL_MAX_LEN}文字以下で入力してください。`;
                        }
                    }
                    break;
                case "patient_tel_03": // 電話連絡先(職場)
                    this.validateError.patient_tel_03 = null;
                    if (data[column]) {
                        if (!String(data[column]).match(/^[0-9]+$/)) {
                            this.validateError.patient_tel_03 =
                                "電話連絡先(職場)は半角数字で入力してください。";
                        } else if (
                            data[column] &&
                            data[column].length > this.TEL_MAX_LEN
                        ) {
                            this.validateError.patient_tel_03 = `電話連絡先(職場)は${this.TEL_MAX_LEN}文字以下で入力してください。`;
                        }
                    }
                    break;
                case "patient_tel_04": // 電話連絡先(その他)
                    this.validateError.patient_tel_04 = null;
                    if (data[column]) {
                        if (!String(data[column]).match(/^[0-9]+$/)) {
                            this.validateError.patient_tel_04 =
                                "電話連絡先(その他)は半角数字で入力してください。";
                        } else if (
                            data[column] &&
                            data[column].length > this.TEL_MAX_LEN
                        ) {
                            this.validateError.patient_tel_04 = `電話連絡先(その他)は${this.TEL_MAX_LEN}文字以下で入力してください。`;
                        }
                    }
                    break;
                case "patient_mail_01": // メールアドレス
                    this.validateError.mail = null;
                    //if (!String(data[column]).match(/(?:[A-Za-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) {
                    if (
                        !String(data[column]).match(
                            /^[A-Za-z0-9!-/:-@[-´{-~]*$/
                        )
                    ) {
                        this.validateError.mail =
                            "メールアドレスは半角英数記号(スペース不可)で入力してください。";
                        if (
                            data[column] &&
                            data[column].length > this.MAIL_MAX_LEN
                        ) {
                            this.validateError.mail = `メールアドレスは${this.MAIL_MAX_LEN}文字以下で入力してください。`;
                        }
                    }
                    break;
                case "code_change_activity": // 行動変容ステージモデル
                    this.validateError.code_change_activity = null;
                    if (!data[column]) {
                        this.validateError.code_change_activity =
                            "行動変容ステージモデルを選択してください。";
                    }
                    break;
                case "start_width": // 初回時の体重
                    this.validateError.start_width = null;
                    if (!data[column]) {
                        this.validateError.start_width =
                            "初回時 体重を入力してください。";
                    } else if (data[column] > 999.9 || data[column] < 0.1) {
                        this.validateError.start_width =
                            "初回時 体重は[0.1〜999.9]の範囲で入力してください。";
                    }
                    break;
                case "start_waist": // 初回時の腹囲
                    this.validateError.start_waist = null;
                    if (!data[column]) {
                        this.validateError.start_waist =
                            "初回時 腹囲を入力してください。";
                    } else if (data[column] > 999.9 || data[column] < 0.1) {
                        this.validateError.start_waist =
                            "初回時 腹囲は[0.1〜999.9]の範囲で入力してください。";
                    }
                    break;
                case "target": // 行動目標
                    if (!this.is4th) {
                        this.validateError.target = null;
                        if (data[column] === "") {
                            this.validateError.target =
                                "行動目標を入力してください。";
                        } else if (data[column] > 999.9 || data[column] < 0.0) {
                            this.validateError.target =
                                "行動目標は[0.0〜999.9]の範囲で入力してください。";
                        }
                    }
                    break;
                case "target_width": // 目標体重
                    this.validateError.target_width = null;
                    if (
                        data[column] === null ||
                        data[column] === "" ||
                        data[column] === undefined
                    ) {
                        this.validateError.target_width =
                            "目標体重を入力してください。";
                    } else if (data[column] > 999.9 || data[column] < 0.1) {
                        this.validateError.target_width =
                            "目標体重は[0.1〜999.9]の範囲で入力してください。";
                    }
                    break;
                case "target_waist": // 目標腹囲
                    this.validateError.target_waist = null;
                    if (
                        data[column] === null ||
                        data[column] === "" ||
                        data[column] === undefined
                    ) {
                        this.validateError.target_waist =
                            "目標腹囲を入力してください。";
                    } else if (data[column] > 999.9 || data[column] < 0.1) {
                        this.validateError.target_waist =
                            "目標腹囲は[0.1〜999.9]の範囲で入力してください。";
                    }
                    break;
                case "smocking": //喫煙関連
                    this.validateError.smocking = null;
                    if (!data[column]) {
                        this.validateError.smocking =
                            "喫煙関連を選択してください。";
                    }
                    break;
                case "alcohol": // 飲酒関連
                    this.validateError.alcohol = null;
                    if (!data[column]) {
                        this.validateError.alcohol =
                            "飲酒関連を選択してください。";
                    }
                    break;
                case "code_result": // 受診勧奨とその結果
                    this.validateError.code_result = null;
                    if (!data[column]) {
                        this.validateError.code_result =
                            "受診勧奨とその結果を選択してください。";
                    }
                    break;
                case "other_text_result": // 受診勧奨とその結果 その他テキスト
                    this.validateError.other_text_result = null;
                    //　受診勧奨とその結果 その他チェックがONの場合
                    if (!data[column] && this.dataForm.other_result) {
                        this.validateError.other_text_result =
                            "受信勧奨 その他 を入力してください。";
                    } else if (
                        data[column] &&
                        data[column].length > this.OTHER_TEXT_RESULT
                    ) {
                        this.validateError.other_text_result = `受信勧奨 その他 テキストは${this.OTHER_TEXT_RESULT}文字以下で入力してください。`;
                    }
                    break;
                case "other_text_status": // 服薬状況 その他テキスト
                    this.validateError.other_text_status = null;
                    //　服薬状況 その他チェックがONの場合
                    if (!data[column] && this.dataForm.other_status) {
                        this.validateError.other_text_status =
                            "服薬状況 その他 を入力してください。";
                    } else if (
                        data[column] &&
                        data[column].length > this.OTHER_TEXT_STATUS
                    ) {
                        this.validateError.other_text_status = `服薬状況 その他 テキストは${this.OTHER_TEXT_STATUS}文字以下で入力してください。`;
                    }
                    break;
                case "examination_status": // 受診状況、服薬状況等
                    this.validateError.examination_status = null;
                    if (
                        this.examinationStatusRequired() &&
                        (!this.dataForm.examination_status ||
                            !this.dataForm.examination_status.length)
                    ) {
                        this.validateError.examination_status =
                            "受診状況、服薬状況等を入力してください。";
                    } else if (
                        data[column] &&
                        data[column].length > this.EXAMINATION_STATUS_MAX_LEN
                    ) {
                        this.validateError.examination_status = `受診状況、服薬状況等は${this.EXAMINATION_STATUS_MAX_LEN}文字以下で入力してください。`;
                    }
                    break;
                case "activity_kcal_reduce": // 運動で減らすkcal/日
                    this.validateError.activity_kcal_reduce = null;
                    if (data[column]) {
                        if (!String(data[column]).match(/^[0-9]+$/)) {
                            this.validateError.activity_kcal_reduce =
                                "運動で減らすkcal/日は半角数字で入力してください。";
                        } else if (data[column] > 9999 || data[column] < 0) {
                            this.validateError.activity_kcal_reduce =
                                "運動で減らす[0〜9999]の範囲で入力してください。";
                        }
                    }
                    if (
                        data[column] === "" ||
                        (!data[column] && data[column] !== 0)
                    ) {
                        this.validateError.activity_kcal_reduce =
                            "運動で減らすkcal/日を入力してください。";
                    }
                    break;
                case "eat_kcal_reduce": // 食事で減らすkcal/日
                    this.validateError.eat_kcal_reduce = null;
                    if (data[column]) {
                        if (!String(data[column]).match(/^[0-9]+$/)) {
                            this.validateError.eat_kcal_reduce =
                                "食事で減らすkcal/日は半角数字で入力してください。";
                        } else if (data[column] > 9999 || data[column] < 0) {
                            this.validateError.eat_kcal_reduce =
                                "食事で減らすkcal/日は[0〜9999]の範囲で入力してください。";
                        }
                    }
                    if (
                        data[column] === "" ||
                        (!data[column] && data[column] !== 0)
                    ) {
                        this.validateError.eat_kcal_reduce =
                            "食事で減らすkcal/日を入力してください。";
                    }
                    break;
                case "activity_plan_1": // 行動計画１
                    this.validateError.activity_plan_1 = null;
                    if (!this.splitActivityPlan) {
                        if (!data[column]) {
                            this.validateError.activity_plan_1 =
                                "行動計画を入力してください。";
                        } else if (
                            data[column] &&
                            data[column].length > this.ACTIVITY_PLAN_MAX_LEN
                        ) {
                            this.validateError.activity_plan_1 = `行動計画は${this.ACTIVITY_PLAN_MAX_LEN}文字以下で入力してください。`;
                        }
                    }
                    break;
                case "activity_and_eat": // 食生活・運動関連
                    this.validateError.activity_and_eat = null;
                    if (!data[column]) {
                        this.validateError.activity_and_eat =
                            "食生活・運動関連を入力してください。";
                    } else if (
                        data[column] &&
                        data[column].length > this.ACTIVITY_AND_EAT_MAX_LEN
                    ) {
                        this.validateError.activity_and_eat = `食生活・運動関連は${this.ACTIVITY_AND_EAT_MAX_LEN}文字以下で入力してください。`;
                    }
                    break;
                case "free": // 自由欄
                    if (
                        data[column] &&
                        data[column].length > this.FREE_MAX_LEN
                    ) {
                        this.dataForm.free = data[column].slice(0, 500);
                    }
                    break;
                case "contact_way_priority_01": // 連絡手段 第１希望
                    this.validateError.contact_way_priority_01 = null;
                    if (!data[column]) {
                        this.validateError.contact_way_priority_01 =
                            "連絡手段 第１希望を選択してください。";
                    }
                    break;
                case "example_continue_support": // 継続支援伝達事項 備考（補足・希望時間など）
                    this.validateError.example_continue_support = null;
                    if (
                        !data[column] &&
                        this.exampleContinueSupportRequired()
                    ) {
                        this.validateError.example_continue_support =
                            "継続支援伝達事項 備考を入力してください。";
                    } else if (
                        data[column] &&
                        data[column].length >
                            this.EXAMPLE_CONTINUE_SUPPORT_MAX_LEN
                    ) {
                        this.validateError.example_continue_support = `継続支援伝達事項 備考は${this.EXAMPLE_CONTINUE_SUPPORT_MAX_LEN}文字以下で入力してください。`;
                    }
                    break;

                // switch
            }
        },

        handleBeforeDialog(dialog_name) {
            if (dialog_name) {
                this[dialog_name] = true;
            }
        },

        /**
         * フォームデータ取得.
         */
        async getList() {
            this.setIsLoading(true);
            await L1.list(this.segment.id)
                .then(res => {
                    const { data, status } = res;
                    this.setIsLoading(false);

                    if (res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(
                            `[${res?.error.messageID}] ${res?.error.message}`,
                            TOAST_TIME_OUT
                        );
                        return;
                    }

                    if (status !== SUCCESS) {
                        this.$toast.open({
                            message: "入力内容に誤りがあります",
                            type: "error"
                        });
                    }
                    if (status !== SUCCESS) return;

                    this.isFormDataLoading = true;

                    let segment = data.data.segment_item;
                    this.data_patient = data.data.patient;
                    let patient = data.data.patient;
                    this.healthExamDate = patient.healthexam_date;

                    L1.weightOfDiagnosticResults(patient.id).then(res => {
                        if (res.errorCode === INTERNAL_SERVER_ERROR) {
                            this.$toast.error(
                                `[${res?.error.messageID}] ${res?.error.message}`,
                                TOAST_TIME_OUT
                            );
                            return;
                        }
                        this.examWidth = res.data.data.width;
                        this.examWaist = res.data.data.waist;
                        // this.healthExamDate = res.data.data.date;
                    });

                    this.dataForm.patient_tel_01 = patient.patient_tel_01;
                    this.dataForm.patient_tel_02 = patient.patient_tel_02;
                    this.dataForm.patient_tel_03 = patient.patient_tel_03;
                    this.dataForm.patient_tel_04 = patient.patient_tel_04;
                    this.dataForm.mail_address_leave_blank = false;
                    this.dataForm.patient_mail_01 = patient.patient_mail_01;
                    if (
                        this.segment?.support_progress_status ==
                            SUPPORT_PROGRESS_PLAN ||
                        this.segment?.support_progress_status ==
                            SUPPORT_PROGRESS_SCHEDULE
                    ) {
                        this.dataForm.health_support_form_id =
                            this.segment?.health_support_form_id;
                    }

                    if (segment) {
                        this.dataForm.system_users_leader_id =
                            segment.system_users_leader_id;
                        this.dataForm.start_date = segment.start_date;
                        this.handleChangeStartDate(segment.start_date);
                        this.dataForm.start_time = segment.start_time;
                        this.dataForm.end_time = segment.end_time;
                        this.dataForm.estimate_time = segment.estimate_time;
                        this.dataForm.system_users_id =
                            segment.system_users_id ?? null;
                        this.dataForm.system_users_name =
                            segment.system_users_name;
                        // this.dataForm.health_support_form_id = segment.health_support_form_id;
                        this.dataForm.health_support_form_id =
                            this.checkHealthSupportFormsBySegmentVal;
                        this.dataForm.mail_address_leave_blank =
                            segment.mail_address_leave_blank ? true : false;
                        this.dataForm.code_change_activity =
                            segment.code_change_activity;
                        this.dataForm.start_width =
                            parseFloat(segment.start_width) == 0
                                ? "0.0"
                                : parseFloat(segment.start_width);
                        this.dataForm.start_waist =
                            parseFloat(segment.start_waist) == 0
                                ? "0.0"
                                : parseFloat(segment.start_waist);
                        this.dataForm.target =
                            parseFloat(segment.target) == 0
                                ? "0.0"
                                : parseFloat(segment.target);
                        this.dataForm.target_width =
                            parseFloat(segment.target_width) == 0
                                ? "0.0"
                                : parseFloat(segment.target_width);
                        this.dataForm.target_waist =
                            parseFloat(segment.target_waist) == 0
                                ? "0.0"
                                : parseFloat(segment.target_waist);
                        this.dataForm.smocking = segment.smocking;
                        this.dataForm.alcohol = segment.alcohol;
                        this.dataForm.code_result = segment.code_result;
                        this.dataForm.blood_pressure_result =
                            segment.blood_pressure_result;
                        this.dataForm.blood_result = segment.blood_result;
                        this.dataForm.lipid_result = segment.lipid_result;
                        this.dataForm.other_result = segment.other_result;
                        this.dataForm.other_text_result =
                            segment.other_text_result;
                        this.dataForm.no_medication = segment.no_medication;
                        this.dataForm.blood_pressure_status =
                            segment.blood_pressure_status;
                        this.dataForm.blood_status = segment.blood_status;
                        this.dataForm.lipid_status = segment.lipid_status;
                        this.dataForm.other_status = segment.other_status;
                        this.dataForm.other_text_status =
                            segment.other_text_status;
                        this.dataForm.examination_status =
                            segment.examination_status;
                        this.dataForm.activity_kcal_reduce =
                            segment.activity_kcal_reduce;
                        this.dataForm.eat_kcal_reduce = segment.eat_kcal_reduce;
                        this.dataForm.kcal_reduce = segment.kcal_reduce;
                        this.dataForm.activity_plan_1 = segment.activity_plan_1;

                        for (const activityPlanType of this.activityPlanTypes) {
                            const key = activityPlanType.key;
                            const value = segment[activityPlanType.key];
                            if (
                                value &&
                                value.indexOf(ACTIVITY_PLAN_SEPARATOR) > 0
                            ) {
                                const [value1, value2] = value.split(
                                    ACTIVITY_PLAN_SEPARATOR
                                );
                                this.dataForm[key] = value1;
                                this.dataForm[key + "2"] = value2;
                                this.useSecondActivityPlanFlags[key] = true;
                            } else {
                                this.dataForm[key] = value;
                            }
                        }
                        // this.dataForm.activity_plan_exercise =
                        //     segment.activity_plan_exercise;
                        // this.dataForm.activity_plan_smoking =
                        //     segment.activity_plan_smoking;
                        // this.dataForm.activity_plan_rest =
                        //     segment.activity_plan_rest;
                        // this.dataForm.activity_plan_else =
                        //     segment.activity_plan_else;
                        this.dataForm.activity_and_eat =
                            segment.activity_and_eat;
                        this.dataForm.free = segment.free;
                        this.dataForm.denied_support = segment.denied_support;
                        this.dataForm.code_denied_support =
                            segment.code_denied_support;
                        this.dataForm.example_continue_support =
                            segment.example_continue_support;
                        this.dataForm.contact_way_priority_01 =
                            segment.contact_way_priority_01;
                        this.dataForm.contact_way_priority_02 =
                            segment.contact_way_priority_02;
                        this.dataForm.contact_way_priority_03 =
                            segment.contact_way_priority_03;
                    }

                    if (!this.dataForm.system_users_id) {
                        this.flagLoadFirst = false;
                        this.dataForm.system_users_id = this.user.id;
                    }
                })
                .catch(err => {
                    this.setIsLoading(false);
                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                })
                .finally(v => {
                    this.isFormDataLoading = false;
                });
        },

        /**
         * フォームクリア.
         */
        async clearSegment() {
            this.dataForm.start_date = new Date();
            this.handleChangeStartDate(this.dataForm.start_date);
            this.dataForm.start_time = null;
            this.dataForm.end_time = null;
            this.dataForm.estimate_time = 0;
            this.dataForm.system_users_leader_id = null;
            this.dataForm.system_users_id = null;
            this.dataForm.system_users_name = null;
            this.dataForm.health_support_form_id = null;
            this.dataForm.patient_tel_01 = null;
            this.dataForm.patient_tel_02 = null;
            this.dataForm.patient_tel_03 = null;
            this.dataForm.patient_tel_04 = null;
            this.dataForm.health_support_form_id_blank = false;
            this.dataForm.mail_address_leave_blank = false;
            this.dataForm.patient_mail_01 = null;
            this.dataForm.code_change_activity = null;
            this.dataForm.start_width = "0.0";
            this.dataForm.start_waist = "0.0";
            this.dataForm.target = "0.0";
            this.dataForm.target_width = "0.0";
            this.dataForm.target_waist = "0.0";
            this.dataForm.smocking = null;
            this.dataForm.alcohol = null;
            this.dataForm.code_result = null;
            this.dataForm.blood_pressure_result = false;
            this.dataForm.blood_result = false;
            this.dataForm.lipid_result = false;
            this.dataForm.other_result = false;
            this.dataForm.other_text_result = null;
            this.dataForm.no_medication = false;
            this.dataForm.blood_pressure_status = false;
            this.dataForm.blood_status = false;
            this.dataForm.lipid_status = false;
            this.dataForm.other_status = false;
            this.dataForm.other_text_status = null;
            this.dataForm.examination_status = null;
            this.dataForm.activity_kcal_reduce = 0;
            this.dataForm.eat_kcal_reduce = 0;
            this.dataForm.kcal_reduce = null;
            this.dataForm.activity_plan_1 = null;
            this.dataForm.activity_plan_eating = null;
            this.dataForm.activity_plan_exercise = null;
            this.dataForm.activity_plan_smoking = null;
            this.dataForm.activity_plan_rest = null;
            this.dataForm.activity_plan_else = null;
            this.dataForm.activity_plan_eating2 = null;
            this.dataForm.activity_plan_exercise2 = null;
            this.dataForm.activity_plan_smoking2 = null;
            this.dataForm.activity_plan_rest2 = null;
            this.dataForm.activity_plan_else2 = null;
            this.dataForm.activity_and_eat = null;
            this.dataForm.free = null;
            this.dataForm.denied_support = false;
            this.dataForm.code_denied_support = null;
            this.dataForm.example_continue_support = null;
            this.support_amount = 0;
            this.dataForm.contact_way_priority_01 = null;
            this.dataForm.contact_way_priority_02 = null;
            this.dataForm.contact_way_priority_03 = null;
            this.useSecondActivityPlanFlags.activity_plan_eating = false;
            this.useSecondActivityPlanFlags.activity_plan_exercise = false;
            this.useSecondActivityPlanFlags.activity_plan_smoking = false;
            this.useSecondActivityPlanFlags.activity_plan_rest = false;
            this.useSecondActivityPlanFlags.activity_plan_else = false;
            this.getList();
        },

        /**
         * 一時保存.
         */
        async saveDraft() {
            // ダイアログを閉じる.
            this.isDialogSaveDraft = false;

            // 保存用にフォームデータ変換.
            this.convertFormDataBeforeSave();

            // ローディング状態設定.
            this.setIsLoading(true);
            // バックエンド呼出し
            await L1.segmentContinue(
                this.segment.id,
                BTN_SAVE_DRAFT,
                this.makeRequestData()
            )
                .then(res => {
                    // ローディング状態解除.
                    this.setIsLoading(false);

                    const { data, status } = res;

                    if (res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(
                            `[${res?.error.messageID}] ${res?.error.message}`,
                            TOAST_TIME_OUT
                        );
                        return;
                    }

                    if (status !== SUCCESS) {
                        this.$toast.open({
                            message: "入力内容に誤りがあります",
                            type: "error"
                        });
                    }
                    if (status !== SUCCESS) return;
                    this.$toast.open({
                        message: "一時保存の登録が完了しました。",
                        type: "success"
                    });
                    this.$root.$emit("Dashboard.LoadPages", {
                        id: this.segment.id
                    });
                })
                .catch(err => {
                    // ローディング状態解除.
                    this.setIsLoading(false);

                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                });
        },

        /**
         * 確定解除.
         */
        async deleteSegment() {
            // ダイアログを閉じる.
            this.isDialogDelete = false;
            // ローディング状態設定.
            this.setIsLoading(true);
            // バックエンド呼出.
            await L1.segmentContinue(
                this.segment.id,
                BTN_DELETE,
                this.makeRequestData()
            )
                .then(res => {
                    const { data, status, error } = res;
                    // ローディング状態解除.
                    this.setIsLoading(false);

                    if (res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(
                            `[${res?.error.messageID}] ${res?.error.message}`,
                            TOAST_TIME_OUT
                        );
                        return;
                    }

                    if (status !== SUCCESS) {
                        return this.$toast.open({
                            message: error.message,
                            type: "warning"
                        });
                    }
                    this.$toast.open({
                        message: "確定解除処理が完了しました。",
                        type: "success"
                    });
                    this.$root.$emit("Dashboard.LoadPages", {
                        id: this.segment.id
                    });
                })
                .catch(err => {
                    // ローディング状態解除.
                    this.setIsLoading(false);

                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                    this.$toast.open({
                        message: "error",
                        type: "error"
                    });
                });
        },

        /**
         * 修正.
         */
        async editSegment() {
            // ダイアログを閉じる.
            this.isDialogEdit = false;

            // バリデーション
            if (this.validate()) {
                // 保存用にフォームデータ変換.
                this.convertFormDataBeforeSave();
                // ローディング状態設定.
                this.setIsLoading(true);
                // バックエンド呼出.
                await L1.segmentContinue(
                    this.segment.id,
                    BTN_EDIT,
                    this.makeRequestData()
                )
                    .then(res => {
                        const { data, status, error } = res;
                        // ローディング状態解除.
                        this.setIsLoading(false);

                        if (res.errorCode === INTERNAL_SERVER_ERROR) {
                            this.$toast.error(
                                `[${res?.error.messageID}] ${res?.error.message}`,
                                TOAST_TIME_OUT
                            );
                            return;
                        }

                        if (status !== SUCCESS) {
                            this.validateError = res.error.error;
                            return this.$toast.open({
                                message: error.message,
                                type: "warning"
                            });
                        } else {
                            this.$toast.open({
                                message: "修正処理が完了しました。",
                                type: "success"
                            });
                            this.$root.$emit("Dashboard.LoadPages", {
                                id: this.segment.id
                            });
                        }
                    })
                    .catch(err => {
                        // ローディング状態解除.
                        this.setIsLoading(false);

                        err.inner.forEach(error => {
                            this.errors[error.path] = error.message;
                        });
                        this.$toast.open({
                            message: "error",
                            type: "error"
                        });
                    });
            }
        },

        /**
         * リクエストデータ作成.
         */
        makeRequestData() {
            const data = { ...this.dataForm };
            for (const activityPlanType of this.activityPlanTypes) {
                const value1 = this.dataForm[activityPlanType.key];
                const value2 = this.dataForm[activityPlanType.key + "2"];
                delete data[activityPlanType.key + "2"];
                const useSecondFlag =
                    this.useSecondActivityPlanFlags[activityPlanType.key];
                if (useSecondFlag && value2) {
                    data[activityPlanType.key] = [value1, value2].join(
                        ACTIVITY_PLAN_SEPARATOR
                    );
                }
            }
            return data;
        },

        /**
         * 確定.
         */
        async confirmDialog() {
            // ダイアログ閉じる
            this.isDialogConfirm = false;

            // バリデーション
            if (this.validate()) {
                // 保存用にフォームデータ変換.
                this.convertFormDataBeforeSave();
                // ローディング状態設定.
                this.setIsLoading(true);
                // バックエンド呼出
                await L1.segmentContinue(
                    this.segment.id,
                    BTN_CONFIRM,
                    this.makeRequestData()
                )
                    .then(res => {
                        const { data, status } = res;
                        // ローディング状態解除.
                        this.setIsLoading(false);

                        if (res.errorCode === INTERNAL_SERVER_ERROR) {
                            this.$toast.error(
                                `[${res?.error.messageID}] ${res?.error.message}`,
                                TOAST_TIME_OUT
                            );
                            return;
                        }

                        if (status !== SUCCESS) {
                            this.$toast.open({
                                message: "入力内容に誤りがあります",
                                type: "error"
                            });
                        }
                        if (status !== SUCCESS) return;
                        this.$toast.open({
                            message: "確定処理が完了しました。",
                            type: "success"
                        });
                        this.$root.$emit("Dashboard.LoadPages", {
                            id: this.segment.id
                        });
                    })
                    .catch(err => {
                        // ローディング状態解除.
                        this.setIsLoading(false);
                        err.inner.forEach(error => {
                            this.errors[error.path] = error.message;
                        });
                    });
            }
        },

        /**
         * 保存前 フォームデータ変換.
         */
        convertFormDataBeforeSave() {
            const dateDefault = moment().format("YYYY-MM-DD");
            const dateFrom = moment(
                dateDefault + " " + this.dataForm.start_time
            );
            const dateTo = moment(dateDefault + " " + this.dataForm.end_time);
            this.dataForm.estimate_time = dateTo.diff(dateFrom, "minutes");

            this.dataForm.kcal_reduce =
                parseInt(
                    this.dataForm.activity_kcal_reduce
                        ? this.dataForm.activity_kcal_reduce
                        : 0
                ) +
                parseInt(
                    this.dataForm.eat_kcal_reduce
                        ? this.dataForm.eat_kcal_reduce
                        : 0
                );

            this.dataForm.start_date = moment(this.dataForm.start_date).format(
                "YYYY-MM-DD"
            );
            this.handleChangeStartDate(this.dataForm.start_date);
            this.dataForm.activity_kcal_reduce = this.dataForm
                .activity_kcal_reduce
                ? parseInt(this.dataForm.activity_kcal_reduce)
                : 0;
            this.dataForm.blood_pressure_result = this.dataForm
                .blood_pressure_result
                ? 1
                : 0;
            this.dataForm.no_medication = this.dataForm.no_medication ? 1 : 0;
            this.dataForm.blood_pressure_status = this.dataForm
                .blood_pressure_status
                ? 1
                : 0;
            this.dataForm.denied_support = this.dataForm.denied_support ? 1 : 0;
            this.dataForm.eat_kcal_reduce = this.dataForm.eat_kcal_reduce
                ? parseInt(this.dataForm.eat_kcal_reduce)
                : 0;
            this.dataForm.lipid_result = this.dataForm.lipid_result ? 1 : 0;
            this.dataForm.lipid_status = this.dataForm.lipid_status ? 1 : 0;

            this.dataForm.other_result = this.dataForm.other_result ? 1 : 0;
            this.dataForm.other_text_result = this.dataForm.other_result
                ? this.dataForm.other_text_result
                : null;

            this.dataForm.other_status = this.dataForm.other_status ? 1 : 0;
            this.dataForm.other_text_status = this.dataForm.other_status
                ? this.dataForm.other_text_status
                : null;

            this.dataForm.start_waist = this.dataForm.start_waist
                ? this.dataForm.start_waist
                : 0.0;
            this.dataForm.start_width = this.dataForm.start_width
                ? this.dataForm.start_width
                : 0.0;
            this.dataForm.target = this.dataForm.target
                ? this.dataForm.target
                : 0.0;
            this.dataForm.target_waist = this.dataForm.target_waist
                ? this.dataForm.target_waist
                : 0.0;
            this.dataForm.target_width = this.dataForm.target_width
                ? this.dataForm.target_width
                : 0.0;
            this.dataForm.blood_result = this.dataForm.blood_result ? 1 : 0;
            this.dataForm.blood_status = this.dataForm.blood_status ? 1 : 0;

            this.dataForm.mail_address_leave_blank = this.dataForm
                .mail_address_leave_blank
                ? 1
                : 0;
            this.dataForm.patient_mail_01 = this.dataForm
                .mail_address_leave_blank
                ? null
                : this.dataForm.patient_mail_01;
            this.dataForm.system_users_name = this.dataForm.system_users_id
                ? this.dataForm.system_users_name
                : null;
        },

        onHandleCloseDialog(dialog_name) {
            if (dialog_name) {
                this[dialog_name] = false;
            }
        },

        // Input support area permission processing matrix
        // Current processing status: this.getSupportProgressStatus
        /**
         * フォーム下部のボタン表示制御.
         */
        async checkPermissionButton() {
            if (this.getSupportProgressStatus) {
                // 途中終了 or 初回面接辞退の場合.
                if (
                    this.support_flow_status == 40 ||
                    this.support_flow_status == 50
                ) {
                    // × 一時保存
                    // × 確定
                    // × 修正
                    // × 確定解除
                    // × 支援内容クリア
                    this.setPermissionButton(false, false, false, false, false);
                } else {
                    // 支援開始前 or 支援実施中 or 支援完了
                    if (this.isSupporter(true)) {
                        // 支援担当者の場合
                        if (
                            parseInt(this.getSupportProgressStatus) ===
                            SUPPORT_PROGRESS_CONFIRM
                        ) {
                            // 対象支援セグメントが確定されている場合.
                            // × 一時保存
                            // × 確定
                            // × 修正
                            // × 確定解除
                            // × 支援内容クリア
                            this.setPermissionButton(
                                false,
                                false,
                                false,
                                false,
                                false
                            );
                        } else {
                            // 対象支援セグメントが「計画」or「予定」or「実施中」されている場合.
                            // ◯ 一時保存
                            // ◯ 確定
                            // × 修正
                            // × 確定解除
                            // × 支援内容クリア
                            this.setPermissionButton(
                                true,
                                true,
                                false,
                                false,
                                false
                            );
                        }
                    } else {
                        // 「事務局」or「システム管理者」の場合
                        // role is system admin OR backoffice

                        if (
                            parseInt(this.getSupportProgressStatus) ===
                            SUPPORT_PROGRESS_CONFIRM
                        ) {
                            // 対象支援セグメントが確定されている場合.
                            // × 一時保存
                            // × 確定
                            // △(次セグメントが確定されていなければ◯) 修正
                            // ◯ 確定解除
                            // × 支援内容クリア
                            this.setPermissionButton(
                                false,
                                false,
                                false,
                                !(
                                    parseInt(this.nextSupportProgressStatus) ===
                                    SUPPORT_PROGRESS_CONFIRM
                                ),
                                true
                            );
                        } else if (
                            parseInt(this.getSupportProgressStatus) ===
                            SUPPORT_PROGRESS_IN_PROGRESS
                        ) {
                            // 支援実施中の場合.
                            // ◯ 一時保存
                            // ◯ 確定
                            // × 修正
                            // × 確定解除
                            // ◯ 支援内容クリア
                            this.setPermissionButton(
                                true,
                                true,
                                true,
                                false,
                                false
                            );
                        } else if (
                            parseInt(this.getSupportProgressStatus) ===
                                SUPPORT_PROGRESS_PLAN ||
                            parseInt(this.getSupportProgressStatus) ===
                                SUPPORT_PROGRESS_SCHEDULE
                        ) {
                            // 「計画」or「予定」の場合.
                            // ◯ 一時保存
                            // ◯ 確定
                            // × 修正
                            // × 確定解除
                            // × 支援内容クリア
                            this.setPermissionButton(
                                true,
                                true,
                                false,
                                false,
                                false
                            );
                        }
                    }
                }
            }
        },

        /**
         * フォーム下部のボタン表示制御
         *
         *
         * @param {*} save_draft_status
         * @param {*} confirm_status
         * @param {*} clear_status
         * @param {*} delete_status
         * @param {*} edit_status
         */
        setPermissionButton(
            save_draft_status,
            confirm_status,
            clear_status,
            delete_status,
            edit_status
        ) {
            this.btnSaveDraftStatus = save_draft_status;
            this.btnConfirmStatus = confirm_status;
            this.$emit("btnClearStatus", clear_status);
            this.btnDeleteStatus = delete_status;
            this.btnEditStatus = edit_status;
        },
        /**
         * 行動目標 変更時.
         */
        changeDataFormTarget() {
            if (this.dataForm.start_waist || this.dataForm.start_width) {
                var target = this.dataForm.target;
                // 行動目標フォーマット.
                if (
                    this.dataForm.target == "" ||
                    this.dataForm.target == null
                ) {
                    target = 0;
                } else {
                    if (
                        this.dataForm.target.toString().match(/\.\d{1}/) ==
                            null &&
                        this.dataForm.target.toString().match(/\d{3}/)
                    ) {
                        this.dataForm.target = this.dataForm.target
                            .toString()
                            .match(/\d{3}/)[0];
                        this.target_old = this.dataForm.target;
                    } else if (
                        this.dataForm.target.toString().match(/\.\d{1}/) &&
                        this.dataForm.target.toString().match(/\d{3}/)
                    ) {
                        this.dataForm.target =
                            this.dataForm.target.toString().match(/\d{3}/)[0] +
                            this.dataForm.target.toString().match(/\.\d{1}/)[0];
                        this.target_old = this.dataForm.target;
                    }
                }
                // 目標体重、腹囲算出.
                this.dataForm.target_waist =
                    this.dataForm.start_waist - target > 0
                        ? (this.dataForm.start_waist - target).toFixed(1)
                        : 0.0;
                this.dataForm.target_width =
                    this.dataForm.start_width - target > 0
                        ? (this.dataForm.start_width - target).toFixed(1)
                        : 0.0;
            }
        },

        /**
         * 初回時体重 変更時. 目標体重 算出
         */
        changeDataFormStartWidth() {
            // 初回体重が 変更された場合.
            if (
                this.dataForm.start_width &&
                this.start_width_old !== this.dataForm.start_width
            ) {
                // 初回時体重フォーマット.
                if (
                    this.dataForm.start_width.toString().match(/\.\d{1}/) ==
                        null &&
                    this.dataForm.start_width.toString().match(/\d{3}/)
                ) {
                    this.dataForm.start_width = this.dataForm.start_width
                        .toString()
                        .match(/\d{3}/)[0];
                    this.start_width_old = this.dataForm.start_width;
                } else if (
                    this.dataForm.start_width.toString().match(/\.\d{1}/) &&
                    this.dataForm.start_width.toString().match(/\d{3}/)
                ) {
                    this.dataForm.start_width =
                        this.dataForm.start_width.toString().match(/\d{3}/)[0] +
                        this.dataForm.start_width
                            .toString()
                            .match(/\.\d{1}/)[0];
                    this.start_width_old = this.dataForm.start_width;
                }
                // 行動目標
                // (4期仕様では「目標(何kg/cm減らす)」は未使用のため算出不要)
                if (!this.is4th) {
                    var target = this.dataForm.target;
                    if (
                        this.dataForm.target == "" ||
                        this.dataForm.target == null
                    ) {
                        target = 0;
                    }
                    // 目標体重
                    this.dataForm.target_width =
                        this.dataForm.start_width - target > 0
                            ? (this.dataForm.start_width - target).toFixed(1)
                            : 0.0;
                }

                // 警告出力.
                this.message_warning_target_width = null;
                if (
                    ENVS.WARN_INPUT_DIFF_WEIGHT <
                    Math.abs(this.dataForm.start_width - this.examWidth)
                ) {
                    this.message_warning_target_width =
                        "健診時の体重と比較して大きな差があります。";
                    this.notifyInputWarnToast("体重");
                }
            }
        },

        /**
         * 初回時腹囲 変更時. 目標腹囲算出
         */
        changeDataFormStartWaist() {
            //初回腹囲 が変更された場合.
            if (
                this.dataForm.start_waist &&
                this.start_waist_old !== this.dataForm.start_waist
            ) {
                // 初回腹囲のフォーマット
                if (
                    this.dataForm.start_waist.toString().match(/\.\d{1}/) ==
                        null &&
                    this.dataForm.start_waist.toString().match(/\d{3}/)
                ) {
                    this.dataForm.start_waist = this.dataForm.start_waist
                        .toString()
                        .match(/\d{3}/)[0];
                    this.start_waist_old = this.dataForm.start_waist;
                } else if (
                    this.dataForm.start_waist.toString().match(/\.\d{1}/) &&
                    this.dataForm.start_waist.toString().match(/\d{3}/)
                ) {
                    this.dataForm.start_waist =
                        this.dataForm.start_waist.toString().match(/\d{3}/)[0] +
                        this.dataForm.start_waist
                            .toString()
                            .match(/\.\d{1}/)[0];
                    this.start_waist_old = this.dataForm.start_waist;
                }
                // 行動目標
                // (4期仕様では「目標(何kg/cm減らす)」は未使用のため算出不要)
                if (!this.is4th) {
                    var target = this.dataForm.target;
                    if (
                        this.dataForm.target == "" ||
                        this.dataForm.target == null
                    ) {
                        target = 0;
                    }
                    // 目標腹囲 算出
                    this.dataForm.target_waist =
                        this.dataForm.start_waist - target > 0
                            ? (this.dataForm.start_waist - target).toFixed(1)
                            : 0.0;
                }

                // 警告出力.
                this.message_warning_target_waist = null;
                if (
                    ENVS.WARN_INPUT_DIFF_WAIST <
                    Math.abs(this.dataForm.start_waist - this.examWaist)
                ) {
                    this.message_warning_target_waist =
                        "健診時の腹囲と比較して大きな差があります。";
                    this.notifyInputWarnToast("腹囲");
                }
            }
        },

        /**
         * 初回支援日の変更時
         * - 早期介入のポイントを計算
         * @param {*} date
         */
        handleChangeStartDate(value) {
            if (!value) {
                this.$emit("changePoint", {
                    process: 0 - this.currentSegmentProcessPoint
                });
            }

            // 健診日
            const healthExamDate = new Date(this.healthExamDate);

            // 初回支援日
            const supportDate = new Date(value);

            // 日付の差を計算（ミリ秒）
            const timeDiff = Math.abs(supportDate - healthExamDate);

            // 当日
            if (timeDiff == 0) {
                this.$emit("changePoint", {
                    process: 20 - this.currentSegmentProcessPoint
                });
                return;
            }

            // 1週間
            // 1週間のミリ秒数（7日 * 24時間 * 60分 * 60秒 * 1000ミリ秒）
            if (timeDiff <= 7 * 24 * 60 * 60 * 1000) {
                this.$emit("changePoint", {
                    process: 10 - this.currentSegmentProcessPoint
                });
                return;
            }

            this.$emit("changePoint", {
                process: 0 - this.currentSegmentProcessPoint
            });
        }
    }
};
</script>
<style>
.form-l1 .time .bootstrap-datetimepicker-widget {
    max-width: 150px;
}
</style>
<style scoped lang="scss">
.dashboard-block-segment {
    padding-left: 6px;
    position: relative;
}
.dashboard-block-segment .row {
    margin-left: 0 !important;
}
.dashboard-block-segment:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 100%;
}

.block-segment-gray:after {
    background: gray;
    border: 1px solid #333;
}
.block-segment-blue:after {
    background: #eedcf2;
    border: 1px solid #333;
}
.block-segment-pink:after {
    background: pink;
    border: 1px solid #333;
}
.block-segment-green:after {
    background: green;
    border: 1px solid #333;
}

.block-segment-white:after {
    background: white;
    border: 1px solid #333;
}

.dashboard-create-first-support {
    display: flex;
    flex-direction: column;
    height: 100%;

    .contentForm {
        flex: 1 1 0;
    }
    .required:after {
        content: " *";
        color: red;
        font-weight: 100;
    }

    #myTabContent {
        overflow: auto;
        overflow-x: hidden;
        flex: 1 1 0;
        padding-left: 5px;
        padding-right: 15px;
    }
    .footer-button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.middle-row {
    margin-top: 0.5rem;
}

.dots-the-end {
    text-overflow: ellipsis;
}

.text-gray {
    color: #777777;
    font-size: small;
}
</style>
