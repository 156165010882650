<template>
    <div class="dashboard-support-flow">
        <div class="container-support-flow">
            <div class="row container-title mb-3 ml-0">
                <div class="col-12 d-flex justify-space-between p-0 pl-2" :class="statusSegmentColor(flow)">
                    <span>
                        <!-- 支援完了 -->
                        <span v-if="flow?.support_flow_status == this.SUPPORT_COMPLETE">
                            支援完了
                        </span>
                        <!-- 途中終了 -->
                        <span v-else-if="flow?.support_flow_status == this.END_HALFWAY">
                            途中終了 <span v-if="flow?.support_stop_reason">({{ reasonTerminate[flow?.support_stop_reason]
                            }})</span>&nbsp;
                            <span v-if="flow?.support_stop_memo">
                                <i class="fas fa-pencil cursor-pointer " @click="is_show_reason_stop_halfway = !is_show_reason_stop_halfway"></i>
                            </span>
                            &emsp;&emsp;
                            <span v-if="reception.health_guidance_div == HEALTH_GUIDANCE_DIV.active_support">
                                途中終了設定日：{{ formatTime(flow?.support_stop_setting_date, 'YYYY-MM-DD') }}
                            </span>
                        </span>
                        <!-- 初回面接辞退 -->
                        <span v-else-if="flow?.support_flow_status == this.FIRST_INTERVIEW_DECLINE">
                            初回面接辞退 <span v-if="flow?.support_flow_status">({{reasonTerminate[flow?.support_decline_setting_reason]}})</span>
                            <span v-if="flow?.support_decline_setting_memo">&nbsp;
                                <i class="fas fa-pencil cursor-pointer " @click="is_show_reason_first_decline = !is_show_reason_first_decline"></i>
                            </span>
                            &emsp;&emsp;
                            <span v-if="reception.health_guidance_div == HEALTH_GUIDANCE_DIV.active_support">
                                初回面接辞退設定日： {{ formatTime(flow?.support_decline_setting_date, 'YYYY-MM-DD') }}
                            </span>
                        </span>
                        <!-- 支援開始前 / 支援中 -->
                        <span v-else>
                            現在：{{ activeSegment?.health_support_form?.support_form_name }}
                        </span>
                    </span>
                    <span class="nav-link dropdown-toggle" v-if="canStop(reception, flow) || canReject(reception, flow)"
                        id="select" data-bs-placement="top" data-bs-toggle="dropdown" aria-expanded="false"></span>
                    <ul class="dropdown-menu" aria-labelledby="select"
                        v-if="canStop(reception, flow) || canReject(reception, flow)">
                        <li class="dropdown-item" @click="isStop = true" v-if="canStop(reception, flow)">途中終了設定</li>
                        <li class="dropdown-item" @click="isRejectReview = true" v-if="canReject(reception, flow)">
                            初回面接辞退</li>
                    </ul>
                </div>
            </div>
            <template v-if="showPointReachAlert">
                <v-alert type="info" outlined class="mx-2 pa-2" style="font-size: 15px" >
                    今回の支援分のプロセス評価を加算すると180ポイントを超えます。<br/>最終評価メール送信してください。
                </v-alert>
            </template>
            <div class="d-flex mb-1 position-relative align-items-center pl-2 gap-3" v-if="flow">
                <div>
                    <!-- 第4期以降はアウトカム評価を使用する -->
                    <template v-if="useOutcomeEval">
                        <div class="d-flex">
                            <!-- 現在 -->
                            <div>
                                <div class="d-flex">
                                    <div class="point-label"></div>
                                    <div style="text-align: right; font-size: 12px; color: gray">現在</div>
                                </div>
                                <div class="d-flex">
                                    <div class="point-label">プロセス評価ポイント</div>
                                    <div style="text-align: right;">{{flow?.current_support_point}}</div>
                                </div>
                                <div class="d-flex">
                                    <div class="point-label">アウトカム評価ポイント</div>
                                    <div>{{flows[0]?.current_outcome_point}}</div>
                                </div>
                                <div class="d-flex point-sum">
                                    <div class="point-label">合計ポイント</div>
                                    <div class="font-weight-bold">{{currentTotalPoint}}/{{ flow?.requried_support_point }}</div>
                                </div>
                            </div>
                            <!-- 加算予定 -->
                            <div class="ml-3" v-if="planningPoint.process != 0 || planningPoint.outcome != 0">
                                <div class="d-flex">
                                    <div style="text-align: left; font-size: 12px; color: gray">加算予定</div>
                                </div>
                                <div class="d-flex">
                                    <div>{{flow?.current_support_point + planningPoint.process}}</div>
                                    <template v-if="planningPoint.process != 0">
                                        <div class="ml-auto">(
                                            <v-template v-if="planningPoint.process > 0">+</v-template>
                                            {{planningPoint.process}}
                                        )</div>
                                    </template>
                                </div>
                                <div class="d-flex">
                                    &nbsp;{{flow?.current_outcome_point + planningPoint.outcome}}
                                    <template v-if="planningPoint.outcome != 0">
                                        <div class="ml-auto">(
                                            <v-template v-if="planningPoint.outcome > 0">+</v-template>
                                            {{planningPoint.outcome}}
                                        )</div>
                                    </template>
                                </div>
                                <div class="d-flex point-sum">
                                    <!-- <div class="point-label"></div> -->
                                    <div class="font-weight-bold">{{currentTotalPoint + planningPoint.process + planningPoint.outcome}}/{{ flow?.requried_support_point }}</div>
                                    <div class="ml-3">(
                                        <v-template v-if="planningPoint.process + planningPoint.outcome > 0">+</v-template>
                                        {{ planningPoint.process + planningPoint.outcome }}
                                    )</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <span v-if="reception.health_guidance_div == HEALTH_GUIDANCE_DIV.active_support">
                            実施済みポイント &nbsp; {{flow?.current_support_point}}/{{ flow?.requried_support_point }}
                        </span>
                    </template>
              </div>
              <div class="d-flex">
                <div>
                    <span v-if="checkIconPhone()" class="d-grid text-center pr-5">
                        <v-icon style="font-size: 20px;" color="red" large>mdi-phone-in-talk</v-icon>
                        <span style="font-size: 10px; color: red;">督促</span>
                    </span>
                </div>
               <div>
                <span v-if="checkDeniedSupport()" class="d-grid text-center">
                  <v-icon style="font-size: 2rem;" color="yellow" large>mdi-alert-circle</v-icon>
                  <span style="font-size: 10px; color: red;">継続支援辞退</span>
                </span>
                <span v-if="checkIncompleteFirstSupport()" class="d-grid text-center">
                  <v-icon style="font-size: 2rem;" color="orange" large>mdi-alert-circle</v-icon>
                  <span style="font-size: 10px; color: red;">初回未完了</span>
                </span>
               </div>
              </div>
              <div style="margin-left: auto !important;">
                    <button style="margin-top: -8px;" type="button" class="btn btn-success" @click="openSlideFunc()">
                        {{ $t('dashboard_support.support_flow.flow_support') }}
                    </button>
                </div>
            </div>
            <div class="d-flex align-center gap-2 container-support-flow-segment">
                <template v-for="(flow) in flows">
                    <template v-for="(segment, index) in flow?.health_support_flow_segments">
                        <div class="segments-block" :key="`panel_support_${segment.id}`">
                            <SegmentBlock
                                :reception="reception"
                                :segment="segment"
                                :supportFlowStatus="flow?.support_flow_status"
                                :previewSegment="getPreviousSegment(flow?.health_support_flow_segments, index)"
                                :nextSegment="getNextSegment(flow?.health_support_flow_segments, index)"
                                :currentDataFlowSegment="currentDataFlowSegments" :systemUserRole="systemUserRole"
                                @showAssignTask="showAssignTaskFunc($event)"
                                @resetPoint="resetPoint()"
                                ></SegmentBlock>
                        </div>
                    </template>
                </template>
            </div>
        </div>
        <Slide>
            <EditFlowSupport :isShowNavigation="randomString" v-if="flows" :flows="flows" :time="new Date"></EditFlowSupport>
        </Slide>
        <Dialog :isShow="isShowDialogAssignTask" persistent :maxWidth="600">
            <AssignTasks
                :open="true"
                :segmentItem="segmentItem"
                :scheduled-first-support-datetime-assign="scheduledFirstSupportDatetime"
                :listSupportPlace="listSupportPlace" @closeDialog="isShowDialogAssignTask = false; segmentItem = null;"
                :useOutcomeEval="useOutcomeEval"
                @openActionAddress="onHandleOpenActionAddress" />
        </Dialog>
        <Dialog :isShow="isShowDialogAddress" persistent :maxWidth="400">
            <ActionAddress :addressEdit="addressEdit" :insurerId="insurerId" @closeDialog="onHandleCloseDialogAddress"
                @actionSuccess="getListSupportPlace" />
        </Dialog>

        <Dialog :isShow="isStop" persistent :maxWidth="400">
            <v-card>
                <v-container>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" @change="functionCheckDisabled()" type="checkbox"
                                    v-model="middleTerminate.support_stop_flag" id="support_stop_flag">
                                <label class="form-check-label" for="support_stop_flag">
                                    途中終了を設定する
                                </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <label class="form-label">
                                途中終了設定日
                                <span v-if="!checkDisabled" class="required"></span>
                            </label>
                            <date-picker autocomplete="off" v-model="middleTerminate.support_stop_setting_date"
                                :config="option_datetime" name="tel_datetime" :disabled="checkDisabled">
                            </date-picker>
                            <span class="text-danger">{{ checkValidate.date }}</span>
                        </div>
                        <div class="col-12">
                            <div class="">
                                <label class="form-label">
                                    理由
                                    <span v-if="!checkDisabled" class="required"></span>
                                </label>
                                <select :disabled="checkDisabled" name="" class="form-select"
                                    v-model="middleTerminate.support_stop_reason">
                                    <option value=""></option>
                                    <option v-for="(text, key) in reasonTerminate" :key="key" :value="key">{{ text }}
                                    </option>
                                </select>
                                <span class="text-danger">{{ checkValidate.reason }}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="">
                                <label class="form-label">
                                    メモ
                                </label>
                                <textarea :disabled="checkDisabled" name="" cols="30" rows="5" class="form-control"
                                    maxlength="500" v-model="middleTerminate.support_stop_memo">
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <v-card-actions class="d-flex align-content-center justify-content-between pl-0 pr-2">
                        <!-- <button type="button" class="btn btn-danger" @click="isStop = false">キャンセル -->
                        <button type="button" class="btn btn-danger" @click="closeMiddleTerminate()">キャンセル
                        </button>
                        <button type="button" class="btn btn-primary" @click="middleTerminateFuncConfirm">設定
                        </button>
                    </v-card-actions>
                </v-container>
            </v-card>
        </Dialog>

        <Dialog :isShow="isRejectReview" persistent :maxWidth="400">
            <v-card>
                <v-container>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-check">
                                <input @change="functionCheckDisabled2()" class="form-check-input" type="checkbox"
                                    v-model="rejectReviewOrg.support_stop_flag" id="setting_stop">
                                <label class="form-check-label" for="setting_stop">
                                    初回面接辞退を設定する
                                </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="">
                                <label class="form-label">
                                    初回面接辞退 設定日
                                    <span v-if="!checkDisabled2" class="required"></span>
                                </label>
                                <date-picker :disabled="checkDisabled2" autocomplete="off"
                                    v-model="rejectReviewOrg.support_decline_setting_date" :config="option_datetime"
                                    name="tel_datetime">
                                </date-picker>
                                <span class="text-danger">{{ checkValidate.date }}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="">
                                <label class="form-label">
                                    理由
                                    <span v-if="!checkDisabled2" class="required"></span>
                                </label>
                                <select :disabled="checkDisabled2" name="" class="form-select"
                                    v-model="rejectReviewOrg.support_decline_setting_reason">
                                    <option value=""></option>
                                    <option v-for="(text, key) in reasonDecline" :key="key" :value="key">{{ text }}
                                    </option>
                                </select>
                                <span class="text-danger">{{ checkValidate.reason }}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="">
                                <label class="form-label">
                                    メモ
                                </label>
                                <textarea :disabled="checkDisabled2" name="" cols="30" rows="5" class="form-control"
                                    maxlength="500" v-model="rejectReviewOrg.support_decline_setting_memo">
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <v-card-actions class="d-flex align-content-center justify-content-between pl-0 pr-2">
                        <button type="button" class="btn btn-danger" @click="closeRejectReviewOrg()">キャンセル
                        </button>
                        <button type="button" class="btn btn-primary" @click="rejectFirstReviewFuncConfirm">設定
                        </button>
                    </v-card-actions>
                </v-container>
            </v-card>
        </Dialog>
        <Dialog :isShow="is_show_reason_stop_halfway" :maxWidth="400" persistent>
            <v-card>
                <v-container>
                    <div class="row">
                        <div class="col-12">
                            <pre>{{ flow?.support_stop_memo }}</pre>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <button type="button" class="btn btn-outline-primary btn-sm"
                                @click="is_show_reason_stop_halfway = false">キャンセル
                            </button>
                        </div>
                    </div>
                </v-container>
            </v-card>
        </Dialog>
        <Dialog :isShow="is_show_reason_first_decline" :maxWidth="400" persistent>
            <v-card>
                <v-container>
                    <div class="row">
                        <div class="col-12">
                            <pre>{{ flow?.support_decline_setting_memo }}</pre>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <button type="button" class="btn btn-outline-primary btn-sm"
                                @click="is_show_reason_first_decline = false">キャンセル
                            </button>
                        </div>
                    </div>
                </v-container>
            </v-card>
        </Dialog>
        <Dialog :isShow="dialogConfirm" :maxWidth="500" persistent>
            <DialogConfirm :support_stop_flag="rejectReviewOrg.support_stop_flag" :check_text="true" :middle_terminate="false" @closeDialog="dialogConfirm = false" @confirmDialog="rejectFirstReviewFunc()" />
        </Dialog>
        <Dialog :isShow="dialogConfirmStopMiddle" :maxWidth="500" persistent>
            <DialogConfirm :support_stop_flag="middleTerminate.support_stop_flag" :check_text="true" :middle_terminate="true" @closeDialog="dialogConfirmStopMiddle = false" @confirmDialog="middleTerminateFunc()" />
        </Dialog>
    </div>
</template>
<script>
/*eslint-disable*/
import { mapGetters, mapMutations } from "vuex";
import { INITIAL_SUPPORT } from "@/config";
import Slide from "@/components/Slide";
import EditFlowSupport from "@/components/dashboard/FlowSupport/Edit";
import SegmentTitle from "@/components/dashboard/SegmentTitle";
import SegmentBlock from "@/components/dashboard/SegmentBlock";
import Dialog from "@/components/common/Dialog";
import AssignTasks from "@/components/dashboard/FlowSupport/AssignTasks";
import ActionAddress from "@/components/dashboard/FlowSupport/ActionAddress";
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import { SUCCESS, DASHBOARD } from "@/constants";
import mixins from "@/mixins"
const DashboardRepository = RepositoryFactory.get(DASHBOARD)
import DashboardService from "@/services/api/DashboardRepository";
import {
    CODE_STATUS_SUPPORT,
    CODE_REASON_TERMINATE,
    CODE_REASON_FIRST_DECLINE,
    SUPPORT_PROGRESS_PLAN,
    SUPPORT_PROGRESS_SCHEDULE,
    SUPPORT_PROGRESS_IN_PROGRESS,
    SUPPORT_PROGRESS_CONFIRM,
    SUPPORT_PROGRESS_CANCEL,
} from "@/common/constants";
import Repository from "../../../services/api/Repository";
import moment from "moment";
import DialogConfirm from "@/components/dashboard/DialogConfirm";

export default {
    name: "SupportFlow",
    mixins: [
        mixins
    ],
    props: {
        flows: null,
        reception: null,
        insurerId: null,
        systemUserRole: null,
        scheduledFirstSupportDatetime: null,
        planningPoint: { outcome: 0, process: 0 },
    },
    components: {
        ActionAddress,
        AssignTasks,
        Dialog,
        EditFlowSupport,
        Slide,
        SegmentTitle,
        SegmentBlock,
        DialogConfirm
    },
    data() {
        return {
            checkDisabled2: true,
            checkDisabled: true,
            dialogConfirm: false,
            dialogConfirmStopMiddle: false,
            segmentItem: null,
            showEditFlowSupport: false,
            is_show_reason_stop_halfway: false,
            is_show_reason_first_decline: false,
            BEFORE_START_SUPPORT: 10,
            SUPPORT_IN_PROGRESS: 20,
            SUPPORT_COMPLETE: 30,
            END_HALFWAY: 40,
            FIRST_INTERVIEW_DECLINE: 50,

            isStop: false,
            isRejectReview: false,
            codeStatusSupportFirst: 10,
            codeStatusSupportA: 20,
            codeStatusSupportB: 30,
            codeStatusSupportFinal: 40,
            codeSupportProgressConfirm: 40,
            supportProgressStatusPlan: 10,
            supportProgressStatusIntended: 20,
            supportProgressStatusConfirm: 40,
            currentStatus: "初回支援",
            isOpenDropdown: false,
            point: "60/180",
            initialSupport: INITIAL_SUPPORT,
            listTitleSupport: [],
            listFlowSupport: [],
            listSupportPlace: [],
            isShowDialogAssignTask: false,
            isShowDialogAddress: false,
            addressEdit: null,
            addressEditId: null,
            codeStatusSupport: null,
            reasonTerminate: [],
            reasonDecline: [],
            checkValidate: {
                date: null,
                reason: null,
                memo: null,
            },
            middleTerminate: {
                support_stop_flag: false,
                support_stop_setting_date: null,
                support_stop_reason: null,
                support_stop_memo: '',
            },
            middleTerminateOrg: {
                support_stop_flag: false,
                support_stop_setting_date: null,
                support_stop_reason: null,
                support_stop_memo: '',
            },
            rejectReview: {
                support_decline_setting_date: null,
                support_decline_setting_reason: null,
                support_decline_setting_memo: null,
                support_stop_flag: false,
            },
            rejectReviewOrg: {
                support_decline_setting_date: null,
                support_decline_setting_reason: null,
                support_decline_setting_memo: null,
                support_stop_flag: false,
            },
            CODE_FLOW_SUPPORTING: 20,
            flow: null,
            SUPPORT_PROGRESS_STATUS: {
                plan: SUPPORT_PROGRESS_PLAN,
                schedule: SUPPORT_PROGRESS_SCHEDULE,
                in_progress: SUPPORT_PROGRESS_IN_PROGRESS,
                confirm: SUPPORT_PROGRESS_CONFIRM,
                cancel: SUPPORT_PROGRESS_CANCEL,
            },
            HEALTH_GUIDANCE_DIV: {
                active_support: 1,
                motivational_support: 2,
                equivalent_support: 3
            },
            activeSegment: null,
            dataFlowSegments: [],
            currentDataFlowSegments: null,
            randomString: "",
        };
    },
    watch: {
        // flows() {},
        slideDisplay(data) {
            if (data === 'hide') {
                this.showEditFlowSupport = false;
            }
        },
        isRejectReview (newVal) {
            if (newVal ) {
                if (this.getRejectReviewOrg.support_stop_flag) {
                    this.rejectReviewOrg.support_decline_setting_date = this.getRejectReviewOrg.support_decline_setting_date
                    this.rejectReviewOrg.support_decline_setting_reason = this.getRejectReviewOrg.support_decline_setting_reason
                    this.rejectReviewOrg.support_decline_setting_memo = this.getRejectReviewOrg.support_decline_setting_memo
                    this.rejectReviewOrg.support_stop_flag = this.getRejectReviewOrg.support_stop_flag
                    this.checkDisabled2 = false
                }
            }
        },
        isStop (newVal) {
            if (newVal ) {
                if (this.getMiddleTerminate.support_stop_flag) {
                    this.middleTerminate.support_stop_setting_date = this.getMiddleTerminate.support_stop_setting_date
                    this.middleTerminate.support_stop_reason = this.getMiddleTerminate.support_stop_reason
                    this.middleTerminate.support_stop_memo = this.getMiddleTerminate.support_stop_memo
                    this.middleTerminate.support_stop_flag = this.getMiddleTerminate.support_stop_flag
                    this.checkDisabled = false
                }
            }
        }
    },
    async created() {
        this.filterFlowSupporting()
        this.filterActiveSegment()
        this.setupListTitleSupport()
        this.setupListSupport()
        this.getListSupportPlace()
        this.codeStatusSupport = await this.getCodeDefine(CODE_STATUS_SUPPORT);
        this.reasonTerminate= await this.getCodeDefine(CODE_REASON_TERMINATE);
        this.reasonDecline = await this.getCodeDefine(CODE_REASON_FIRST_DECLINE);
        this.flowsData();
    },
    computed: {
        ...mapGetters({
            receptionId: "dashboard/getReceptionId",
            slideDisplay: "slideDisplayStatus",
            getRejectReviewOrg: "interview/getRejectReviewOrg",
            getMiddleTerminate: "interview/getMiddleTerminate"
        }),
        option_datetime() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },

        /**
         * 合計ポイント
         */
        currentTotalPoint() {
            let point = 0;
            const flow = this.flows[0];
            // const flow = this.flow;
            if (!flow) {
                return 0;
            }
            point += flow.current_support_point ?? 0;
            point += flow.current_outcome_point ?? 0;
            return point;
        },

        /**
         * アウトカム評価を使用するかどうか
         * 第4期以降
         */
        useOutcomeEval() {
            if (!this.reception) {
                return false;
            }
            return this.reception.health_guidance_period >= 4;
        },

        /**
         * ポイント達成アラートを表示するかどうか
         */
        showPointReachAlert() {
            if (!this.flow) {
                return false;
            }
            // 加算予定なし
            if (this.planningPoint.process + this.planningPoint.outcome === 0) {
                return false;
            }
            // 既に到達
            if (this.flow.requried_support_point <= this.currentTotalPoint) {
                return false;
            }

            return  this.flow.requried_support_point <= (this.currentTotalPoint + this.planningPoint.process + this.planningPoint.outcome);
        }
    },
    methods: {
        /**
         * 督促アイコンを表示する場合true
         */
        checkIconPhone(){
            // 支援フローステータスが「支援実施中」以外ならば表示しない
            if (this.flows[0].support_flow_status != 20) return false;

            const checkData = this.flows[0].health_support_flow_segments.filter(x => 
                x.segment_status_01 && 
                !x.support_stop_flag && 
                (x.support_progress_status == 10 || x.support_progress_status == 20 || x.support_progress_status == 30)
            );
            if(checkData.length > 0){
                return true;
            }
            return false;
        },
        checkDeniedSupport() {
          const checkData = this.flows[0].support_decline_flag;
          if(checkData > 0){
            return true;
          }
          return false;
        },
        checkIncompleteFirstSupport() {
            return this.flows[0].incomplete_first_support_flag;
        },
        functionCheckDisabled() {
            this.checkValidate = {
                date: null,
                reason: null,
                memo: null,
            };
            this.checkDisabled = true;
            if (this.middleTerminate.support_stop_flag) {
                this.checkDisabled = false;
            }
        },
        functionCheckDisabled2() {
            this.checkValidate = {
                date: null,
                reason: null,
                memo: null,
            };
            this.checkDisabled2 = true;
            if (this.rejectReviewOrg.support_stop_flag) {
                this.checkDisabled2 = false;
            }
        },
        closeRejectReviewOrg () {
            this.rejectReviewOrg.support_stop_flag = false
            this.checkDisabled2 = true;
            this.isRejectReview = false;
            this.checkValidate = {
                date: null,
                reason: null,
                memo: null,
            };
            this.rejectReviewOrg= {
            support_decline_setting_date: null,
                support_decline_setting_reason: null,
                support_decline_setting_memo: null,
                support_stop_flag: false,
          }
        },

        closeMiddleTerminate () {
            this.middleTerminate.support_stop_flag = false;
            this.checkDisabled = true;
            this.isStop = false;
            this.checkValidate = {
                date: null,
                reason: null,
                memo: null,
            };
            this.middleTerminate = {
            support_stop_flag: false,
                support_stop_setting_date: null,
                support_stop_reason: null,
                support_stop_memo: '',
          }
        },

        flowsData() {
            this.dataFlowSegments = [];
            let dataFlows = this.flows[0].health_support_flow_segments;
            for (let i = 0; i < dataFlows.length; i++) {
                Number(
                    this.dataFlowSegments.push(parseInt(dataFlows[i].support_progress_status)));
            }
            this.currentDataFlowSegments = this.dataFlowSegments.includes(this.SUPPORT_PROGRESS_STATUS.confirm);
        },
        openSlideFunc() {
            this.showEditFlowSupport = true;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
        filterFlowSupporting() {
            if (this.flows !== null) {
                this.flow = JSON.parse(JSON.stringify(
                    this.flows[0]
                    // this.flows.find(flow => {
                    //     return parseInt(flow.support_flow_status) === this.CODE_FLOW_SUPPORTING
                    // })
                ))
                this.middleTerminate = JSON.parse(JSON.stringify(this.flow));
                this.rejectReviewOrg = JSON.parse(JSON.stringify(this.flow));
                this.middleTerminate.support_stop_flag = parseInt(this.middleTerminate.support_flow_status) === this.END_HALFWAY;
                this.rejectReviewOrg.support_stop_flag = parseInt(this.middleTerminate.support_flow_status) === this.FIRST_INTERVIEW_DECLINE;

                let tmpRejectReviewOrg = {
                    support_decline_setting_date: this.rejectReviewOrg.support_decline_setting_date,
                    support_decline_setting_reason: this.rejectReviewOrg.support_decline_setting_reason,
                    support_decline_setting_memo: this.rejectReviewOrg.support_decline_setting_memo,
                    support_stop_flag: this.rejectReviewOrg.support_stop_flag,
                }
                this.setRejectReviewOrg(tmpRejectReviewOrg);

                let tmpMiddleTerminate = {
                    support_stop_flag: this.middleTerminate.support_stop_flag,
                    support_stop_setting_date: this.middleTerminate.support_stop_setting_date,
                    support_stop_reason: this.middleTerminate.support_stop_reason,
                    support_stop_memo: this.middleTerminate.support_stop_memo,
                }
                this.setMiddleTerminate(tmpMiddleTerminate);
            }
        },
        filterActiveSegment() {
            this.activeSegment = this.flow?.health_support_flow_segments.filter(e => {
                return e.support_progress_status != this.SUPPORT_PROGRESS_STATUS.confirm
            })[0] ?? null;
        },
        async middleTerminateFuncConfirm() {
          await DashboardService.getReceptionFlowSegments(this.reception.id).then(res => {
            this.flow = res.data.data?.health_support_flows[0];
            if(!this.canStop(this.reception, res.data.data?.health_support_flows[0])) {
              this.$toast.open({
                message: "[E0701]入力エラー<br>データは途中終了 設定の実施ができないステータスです。",
                type: "error",
              });
            } else {
              this.dialogConfirmStopMiddle = true;
            }
          })
        },

        async middleTerminateFunc() {
            var checkValidate = true;
            this.checkValidate = {
                date: null,
                reason: null,
            };
            if (this.middleTerminate.support_stop_flag) {
                if (!this.middleTerminate.support_stop_setting_date) {
                    this.checkValidate.date = "途中終了設定日を入力してください。";
                    checkValidate = false;
                }
                if (!this.middleTerminate.support_stop_reason) {
                    this.checkValidate.reason = "理由を入力してください。";
                    checkValidate = false;
                }
            }

            if (checkValidate) {
                await DashboardRepository.middleTerminate(this.flow?.id, this.middleTerminate).then(res => {
                    this.isStop = false;
                    this.dialogConfirmStopMiddle = false;
                    this.middleTerminate = this.middleTerminateOrg;

                    if (res.data.data) {
                        let tmpMiddleTerminate = {
                            support_stop_flag: this.middleTerminate.support_stop_flag,
                            support_stop_setting_date: this.middleTerminate.support_stop_setting_date,
                            support_stop_reason: this.middleTerminate.support_stop_reason,
                            support_stop_memo: this.middleTerminate.support_stop_memo,
                        }
                        this.setMiddleTerminate(tmpMiddleTerminate);
                        this.$root.$emit("Dashboard.ReloadDataReception");
                        this.notifySuccess("途中終了の設定処理が完了しました。")
                    } else {
                        this.notifyError("途中終了の設定処理中にエラーが発生しました。")
                    }
                })
            } else {
                this.dialogConfirmStopMiddle = false;
            }
        },
        async rejectFirstReviewFuncConfirm() {
          await DashboardService.getReceptionFlowSegments(this.reception.id).then(res => {
            this.flow = res.data.data?.health_support_flows[0];
            if(!this.canReject(this.reception, res.data.data?.health_support_flows[0])) {
              this.$toast.open({
                message: "[E0701]入力エラー<br>初回面接辞退 設定の実施ができないステータスです。",
                type: "error",
              });
            } else {
              this.dialogConfirm = true;
            }
          })
        },
        async rejectFirstReviewFunc() {
            let checkValidate = true;
            this.checkValidate = {
                date: null,
                reason: null,
            };
            if (this.rejectReviewOrg.support_stop_flag) {
                if (!this.rejectReviewOrg.support_decline_setting_date) {
                    this.checkValidate.date = "途中終了設定日を入力してください。";
                    checkValidate = false;
                }
                if (!this.rejectReviewOrg.support_decline_setting_reason) {
                    this.checkValidate.reason = "理由を入力してください。";
                    checkValidate = false;
                }
            }
            if (checkValidate) {
                await DashboardRepository.rejectFirstReview(this.flow?.id, this.rejectReviewOrg).then(res => {
                    this.isRejectReview = false;
                    this.dialogConfirm = false;
                    this.middleTerminate = this.middleTerminateOrg;

                    if (res.data.data) {
                        let tmpRejectReviewOrg = {
                            support_decline_setting_date: this.rejectReviewOrg.support_decline_setting_date,
                            support_decline_setting_reason: this.rejectReviewOrg.support_decline_setting_reason,
                            support_decline_setting_memo: this.rejectReviewOrg.support_decline_setting_memo,
                            support_stop_flag: this.rejectReviewOrg.support_stop_flag,
                        }
                        this.setRejectReviewOrg(tmpRejectReviewOrg);
                        this.$root.$emit("Dashboard.ReloadDataReception");
                        this.notifySuccess("初回面接辞退の設定処理が完了しました。")
                    } else {
                        this.notifyError("初回面接辞退の設定処理中にエラーが発生しました。")
                    }
                })
            } else {
                this.dialogConfirm = false;
            }
        },
        onHandleClickDropdown(index, value = -1, indexItem = -1) {
            this.$refs[`panel_support_${index}`][0].classList.toggle("d-block")
            if (value === -1 || indexItem) return;
            this.isShowDialogAssignTask = true;
        },
        checkSupportFirst(segment) {
            return parseInt(segment.support_exec_div) === this.codeStatusSupportFirst;
        },
        checkSupportA(segment) {
            return parseInt(segment.support_exec_div) === this.codeStatusSupportA;
        },
        checkSupportB(segment) {
            return parseInt(segment.support_exec_div) === this.codeStatusSupportB;
        },
        checkMidEval(segment) {
            return segment.mid_eval_flg;
        },
        // checkSupportFinal(segment) {
        //     return parseInt(segment.support_exec_div) === this.codeStatusSupportFinal;
        // },
        onHandleClickDropdownBlock(index, data) {
            this.$refs[`panel_support_${data.id}`][0].classList.remove("d-block")
            switch (index) {
                case 0:
                    this.setSegment(data);
                    if (this.checkSupportFirst(data)) {
                        this.$emit('block', 'L1');
                    }
                    if (this.checkSupportA(data) || this.checkSupportB(data) || this.checkMidEval(data)) {
                        this.$emit('block', 'L2');
                    }
                    if (this.checkSupportFinal(data)) {
                        this.$emit('block', 'L3');
                    }

                    break;
                case 1:
                    this.setSegmentBlockA(data);
                    break;
                case 2:
                    this.setSegmentBlockB(data);
                    break;
                case 3:
                    console.log(3);
                    break;
            }
        },
        formatTime(value, format) {
            if (typeof value === "undefined" || value == null) return null;
            return moment(value).format(format);
        },
        setupListTitleSupport() {
            // NOTE: おそらく使っていない
            this.listTitleSupport = [
                {
                    text: this.$t("dashboard_support.support_flow.task_assigment"),
                    value: 0
                },
                {
                    text: this.$t("dashboard_support.support_flow.column_support_1"),
                    value: 1
                },
                {
                    text: this.$t("dashboard_support.support_flow.column_support_2"),
                    value: 2
                },
                {
                    text: "直前支援をもとに評価",
                    value: 3
                }
            ]
        },
        setupListSupport() {
            this.listFlowSupport = [
                {
                    title: "初回支援",
                    schedule: {
                        requester: "保健一郎",
                        manager: "支援花子",
                        schedule_date: "04/03",
                        place: "6th Element West Lake",
                        form: "個別面談(20分)",
                    },
                    implementation: {
                        manager: "支援花子",
                        schedule_date: "04/05",
                        form: "個別面談(20分)",
                    }
                },
                {
                    title: "支援A",
                    schedule: {
                        requester: "保健一郎",
                        manager: "支援花子",
                        schedule_date: "04/03",
                        form: "個別面談(20分)",
                        point: "40pt"
                    },
                    implementation: {
                        manager: "支援花子",
                        schedule_date: "04/05",
                        form: "個別面談(20分)",
                        point: "40pt"
                    }
                },
                {
                    title: "支援B",
                    schedule: {
                        requester: "保健一郎",
                        manager: "支援花子",
                        schedule_date: "04/03",
                        form: "個別面談(20分)",
                        point: "40pt"
                    },
                    implementation: {
                        manager: "支援花子",
                        schedule_date: "04/05",
                        form: "個別面談(20分)",
                        point: "40pt"
                    }
                }
            ]
        },
        onHandleOpenActionAddress(address) {
            if (address) {
                this.addressEdit = {
                    ...address,
                    addressName: address.item_name
                }
            } else {
                this.addressEdit = null
            }
            this.isShowDialogAddress = true
        },
        onHandleCloseDialogAddress() {
            this.isShowDialogAddress = false
            this.addressEdit = null
        },
        showAssignTaskFunc(data) {
            this.isShowDialogAssignTask = true;
            this.segmentItem = data;
        },
        async getListSupportPlace() {
            this.showLoading()
            const response = await DashboardRepository.getListSupportPlace(this.insurerId)
            const {
                status,
                data
            } = response
            if (status === SUCCESS && data) {
                this.hideLoading()
                this.listSupportPlace = data?.data?.map(place => {
                    return {
                        ...place,
                        label: place.item_name,
                        value: place.id
                    }
                })
            } else {
                this.hideLoading()
                this.notifyError("Fetch data failed")
            }
        },

        /**
         * 直前の支援
         */
        getPreviousSegment(segments, index) {
            const currentSegment = segments[index];
            const activeSegments = segments.filter(seg => seg.support_progress_status != this.SUPPORT_PROGRESS_STATUS.cancel);
            const currentIndex = activeSegments.findIndex(seg => seg.id === currentSegment.id);
            return activeSegments[currentIndex - 1];
        },

        /**
         * 直後の支援
         */
        getNextSegment(segments, index) {
            const currentSegment = segments[index];
            const activeSegments = segments.filter(seg => seg.support_progress_status != this.SUPPORT_PROGRESS_STATUS.cancel);
            const currentIndex = activeSegments.findIndex(seg => seg.id === currentSegment.id);
            return activeSegments[currentIndex + 1];
        },


        resetPoint() {
            this.$emit("changePoint", {
                process: 0,
                outcome: 0
            });
        },


        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            openSlide: "showSlide",
            setSegmentBlockA: "dashboard/setSegmentBlockA",
            setSegmentBlockB: "dashboard/setSegmentBlockB",
            setSegment: "dashboard/setSegment",
            setRejectReviewOrg: "interview/setRejectReviewOrg",
            setMiddleTerminate: "interview/setMiddleTerminate",
        })
    }
}
</script>
<style lang="sass">
    @import "../../../styles/common"
    .segment-block
        position: relative
        margin-right: 13px
        &:before
            content: ">>"
            position: absolute
            right: -17px
            top: 50%


    .cursor-pointer
        cursor: pointer
    .container-support-flow
        max-height: 70%
        & .container-support-flow-segment
            overflow: scroll
            scroll-behavior: smooth
            overflow-y: hidden
            margin-bottom: 18px
        & .container-support
            min-width: 50%
            border: solid .25px $backgroundHoverPaginationItem
            position: relative
            & .container-panel
                width: 3%
                height: 100%
                top: 0
                position: absolute
        & .container-title
            background: $backgroundHoverPaginationItem
        & .container-sub-title
            & .border-left
                border-left: solid thin $black
            & select
                & option:first-child
                    display: none
        & table
            & tr td
                font-size: 12px
                padding: 10px 0
</style>
<style>
.segments-block:nth-last-child(-n+1)>*:last-child::before {
    content: " "
}

.z-999 {
    z-index: 999 !important;
}

.z-0 {
    z-index: 0 !important;
}

.point-label {
    width: 160px;
}

.point-sum {
    border-top: 1px solid #777777;
    padding-top: 2px;
    margin-top: 2px;
}

.right {
    text-align: right;
}
</style>
