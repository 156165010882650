<template>
  <div class="h-100" v-if="showTabs === 'support_last'">
    <!--  case showTabs can not check also because L3 default support last -->
    <div class="dashboard-assistance-area">
        <div class="d-flex flex-column contentForm" :class="classTagColor ? [classTagColor, 'dashboard-block-segment'] : ''">
            <TabContentInputSupport />
            <div id="myTabContent" class="p-3">
                <div class="tab-pane fade show active"
                        id="a"
                        role="tab"
                        aria-labelledby="a-tab">
                        <!-- 実施日 -->
                    <div class="form-group mb-3">
                        <label for="start_date" class="form-label required">実施日</label>
                        <date-picker
                                autocomplete="off"
                                :disabled="isViewSupportInput"
                                :config="optionDate"
                                name="start_date"
                                v-model="formData.start_date"
                                format="YYYY-MM-DD"
                                value-type="YYYY-MM-DD"
                                class="w-100"
                                input-class="form-control"
                        >
                        </date-picker>
                        <span class="text-danger">{{ errors.start_date }}</span>
                    </div>
                    <!-- 支援担当者 -->
                    <div class="form-group mb-3">
                        <label for="system_users_id" class="form-label required">支援担当者</label>
                        <select :disabled="isViewSupportInput" v-model="formData.system_users_id" name="system_users_id" id="system_users_id" class="form-select">
                            <option :value="null"></option>
                            <option v-for="item in optionLeader" :key="item.value" :value="item.value">
                                {{ item.text }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.system_users_id }}</span>
                    </div>
                    <!-- 実施者 -->
                    <div class="form-group mb-3">
                        <label for="system_users_leader_id" class="form-label required">実施者</label>
                        <select :disabled="isViewSupportInput" v-model="formData.system_users_leader_id" class="form-select">
                            <option :value="null"></option>
                            <option
                                    v-for="option in optionExecutor"
                                    :key="option.text"
                                    :value="(option.value == 99 ? 90 : option.value)"
                            >
                                {{ option.text }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.system_users_leader_id }}</span>
                    </div>
                    <!-- 支援形態 -->
                    <div class="form-group mb-3">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                支援形態<span class="required"></span>
                            </div>
                        </div>
                        <select :disabled="isViewSupportInput"
                        v-model="formData.health_support_form_id"
                        name="health_support_form_id"
                        id="health_support_form_id"
                        class="form-select">
                            <option
                                    v-for="option in optionHealthSupport"
                                    :key="option.value"
                                    :value="option.value"
                            >
                                {{ option.text }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.health_support_form_id }}</span>
                    </div>
                    <!-- 体重 -->
                    <div class="form-group mt-3">
                        <div class="d-flex justify-content-between">
                            <label for="weight" class="required">体重(kg)</label>
                            <span class="text-gray">健診時体重：{{ diagnosticWeight }} (kg)</span>
                        </div>
                        <input :disabled="isViewSupportInput" type="number" @blur="handleWeight" v-model="formData.weight" class="form-control" name="weight" step="0.1" min="0.0" max="999.9"/>
                        <span class="text-danger">{{ errors.weight }}</span>
                        <span class="text-danger" v-if="warns.weight != null"><br />{{ warns.weight }}</span>
                    </div>
                    <!-- 腹囲 -->
                    <div class="form-group mt-3">
                        <div class="d-flex justify-content-between">
                            <label for="waist" class="required">腹囲(cm)</label>
                            <span class="text-gray">健診時腹囲：{{ diagnosticWaist }} (cm)</span>
                        </div>
                        <input :disabled="isViewSupportInput" type="number" @blur="handleWaist" v-model="formData.waist" class="form-control" name="waist" step="0.1" min="0.0" max="999.9" />
                        <span class="text-danger">{{ errors.waist }}</span>
                        <span class="text-danger" v-if="warns.waist != null"><br />{{ warns.waist }}</span>
                    </div>
                    <template v-if="useOutcomeEvalCheck && false">
                        <div class="form-group">
                            <input
                                id="archive-1cm-1kg"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_1cm_1kg"
                                :disabled="true"
                            />
                            <label class="ml-1" for="archive-1cm-1kg">目標達成 (1cm・1kg)</label>
                        </div>
                        <div class="form-group mb-3">
                            <input
                                id="archive-2cm-2kg"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_2cm_2kg"
                                :disabled="true"
                            />
                            <label class="ml-1" for="archive-1cm-1kg">目標達成 (2cm・2kg)</label>
                        </div>
                    </template>


                    <!-- 食生活 -->
                    <div class="form-group mt-3 mb-1">
                        <label for="plan_eating" class="form-label required">
                            <template v-if="is4th">
                                行動目標（食習慣）について
                            </template>
                            <template v-else>
                                食生活に変化があったか
                            </template>
                        </label>
                        <select :disabled="isViewSupportInput" v-model="formData.plan_eating" name="plan_eating" id="plan_eating" class="form-select">
                            <option :value="null"></option>
                            <option v-for="planEating in optionPlanEating" :key="planEating.code" :value="planEating.code">
                                {{ planEating.name }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.plan_eating }}</span>
                    </div>
                    <template v-if="useOutcomeEval">
                        <div class="plan" v-if="firstPlans.activity_plan_eating">
                            計画：{{ firstPlans.activity_plan_eating }}
                        </div>
                        <div v-if="formData.is_achieved_plan_eating && formData.plan_eating==null" class="text-red">
                            評価内容とポイントが合っておりません。直前支援を確認してください。
                        </div>
                    </template>
                    <template v-if="useOutcomeEvalCheck && false">
                        <div class="form-group mb-3">
                            <input
                                id="archive-eating"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_eating"
                                :disabled="isViewSupportInput"
                                @change="onHandleChangeAchievedPlanEating"
                            />
                            <label class="ml-1" for="archive-eating">目標達成 (食習慣の改善)</label>
                        </div>
                    </template>

                    <!-- 運動 -->
                    <div class="form-group mt-3">
                        <label for="plan_activity" class="form-label required">
                            <template v-if="is4th">
                                行動目標（運動習慣）について
                            </template>
                            <template v-else>
                                運動について変化があったか
                            </template>
                        </label>
                        <select :disabled="isViewSupportInput" v-model="formData.plan_activity" name="plan_activity" id="plan_activity" class="form-select">
                            <option :value="null"></option>
                            <option v-for="planActivity in optionPlanActivity" :key="planActivity.code" :value="planActivity.code">
                                {{ planActivity.name }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.plan_activity }}</span>
                    </div>
                    <template v-if="useOutcomeEval">
                        <div class="plan" v-if="firstPlans.activity_plan_exercise">
                            計画：{{ firstPlans.activity_plan_exercise }}
                        </div>
                        <div v-if="formData.is_achieved_plan_exercise && formData.plan_activity==null" class="text-red">
                            評価内容とポイントが合っておりません。直前支援を確認してください。
                        </div>
                    </template>
                    <template v-if="useOutcomeEvalCheck && false">
                        <div class="form-group mb-3">
                            <input
                                id="archive-exercise"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_exercise"
                                :disabled="isViewSupportInput"
                                @change="onHandleChangeAchievedPlanExercise"
                            />
                            <label class="ml-1" for="archive-exercise">目標達成 (運動習慣の改善)</label>
                        </div>
                    </template>

                    <!-- 喫煙状況 -->
                    <div class="form-group mt-3">
                        <label for="plan_smocking" class="form-label required">
                            <template v-if="is4th">
                                行動目標（喫煙習慣）について
                            </template>
                            <template v-else>
                                喫煙状況について変化があったか
                            </template>
                        </label>
                        <select :disabled="isViewSupportInput" v-model="formData.plan_smocking" name="plan_smocking" id="plan_smocking" class="form-select">
                            <option :value="null"></option>
                            <option v-for="smokeChange in optionsSmokeChange" :key="smokeChange.code" :value="smokeChange.code">
                                {{ smokeChange.name }}
                            </option>
                        </select>
                        <span class="text-danger">{{ errors.plan_smocking }}</span>
                    </div>
                    <template v-if="useOutcomeEval">
                        <div class="plan" v-if="firstPlans.activity_plan_smoking">
                            計画：{{ firstPlans.activity_plan_smoking }}
                        </div>
                        <div v-if="formData.is_achieved_plan_smoking && formData.plan_smocking==null" class="text-red">
                            評価内容とポイントが合っておりません。直前支援を確認してください。
                        </div>
                    </template>
                    <template v-if="useOutcomeEvalCheck && false">
                        <div class="form-group mb-3">
                            <input
                                id="archive-smoking"
                                type="checkbox"
                                class="form-check-input"
                                v-model="formData.is_achieved_plan_smoking"
                                :disabled="isViewSupportInput"
                                @change="onHandleChangeAchievedPlanSmoking"
                            />
                            <label class="ml-1" for="archive-smoking">目標達成 (喫煙習慣の改善)</label>
                        </div>
                    </template>

                    <!-- 休養 -->
                    <template v-if="useOutcomeEval">
                        <div class="form-group mt-3 mb-1">
                            <label for="plan-rest" class="required">行動目標（休養習慣）について</label>
                            <select
                                id="plan-rest"
                                :disabled="isViewSupportInput"
                                v-model="formData.plan_rest"
                                name="plan_rest"
                                class="form-select"
                            >
                                <option :value="null"></option>
                                <option v-for="option in optionPlanActivity" :key="option.code" :value="option.code">
                                    {{ option.name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.plan_rest }}</span>
                        </div>
                        <template v-if="useOutcomeEval">
                            <div class="plan" v-if="firstPlans.activity_plan_rest">
                                計画：{{ firstPlans.activity_plan_rest }}
                            </div>
                            <div v-if="formData.is_achieved_plan_rest && formData.plan_rest==null" class="text-red">
                                評価内容とポイントが合っておりません。直前支援を確認してください。
                            </div>
                        </template>
                        <template v-if="useOutcomeEvalCheck && false">
                            <div class="form-group mb-3">
                                <input
                                    id="archive-rest"
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="formData.is_achieved_plan_rest"
                                    :disabled="isViewSupportInput"
                                    @change="onHandleChangeAchievedPlanRest"
                                />
                                <label class="ml-1" for="archive-rest">目標達成 (休養習慣の改善)</label>
                                {{ formData.plan_rest }} {{  typeof formData.plan_rest }}
                            </div>
                        </template>
                    </template>

                    <!-- その他の生活習慣 -->
                    <template v-if="useOutcomeEval">
                        <div class="form-group mt-3 mb-1">
                            <label for="plan-else" class="required">行動目標（その他の生活習慣）について</label>
                            <select
                                id="plan-else"
                                :disabled="isViewSupportInput"
                                v-model="formData.plan_else"
                                name="plan_else"
                                class="form-select"
                            >
                                <option :value="null"></option>
                                <option v-for="option in optionPlanActivity" :key="option.code" :value="option.code">
                                    {{ option.name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.plan_else }}</span>
                        </div>
                        <template v-if="useOutcomeEval">
                            <div class="plan text-gray" v-if="firstPlans.activity_plan_else">
                                計画：{{ firstPlans.activity_plan_else }}
                            </div>
                            <div v-if="formData.is_achieved_plan_else && formData.plan_else==null" class="text-red">
                                評価内容とポイントが合っておりません。直前支援を確認してください。
                            </div>
                        </template>
                        <template v-if="useOutcomeEvalCheck && false">
                            <div class="form-group mb-3">
                                <input
                                    id="archive-else"
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="formData.is_achieved_plan_else"
                                    :disabled="isViewSupportInput"
                                    @change="onHandleChangeAchievedPlanElse"
                                />
                                <label class="ml-1" for="archive-else">目標達成 (その他生活習慣の改善)</label>
                            </div>
                        </template>
                    </template>

                    <!-- 実績評価 -->
                    <div class="form-group mt-3">
                        <label for="final_status" class="form-label required">実績評価</label><span class="ml-5">( {{ formData.final_status?.length ?? 0 }} / {{ FINAL_STATUS_MAX_LEN }} )</span>
                        <textarea :disabled="isViewSupportInput" :maxlength="FINAL_STATUS_MAX_LEN" v-model="formData.final_status" name="final_status" class="form-control" id="final_status" cols="30" rows="10"></textarea>
                        <span class="text-danger">{{ errors.final_status }}</span>
                    </div>
                    <!-- 評価できない場合の確認回数 -->
                    <div class="form-group mt-3">
                        <label for="segment_not_review_count" class="form-label required">評価できない場合の確認回数</label>
                        <input :disabled="isViewSupportInput" type="number" v-model="formData.segment_not_review_count" class="form-control" name="segment_not_review_count"  min="0" max="99" />
                        <span class="text-danger">{{ errors.segment_not_review_count }}</span>
                    </div>
                </div>
            </div>
            <footer class="ml-0 mr-2">
                <div class="row form-group-footer footer-button">
                    <div class="col-3 pr-0" v-if="btnSaveDraftStatus">
                        <button @click="handleBeforeDialog('isDialogSaveDraft')" type="button" class="btn btn-secondary">一時保存</button>
                    </div>
                    <div class="col-3 pr-0" v-if="btnDeleteStatus">
                        <button @click="handleBeforeDialog('isDialogDelete')" type="button" class="btn btn-danger">確定解除</button>
                    </div>
                    <div class="col-3 pl-0" v-if="btnEditStatus">
                        <button @click="handleBeforeDialog('isDialogEdit')" type="button" class="btn btn-primary float-right">修正</button>
                    </div>
                    <div class="col-3 pl-0" v-if="btnConfirmStatus">
                        <button type="button" class="btn btn-primary float-right" @click="handleBeforeDialog('isDialogConfirm')">確定</button>
                    </div>
                </div>
            </footer>
        </div>
    </div>

    <Dialog :isShow="isDialogSaveDraft" persistent :maxWidth="600">
      <DialogSaveDraft @saveDraft="saveDraft" @closeDialog="onHandleCloseDialog('isDialogSaveDraft')" />
    </Dialog>
    <Dialog :isShow="isDialogDelete" persistent :maxWidth="600">
      <DialogDelete @deleteSegment="deleteSegment" @closeDialog="onHandleCloseDialog('isDialogDelete')" />
    </Dialog>
    <Dialog :isShow="isDialogEdit" persistent :maxWidth="600">
      <DialogEdit @editSegment="editSegment" @closeDialog="onHandleCloseDialog('isDialogEdit')" />
    </Dialog>
    <Dialog :isShow="isDialogConfirm" persistent :maxWidth="600">
      <DialogConfirm proc_name="確定" @confirmDialog="confirmDialog" @closeDialog="onHandleCloseDialog('isDialogConfirm')" />
    </Dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import mixins from "@/mixins";
import Dialog from "@/components/common/Dialog";
import DialogConfirm from "@/components/dashboard/DialogConfirm";
import DialogDelete from "@/components/dashboard/DialogDelete";
import DialogEdit from "@/components/dashboard/DialogEdit";
import DialogSaveDraft from "@/components/dashboard/DialogSaveDraft";
import L2 from "@/services/api/L2";
import L3 from "@/services/api/L3";
import AuthRepository from "@/services/api/AuthRepository";
import {INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import {
    ROLE_CODE_USER,
    CODE_ACTIVITY_PLAN,
    CODE_SMOCKING_PLAN,
    BTN_SAVE_DRAFT,
    BTN_CONFIRM,
    BTN_EDIT,
    BTN_DELETE,
    ENVS,
    SUPPORT_PROGRESS_PLAN,
    SUPPORT_PROGRESS_SCHEDULE,
    SUPPORT_PROGRESS_IN_PROGRESS,
    SUPPORT_PROGRESS_CONFIRM,
    TOAST_TIME_OUT,
    HEALTH_GUIDANCE_DIV_ASSERTIVE,
} from "@/common/constants";
import DashboardService from "@/services/api/DashboardRepository";
import TabContentInputSupport from "@/components/common/TabContentInputSupport";
import DatePicker from "vue2-datepicker";

import StringUtil from "@/functions/StringUtil";
const {
    toFullWidthString,
} = StringUtil();

export default {
    name: "L3",
    data() {
        return {
            optionLeader: [],
            ArrayLeader: [],
            optionExecutor: [],
            firstPlans: {},
            formData: {
                start_date: null,
                system_users_id: null,
                system_users_leader_id: null,
                health_support_form_id: null,
                weight: "0.0",
                waist: "0.0",
                plan_eating: null,
                plan_activity: null,
                plan_smocking: null,
                plan_rest: null,
                plan_else: null,
                segment_not_review_count: "0",
                final_status: null,
                is_achieved_plan_1cm_1kg: false,
                is_achieved_plan_2cm_2kg: false,
                is_achieved_plan_eating: false,
                is_achieved_plan_exercise: false,
                is_achieved_plan_smoking: false,
                is_achieved_plan_rest: false,
                is_achieved_plan_else: false,
            },
            errors: {
                final_status: null,
                health_support_form_id: null,
                plan_activity: null,
                plan_eating: null,
                plan_smocking: null,
                plan_rest: null,
                plan_else: null,
                system_users_id: null,
                system_users_leader_id: null,
                waist: null,
                weight: null,
                segment_not_review_count: null,
            },
            warns: {
                waist: null,
                weight: null
            },
            getSupportA: [],
            disableForm: false,
            optionCodeEatingPlan: [],
            optionCodeActivityPlan: [],
            optionCodeSmockingPlan: [],
            isDialogSaveDraft: false,
            isDialogDelete: false,
            isDialogEdit: false,
            isDialogConfirm: false,
            segmentData: [],
            optionHealthSupport: [],
            optionPlanEating: [],
            optionPlanActivity: [],
            optionsSmokeChange: [],
            schemaValidate: undefined,
            userPermission: [],
            supporterUserRole: false,
            backofficeUserRole: false,

            // support_progress_status_code
            PLAN: 10,
            SCHEDULE: 20,
            IN_PROGRESS: 30,
            CONFIRM: 40,

            FINAL_STATUS_MAX_LEN: 250,

            // button status
            btnSaveDraftStatus: false,
            btnConfirmStatus: false,
            btnEditStatus: false,
            btnDeleteStatus: false,
            getSupportProgressStatus: null,
            waistFirst : 0,
            weightFirst : 0,
            flagLoadFirst: true,
            isFormDataLoding: false,
            version: "v1",
            eval_div: null,
            evaluator_div: null,
            // 健診結果(検査項目)
            diagnosticList: [],
            // このセグメントでのポイント (付与済のポイント)
            currentSegmentOutcomePoint: 0,
        };
    },
    mixins: [
        mixins
    ],
    components: {
        Dialog,
        DialogSaveDraft,
        DialogEdit,
        DialogConfirm,
        DialogDelete,
        TabContentInputSupport,
        DatePicker
    },
    props: {
        roleSystem: [String, Number],
        previousSupportProgressStatus: [String, Number],
        nextSupportProgressStatus: [String, Number],
        classTagColor: [String],
        showTabs: [String],
        support_flow_status: [String],
        isViewSupportInput: [Boolean],
        reception: [Object, null],
        supportFlow: [Object, null],
        patient: [Object, Number],
    },
    async created() {
        this.userPermission = await this.getCodeDefine(ROLE_CODE_USER);
        await L3.getVersion("L1",this.segment).then((res) => {
            const { data, status } = res;
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            if (status !== SUCCESS) return;
            this.version = data.data.form_version;
            this.eval_div = data.data.support_eval_div;
            this.evaluator_div = data.data.evaluator_div;
        });
        await L3.getOptionByCode(null, this.eval_div, this.evaluator_div).then((res) => {
            const data = res.data.data;
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            var optionExecutor = "0001000032";
            this.optionExecutor = data[optionExecutor];
        });

        L3.getListSupportPerson().then((res) => {
            this.optionLeader = [];
            const data = [];
            this.ArrayLeader = res.data.data;
    
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }

            if(this.isSystemAdminOrBackOffice()){
                // システム管理者 or 事務局
                res.data.data.map((x) => {
                    data.push({
                        text: `[${"0".repeat((7 - String(x.id).length))}${x.id}] ${x.user_name_disp}`,
                        value: x.id,
                        user_occupation: x.user_occupation,
                    });
                });
            } else if(this.isSupporter(true)){
                // 支援担当者の場合

                // ログインユーザ
                this.user.text = `[${"0".repeat((7 - String(this.user.id).length))}${this.user.id}] ${this.user.user_name_disp}`,
                this.user.value = this.user.id;
                data.push(this.user);

                // 支援予定者.
                const user_scheduled_support_staff_id = res.data.data.filter(x => x.id == this.segment.scheduled_support_staff_id);
                if(user_scheduled_support_staff_id.length > 0){
                    let a = user_scheduled_support_staff_id[0];
                    a.text = `[${"0".repeat((7 - String(a.id).length))}${a.id}] ${a.user_name_disp}`;
                    a.value = a.id;
                    a.user_occupation = a.user_occupation;
                    data.push(a);
                }

                // 支援実施者.
                const user_support_staff_id = res.data.data.filter(x => x.id == this.segment.support_staff_id);
                if(user_support_staff_id.length > 0){
                    let b = user_support_staff_id[0];
                    b.text = `[${"0".repeat((7 - String(b.id).length))}${b.id}] ${b.user_name_disp}`;
                    b.value = b.id;
                    b.user_occupation = b.user_occupation;
                    data.push(b);
                }
            }

            this.optionLeader = _.uniqBy(data,"value");
        });

        L3.getHealthSupportFinal().then((res) => {
            this.optionHealthSupport = [];
            if (this.is4th) {
                res.data.data.map((x) => {
                    this.optionHealthSupport.push({
                        text: x.support_form_name_disp_01,
                        value: x.id,
                        status: x.support_amount_fix_flg,
                        min: x.min_support_amount,
                        map_system_code_03: x.map_system_code_03
                    });
                });
            } else {
                // 3期以前の互換性を保つ
                res.data.data.map((x) => {
                    if (x.support_form_name_disp_01.indexOf("遠隔") == -1) {
                        this.optionHealthSupport.push({
                            text: x.support_form_name_disp_01.replace("(対面)", ""),
                            value: x.id,
                            status: x.support_amount_fix_flg,
                            min: x.min_support_amount,
                            map_system_code_03: x.map_system_code_03
                        });
                    }
                });
            }
        });
        L3.getHealthSupportEvalCodesByCode().then((res) => {
            const dataAllCode = res.data.data;
            this.optionCodeEatingPlan = [];
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            dataAllCode.map((x) => {
                if (x.code == CODE_ACTIVITY_PLAN) {
                    this.optionCodeActivityPlan.push({
                        text: x.name,
                        value: x.id,
                    });
                }
                if (x.code == CODE_SMOCKING_PLAN) {
                    this.optionCodeSmockingPlan.push({
                        text: x.name,
                        value: x.id,
                    });
                }
            });
        });
        L3.getDatafirst(this.segment.id).then((res) => {
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            this.waistFirst = res.data.data.waist;
            this.weightFirst = res.data.data.weight;
        });

        L2.getFirstPlansBySegmentId(this.segment.id)
            .then((res) => {
                if (res.data) {
                    this.firstPlans = res.data;
                }
            });

        this.getOptionPlan();
        this.getList();

        this.buttonPermission();

        let that = this;
        this.$root.$on("reloadFormSupport", function() {
            that.clearSegment();
        })

        this.getSupportProgressStatus = null;
        L3.getSegmentDetail(this.segment.id).then((res) => {
            if(res.errorCode === INTERNAL_SERVER_ERROR) {
                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                return;
            }
            this.getSupportProgressStatus = res.data.data.support_progress_status;
            this.checkPermissionButton();
        });

        DashboardService.getHealthResult(this.patient.id).then(
            (result) => {
                this.diagnosticList = result.data.data.diagnostic_list;
            }
        );
    },
    watch: {
        "formData.system_users_id"() {
            if(!this.flagLoadFirst){
                if(this.formData.system_users_id && this.formData.system_users_id != undefined) {
                    let arrayLeader = [...this.ArrayLeader];
                    this.formData.system_users_leader_id = arrayLeader.filter(
                        (x) => x.id == this.formData.system_users_id
                    )[0].user_occupation;
                } else {
                    this.formData.system_users_leader_id = null;
                }
            }else{
                this.flagLoadFirst = false
            }
        },
        "formData.final_status": {
            handler: function(newVal, oldVal) {
                if (!this.isFormDataLoding){
                    if (newVal != null) {
                        this.formData.final_status = toFullWidthString(newVal);
                    }
                }
            },
            deep : false, //ネストされたオブジェクトも監視する deepがfalseだとオブジェクト内の値が変更されても処理が実行されない
            immediate: false // 初期読み込み時にも呼び出す
        },

        "formData.plan_eating": {
            handler: function(newValue, oldValue) {
                if (!this.useOutcomeEvalCheck) return;
                const isAchieved = newValue == 1;
                const outcomeEvalPoint= this.calcOutcomeEvalPoint({ is_achieved_plan_eating: isAchieved });
                this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                this.formData.is_achieved_plan_eating = isAchieved;
            },
            immediate: false,
        },

        "formData.plan_activity": {
            handler: function(newValue, oldValue) {
                if (!this.useOutcomeEvalCheck) return;
                const isAchieved = newValue == 1;
                const outcomeEvalPoint= this.calcOutcomeEvalPoint({ is_achieved_plan_exercise: isAchieved });
                this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                this.formData.is_achieved_plan_exercise = isAchieved;
            },
            immediate: false,
        },

        "formData.plan_smocking": {
            handler: function(newValue, oldValue) {
                if (!this.useOutcomeEvalCheck) return;
                const isAchieved = newValue == 1;
                const outcomeEvalPoint= this.calcOutcomeEvalPoint({ is_achieved_plan_smoking: isAchieved });
                this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                this.formData.is_achieved_plan_smoking = isAchieved;
            },
            immediate: false,
        },

        "formData.plan_rest": {
            handler: function(newValue, oldValue) {
                if (!this.useOutcomeEvalCheck) return;
                const isAchieved = newValue == 1;
                const outcomeEvalPoint= this.calcOutcomeEvalPoint({ is_achieved_plan_rest: isAchieved });
                this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                this.formData.is_achieved_plan_rest = isAchieved;
            },
            immediate: false,
        },

        "formData.plan_else": {
            handler: function(newValue, oldValue) {
                if (!this.useOutcomeEvalCheck) return;
                const isAchieved = newValue == 1;
                const outcomeEvalPoint= this.calcOutcomeEvalPoint({ is_achieved_plan_else: isAchieved });
                this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                this.formData.is_achieved_plan_else = isAchieved;
            },
            immediate: false,
        },
    },
    computed: {
        optionDate() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        ...mapGetters({
            "segment": "dashboard/getSegment",
            user: "common/getUser"
        }),


        segments() {
            if (!this.supportFlow) {
                return [];
            }
            return this.supportFlow.health_support_flow_segments;
        },

        /**
         * 第4期以降
         */
        is4th() {
            if (!this.reception) {
                return false;
            }
            return this.reception.health_guidance_period >= 4;
        },

        /**
         * アウトカム評価を使用するかどうか
         * 第4期以降
         */
        useOutcomeEval() {
            if (!this.reception) {
                return false;
            }
            return this.reception.health_guidance_period >= 4;
        },

        /**
         * 健診時の体重
         */
        diagnosticWeight() {
            if (this.diagnosticList.length == 0) {
                return 0;
            }
            const obj = this.diagnosticList.find((item) => item.healthexam_item_code == "9N006000000000001");
            if (obj && obj.healthexam_item_disp) {
                return Number(obj.healthexam_item_disp.replace("kg", "").replace(/ /, ""));
            }
            return null;
        },

        /**
         * 健診時の腹囲
         */
        diagnosticWaist() {
            if (this.diagnosticList.length == 0) {
                return 0;
            }
            const obj = this.diagnosticList.find((item) => item.healthexam_item_code == "9N016160100000001");
            if (obj && obj.healthexam_item_disp) {
                return Number(obj.healthexam_item_disp.replace("cm", "").replace(/ /, ""));
            }
            return null;
        },

        /**
         * アウトカム評価項目を表示するかどうか
         * 第4期以降で「動機付け支援」「動機付支援相当」の場合に表示する
         * (継続支援・中間評価がないため実績評価時に評価する)
         */
        useOutcomeEvalCheck() {
            if (!this.useOutcomeEval) {
                return false;
            }
            if (this.reception && this.reception.health_guidance_div == HEALTH_GUIDANCE_DIV_ASSERTIVE) {
                return false;
            }
            return true;
        }
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        /**
         * ワーニングクリア.
         */
        clearValidationWarns() {
            for (const prop in this.warns) {
                this.warns[prop] = null;
            }
        },
        /**
         * エラークリア.
         */
        clearValidationErrors() {
            for (const prop in this.errors) {
                this.errors[prop] = null;
            }
        },
        /**
         * バリデーションエラーが存在する場合true.
         */
        hasValidationError() {
            for (const prop in this.errors) {
                if (this.errors[prop] != null) return true;
            }
            return false;
        },
        /**
         * バリデーション.
         */
        validate() {
            this.clearValidationErrors();
            this.clearValidationWarns();

            // 実施日.
            if (!this.formData.start_date) {
                this.errors.start_date = "実施日を入力してください。";
            }
            // 支援担当者.
            if (!this.formData.system_users_id) {
                this.errors.system_users_id = "支援担当者を選択してください。";
            }
            // 実施者
            if (!this.formData.system_users_leader_id) {
                this.errors.system_users_leader_id = "実施者を選択してください。";
            }
            // 支援形態.
            if (!this.formData.health_support_form_id) {
                this.errors.health_support_form_id = "支援形態を選択してください。";
            }

            // 体重
            if (this.formData.weight === "") {
                this.errors.weight = "体重を入力してください。";
            }
            if (this.formData.weight && (this.formData.weight < 0.1 || this.formData.weight > 999.9)) {
                this.errors.weight = "体重は[0.1〜999.9]の範囲で入力してください。";
            }
            // 腹囲
            if (this.formData.waist === "") {
                this.errors.waist = "腹囲を入力してください。";
            }
            if (this.formData.waist && (this.formData.waist < 0.1 || this.formData.waist > 999.9)) {
                this.errors.waist = "腹囲は[0.1〜999.9]の範囲で入力してください。";
            }

            // 食生活について
            if (!this.formData.plan_eating) {
                this.errors.plan_eating = "食生活について変化があったかを選択してください。";
            }
            // 運動について
            if (!this.formData.plan_activity) {
                this.errors.plan_activity = "運動について変化があったかを選択してください。";
            }
            // 喫煙状況について
            if (!this.formData.plan_smocking) {
                this.errors.plan_smocking = "喫煙状況について変化があったかを選択してください。";
            }

            if (this.useOutcomeEval) {
                // 休養状況について
                if (!this.formData.plan_rest) {
                    this.errors.plan_rest = "休養状況について変化があったかを選択してください。";
                }
                // その他の生活習慣について
                if (!this.formData.plan_else) {
                    this.errors.plan_else = "その他の生活習慣について変化があったかを選択してください。";
                }
            }

            // 実績評価.
            if (!this.formData.final_status) {
                this.errors.final_status = "実績評価を入力してください。";
            }
            if (this.formData.final_status?.length > this.FINAL_STATUS_MAX_LEN) {
                this.errors.final_status = `実績評価は${this.FINAL_STATUS_MAX_LEN}文字以下で入力してください。`;
            }

            // 評価できない場合の確認回数
            if (this.formData.segment_not_review_count === "") {
                this.errors.segment_not_review_count = "評価できない場合の確認回数を入力してください。";
            }
            if (this.formData.segment_not_review_count < 0 || this.formData.segment_not_review_count > 99) {
                this.errors.segment_not_review_count = "評価できない場合の確認回数は[0〜99]の範囲で入力してください。";
            }

            const isValid = !this.hasValidationError();

            if (!isValid) {
                this.notifyInputInvalidToast();
            }

            // バリデーションエラーが存在しなければtrue(バリデーションOK).
            return isValid;
        },

        /**
         * 体重・腹囲の達成を計算
         */
        calcOutcomeEvalPointWeightAndWaist({weightCurrent, waistCurrent}) {
            if (this.useOutcomeEvalCheck) {
                const currentWeight = Math.round(Number(weightCurrent) * 100) / 100;
                const currentWaist = Math.round(Number(waistCurrent) * 100) / 100;

                // [計算対象外]
                // - 体重・腹囲が未入力
                if (!currentWeight || !currentWaist) {
                    this.formData.is_achieved_plan_1cm_1kg = false;
                    this.formData.is_achieved_plan_2cm_2kg = false;
                    const outcomeEvalPoint = this.calcOutcomeEvalPoint({
                        is_achieved_plan_1cm_1kg: false,
                        is_achieved_plan_2cm_2kg: false,
                    });
                    this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                    return;
                }

                // JSの浮動小数点演算の誤差を丸める
                const weightDiff = Math.round((this.diagnosticWeight - currentWeight) * 100) / 100;
                const waistDiff = Math.round((this.diagnosticWaist - currentWaist) * 100) / 100;

                // 1cm・1kg達成
                const isAchievedPlan1 = weightDiff >= 1 && waistDiff >= 1;

                // 2cm・2kg達成
                // (当該年の健診時の体重の値に、0.024を乗じた体重（kg）以上かつ同体重と同じ値の腹囲（cm）以上減少している)
                const targetDiff = Math.min(this.diagnosticWeight * 0.024, 2);
                const isAchievedPlan2 = (
                    (weightDiff >= targetDiff) && (waistDiff >= targetDiff)
                );

                this.formData.is_achieved_plan_1cm_1kg = isAchievedPlan1;
                this.formData.is_achieved_plan_2cm_2kg = isAchievedPlan2;
                const outcomeEvalPoint = this.calcOutcomeEvalPoint({
                    is_achieved_plan_1cm_1kg: isAchievedPlan1,
                    is_achieved_plan_2cm_2kg: isAchievedPlan2,
                });
                this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
            }
        },


        handleWeight(e) {
            // eslint-disable-next-line no-empty
            if(e.target.value != "" && (ENVS.WARN_INPUT_DIFF_WEIGHT < Math.abs(e.target.value - this.weightFirst))) {
                this.warns.weight = "初回支援時の体重と比較して大きな差があります。";
                this.notifyInputWarnToast("体重");
            } else {
                this.warns.weight = null;
            }
            this.calcOutcomeEvalPointWeightAndWaist({
                weightCurrent: e.target.value,
                waistCurrent: this.formData.waist,
            });
        },
        handleWaist(e) {
            // eslint-disable-next-line no-empty
            if(e.target.value != "" && (ENVS.WARN_INPUT_DIFF_WAIST < Math.abs(e.target.value - this.waistFirst))) {
                this.warns.waist = "初回支援時の腹囲と比較して大きな差があります。";
                this.notifyInputWarnToast("腹囲");
            } else {
                this.warns.waist = null;
            }
            this.calcOutcomeEvalPointWeightAndWaist({
                weightCurrent: this.formData.weight,
                waistCurrent: e.target.value,
            });
        },
        handleBeforeDialog(dialog_name) {
            if (dialog_name) {
                this[dialog_name] = true;
            }
        },
        handleControlCombobox() {
            if (this.getSupportA[0].status) {
                this.disableForm = true;
            }
        },


        /**
         * アウトカム評価ポイントの変更を反映
         */
        handleChangeOutcomeEvalPoint(point) {
            if (!this.isFirstSupport) {
                this.$emit("changePoint", {
                    outcome: point - this.currentSegmentOutcomePoint
                });
            }
        },


        /**
         * アウトカム評価ポイントを計算
         */
        calcOutcomeEvalPoint({
            is_achieved_plan_1cm_1kg = this.formData.is_achieved_plan_1cm_1kg,
            is_achieved_plan_2cm_2kg = this.formData.is_achieved_plan_2cm_2kg,
            is_achieved_plan_eating = this.formData.is_achieved_plan_eating,
            is_achieved_plan_exercise = this.formData.is_achieved_plan_exercise,
            is_achieved_plan_smoking = this.formData.is_achieved_plan_smoking,
            is_achieved_plan_rest = this.formData.is_achieved_plan_rest,
            is_achieved_plan_else = this.formData.is_achieved_plan_else,
        }) {
            let point = 0;
            if (is_achieved_plan_1cm_1kg) {
                point += this.OUTCOME_EVAL_POINT_1CM_1KG;
            }
            if (is_achieved_plan_2cm_2kg) {
                point += this.OUTCOME_EVAL_POINT_2CM_2KG;
            }
            if (is_achieved_plan_eating) {
                point += this.OUTCOME_EVAL_POINT_EATING;
            }
            if (is_achieved_plan_exercise) {
                point += this.OUTCOME_EVAL_POINT_EXERCISE;
            }
            if (is_achieved_plan_smoking) {
                point += this.OUTCOME_EVAL_POINT_SMOKING;
            }
            if (is_achieved_plan_rest) {
                point += this.OUTCOME_EVAL_POINT_REST;
            }
            if (is_achieved_plan_else) {
                point += this.OUTCOME_EVAL_POINT_ELSE;
            }
            return point;
        },

        /**
         * (目標達成の変更) 食事
         */
        onHandleChangeAchievedPlanEating(event) {
            const outcomeEvalPoint= this.calcOutcomeEvalPoint({ is_achieved_plan_eating: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * (目標達成の変更) 運動
         */
        onHandleChangeAchievedPlanExercise(event) {
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({ is_achieved_plan_exercise: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * (目標達成の変更) 喫煙
         */
        onHandleChangeAchievedPlanSmoking(event) {
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({ is_achieved_plan_smoking: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * (目標達成の変更) 休養
         */
        onHandleChangeAchievedPlanRest(event) {
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({ is_achieved_plan_rest: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        /**
         * (目標達成の変更) その他の生活習慣
         */
        onHandleChangeAchievedPlanElse(event) {
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({ is_achieved_plan_else: event.target.checked });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
        },

        // Input support area permission processing matrix
        // Current processing status: this.getSupportProgressStatus
        /**
         * 入力フォームボタン 画面下部ボタン表示制御.
         * 
         */
        async checkPermissionButton() {
            if (this.getSupportProgressStatus) {
                if (this.support_flow_status == 40 || this.support_flow_status == 50) {
                    // 途中終了 or 初回面接辞退の場合.
                    // × 一時保存
                    // × 確定
                    // × 修正
                    // × 確定解除
                    // × 支援内容クリア 
                    this.setPermissionButton(false, false, false, false, false);
                } else {
                    // 支援開始前 or 支援実施中 or 支援完了
                    if (this.isSupporter(true)) {
                        // 支援担当者の場合
                        // role is Person responsible for support
                        if (parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                            // 対象支援セグメントが確定されている場合.
                            // × 一時保存
                            // × 確定
                            // × 修正
                            // × 確定解除
                            // × 支援内容クリア 
                            this.setPermissionButton(false, false, false, false, false);
                        } else {
                            // 対象支援セグメントが「計画」or「予定」or「実施中」されている場合.
                            if (parseInt(this.previousSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                                // 直前セグメントが確定されている場合.
                                // ◯ 一時保存
                                // ◯ 確定
                                // × 修正
                                // × 確定解除
                                // × 支援内容クリア 
                                this.setPermissionButton(true, true, false, false, false);
                            } else {
                                // 直前セグメントが確定されていない場合.
                                // ◯ 一時保存
                                // × 確定
                                // × 修正
                                // × 確定解除
                                // × 支援内容クリア 
                                this.setPermissionButton(true, false, false, false, false);
                            }
                        }
                    } else {
                        // 「事務局」or「システム管理者」の場合
                        // role is system admin OR backoffice
                        if (parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                            // 対象支援セグメントが確定されている場合.
                            // × 一時保存
                            // × 確定
                            // ◯ 修正
                            // ◯ 確定解除
                            // × 支援内容クリア 
                            this.setPermissionButton(false, false, false, true, true);
                        } else if (parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_IN_PROGRESS) {
                            // 対象セグメントが「実施中」
                            if (parseInt(this.previousSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                                //直前セグメントが確定されている場合.
                                // case o => previous is confirm
                                // ◯ 一時保存
                                // ◯ 確定
                                // × 修正
                                // × 確定解除
                                // ◯ 支援内容クリア 
                                this.setPermissionButton(true, true, true, false, false);
                            } else {
                                //直前セグメントが確定されていない場合.
                                // case x => previous is not confirm
                                // ◯ 一時保存
                                // × 確定
                                // × 修正
                                // × 確定解除
                                // ◯ 支援内容クリア 
                                this.setPermissionButton(true, false, true, false, false);
                            }
                        } else if (parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_PLAN
                            || parseInt(this.getSupportProgressStatus) === SUPPORT_PROGRESS_SCHEDULE) {
                            // 対象セグメントが「計画」or「予定」
                            if (parseInt(this.previousSupportProgressStatus) === SUPPORT_PROGRESS_CONFIRM) {
                                //直前セグメントが確定orキャンセルされている場合.
                                // case o => previous is confirm
                                // ◯ 一時保存
                                // ◯ 確定
                                // × 修正
                                // × 確定解除
                                // × 支援内容クリア 
                                this.setPermissionButton(true, true, false, false, false);
                            } else {
                                //直前セグメントが確定されていない場合.
                                // case x => previous is not confirm
                                // ◯ 一時保存
                                // × 確定
                                // × 修正
                                // × 確定解除
                                // × 支援内容クリア 
                                this.setPermissionButton(true, false, false, false, false);
                            }
                        }
                    }
                }
            }
        },
        /**
         * フォーム下部のボタン表示制御
         * 
         * @param {*} save_draft_status 
         * @param {*} confirm_status 
         * @param {*} clear_status 
         * @param {*} delete_status 
         * @param {*} edit_status 
         */
        setPermissionButton(save_draft_status, confirm_status, clear_status, delete_status, edit_status) {
            this.btnSaveDraftStatus = save_draft_status;
            this.btnConfirmStatus = confirm_status;
            this.$emit("btnClearStatus", clear_status)
            this.btnDeleteStatus = delete_status;
            this.btnEditStatus = edit_status;
        },

        async buttonPermission() {
            this.setIsLoading(true);
            await AuthRepository.checkAuth()
                .then((res) => {
                    const { data, status } = res;
                    this.setIsLoading(false);
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }
                    if (status !== SUCCESS) return;

                    const handlePermissions = data.user.user_sys_auth_div;
                    const dataUserRole = handlePermissions.split(",");
                    this.supporterUserRole = false;
                    this.backofficeUserRole = false;
                    for (const [key] of Object.keys(this.userPermission)) {
                        if (dataUserRole.includes(String(key))) {
                            if (key == 2) {
                                this.supporterUserRole = true;
                            }
                            if (key == 4) {
                                this.backofficeUserRole = true;
                            }
                        }
                    }
                }).catch((err) => {
                    this.setIsLoading(false);
                });
        },
        async getOptionPlan() {
            L3.getOptionPlan(
                {
                    segment_id: this.segment.id
                }).then((res) => {
                this.optionPlanEating = res.data.data.plan_eating;
                this.optionPlanActivity = res.data.data.plan_activity;
                this.optionsSmokeChange = res.data.data.plan_smocking;
            });
        },
        /**
         * フォームデータロード.
         */
        async getList(){
            this.setIsLoading(true);
            await L3.list(this.segment.id)
                .then((res) => {
                    if (this.segment?.support_progress_status == SUPPORT_PROGRESS_PLAN
                        || this.segment?.support_progress_status == SUPPORT_PROGRESS_SCHEDULE) {

                        // 実施日の初期値：入力日
                        this.formData.start_date = moment(new Date()).format("YYYY-MM-DD");
                    }
                    const { data, status } = res;
                    this.setIsLoading(false);
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }
                    if (status !== SUCCESS) return;

                    this.isFormDataLoding = true;

                    if (data.data.segment_item) {
                        this.formData.system_users_id = data.data.segment_item.system_users_id;
                        this.formData.start_date = data.data.segment_item.start_date ?? null;
                        this.formData.system_users_leader_id = data.data.segment_item.system_users_leader_id ?? null;
                        this.formData.health_support_form_id = this.optionHealthSupport.filter(x => x.map_system_code_03 == data.data.segment_item.map_system_code_03)[0]?.value
                        this.formData.weight = data.data.segment_item.weight ? parseFloat(data.data.segment_item.weight).toFixed(1): "0.0";
                        this.formData.waist = data.data.segment_item.waist ? parseFloat(data.data.segment_item.waist).toFixed(1): "0.0";
                        this.formData.plan_eating = data.data.segment_item.plan_eating ?? null;
                        this.formData.plan_activity = data.data.segment_item.plan_activity ?? null;
                        this.formData.plan_smocking = data.data.segment_item.plan_smocking ?? null;
                        this.formData.plan_rest = data.data.segment_item.plan_rest ?? null;
                        this.formData.plan_else = data.data.segment_item.plan_else ?? null;
                        this.formData.final_status = data.data.segment_item.final_status ?? null;
                        this.formData.segment_not_review_count = data.data.segment_item.segment_not_review_count ?? null;

                        // 中間評価のみアウトカム評価の計算
                        // if (this.segment.mid_eval_flg) {
                        let outcomeEvalPoint = 0;
                        if (this.useOutcomeEval) {
                            this.formData.is_achieved_plan_1cm_1kg = Boolean(this.segment.is_achieved_plan_1cm_1kg ?? false);
                            this.formData.is_achieved_plan_2cm_2kg = Boolean(this.segment.is_achieved_plan_2cm_2kg ?? false);
                            this.formData.is_achieved_plan_eating = Boolean(this.segment.is_achieved_plan_eating ?? false);
                            this.formData.is_achieved_plan_exercise = Boolean(this.segment.is_achieved_plan_exercise ?? false);
                            this.formData.is_achieved_plan_smoking = Boolean(this.segment.is_achieved_plan_smoking ?? false);
                            this.formData.is_achieved_plan_rest = Boolean(this.segment.is_achieved_plan_rest ?? false);
                            this.formData.is_achieved_plan_else = Boolean(this.segment.is_achieved_plan_else ?? false);


                            outcomeEvalPoint = this.calcOutcomeEvalPoint({
                                is_achieved_plan_1cm_1kg: this.formData.is_achieved_plan_1cm_1kg,
                                is_achieved_plan_2cm_2kg: this.formData.is_achieved_plan_2cm_2kg,
                                is_achieved_plan_eating: this.formData.is_achieved_plan_eating,
                                is_achieved_plan_exercise: this.formData.is_achieved_plan_exercise,
                                is_achieved_plan_smoking: this.formData.is_achieved_plan_smoking,
                                is_achieved_plan_rest: this.formData.is_achieved_plan_rest,
                                is_achieved_plan_else: this.formData.is_achieved_plan_else,
                            });
                        }
                        this.currentSegmentOutcomePoint = outcomeEvalPoint;
                        this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
                    }

                    if(!this.formData.system_users_id){
                        this.flagLoadFirst = false;
                        this.formData.system_users_id = this.user.id;
                    }
                })
                .catch((err) => {
                    this.setIsLoading(false);
                })
                .finally(v=>{
                    this.isFormDataLoding = false;
                });
        },
        /**
         * 一時保存。
         */
        async saveDraft() {
            // ダイアログ閉じる.
            this.isDialogSaveDraft = false;
            // ローディング状態設定.
            this.setIsLoading(true);

            // バリデーションなしでバックエンド呼出.
            await L3.segmentContinue(this.segment.id, BTN_SAVE_DRAFT, this.formData)
                .then((res) => {
                    const { data, status, error } = res;
                    // ローディング状態設定解除.
                    this.setIsLoading(false);
    
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }

                    if (status !== SUCCESS) {
                        return this.$toast.open({
                            message: error.message,
                            type: "warning",
                        });
                    };

                    this.$toast.open({
                        message: "一時保存の登録が完了しました。",
                        type: "success",
                    });
                    this.$root.$emit("Dashboard.LoadPages", {id: this.segment.id})
                })
                .catch((err) => {
                    // ローディング状態設定解除.
                    this.setIsLoading(false);

                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                    this.$toast.open({
                        message: "error",
                        type: "error",
                    });
                });
        },
        /**
         * 確定解除.
         */
        async deleteSegment() {
            // ダイアログ閉じる.
            this.isDialogDelete = false;
            // ローディング状態設定.
            this.setIsLoading(true);
            // バリデーションなしでバックエンド呼出.
            await L3.segmentContinue(this.segment.id, BTN_DELETE, this.formData)
                .then((res) => {
                    const { data, status, error } = res;
                    // ローディング状態解除.
                    this.setIsLoading(false);
    
                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                        return;
                    }

                    if (status !== SUCCESS) {
                        return this.$toast.open({
                            message: error.message,
                            type: "warning",
                        });
                    };
                    this.$toast.open({
                        message: "確定解除処理が完了しました。",
                        type: "success",
                    });
                    this.$root.$emit("Dashboard.LoadPages", {id: this.segment.id})
                })
                .catch((err) => {
                    // ローディング状態解除.
                    this.setIsLoading(false);

                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                    this.$toast.open({
                        message: "error",
                        type: "error",
                    });
                });
        },

        /**
         * フォームデータクリア.
         */
        async clearSegment() {
            this.formData.start_date = new Date();
            this.formData.system_users_id = null;
            this.formData.system_users_leader_id = null;
            this.formData.health_support_form_id = null;
            this.formData.weight = "0.0";
            this.formData.waist = "0.0";
            this.formData.plan_eating = null;
            this.formData.plan_activity = null;
            this.formData.plan_smocking = null;
            this.formData.plan_rest = null;
            this.formData.plan_else = null;
            this.formData.final_status = null;
            this.formData.segment_not_review_count= "0";
            this.formData.is_achieved_plan_1cm_1kg = false;
            this.formData.is_achieved_plan_2cm_2kg = false;
            this.formData.is_achieved_plan_eating = false;
            this.formData.is_achieved_plan_exercise = false;
            this.formData.is_achieved_plan_smoking = false;
            this.formData.is_achieved_plan_rest = false;
            this.formData.is_achieved_plan_else = false;
            const outcomeEvalPoint = this.calcOutcomeEvalPoint({
                is_achieved_plan_1cm_1kg: false,
                is_achieved_plan_2cm_2kg: false,
                is_achieved_plan_eating: false,
                is_achieved_plan_exercise: false,
                is_achieved_plan_smoking: false,
                is_achieved_plan_rest: false,
                is_achieved_plan_else: false,
            });
            this.handleChangeOutcomeEvalPoint(outcomeEvalPoint);
            // this.handleChangeProcessEvalPoint(0);

            if(!this.formData.system_users_id){
                this.flagLoadFirst = false;
                this.formData.system_users_id = this.user.id;
            }
        },

        /**
         * 修正
         */
        editSegment() {
            // ダイアログ閉じる.
            this.isDialogEdit = false;
            this.validateForm(BTN_EDIT, "修正");
        },
        /**
         * 確定
         */
        confirmDialog() {
            // ダイアログ閉じる.
            this.isDialogConfirm = false;
            this.validateForm(BTN_CONFIRM, "確定");
        },
        // Input support area permission processing matrix
        onHandleCloseDialog(dialog_name) {
            if (dialog_name) {
                this[dialog_name] = false;
            }
        },

        /**
         * 修正 or 確定　共通処理
         *
         * @param {*} btn
         */
        async validateForm(btn, procName) {
            if (this.validate()) {
                // ローディング状態設定.
                this.setIsLoading(true);
                // ローディング状態設定.
                await L3.segmentContinue(this.segment.id, btn, this.formData)
                    .then((res) => {
                        const { data, status, error } = res;
                        // ローディング状態解除.
                        this.setIsLoading(false);
    
                        if(res.errorCode === INTERNAL_SERVER_ERROR) {
                            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                            return;
                        }

                        if (status !== SUCCESS) {
                            return this.$toast.open({
                                message: error.message,
                                type: "warning",
                            });
                        };

                        this.$toast.open({
                            message: `${procName}処理が完了しました。`,
                            type: "success",
                        });
                        this.$root.$emit("Dashboard.LoadPages", {id: this.segment.id})
                    })
                    .catch((err) => {
                        // ローディング状態解除.
                        this.setIsLoading(false);

                        err.inner.forEach((error) => {
                            this.errors[error.path] = error.message;
                        });
                        if (btn === BTN_EDIT) {
                            this.$toast.open({
                                message: "error",
                                type: "error",
                            });
                        }
                    });
            }
        },

    },
};
</script>

<style scoped lang="scss">
/*.dashboard-assistance-area {*/
/*  min-height: 300px;*/
/*  max-height: 300px;*/
/*  overflow: scroll;*/
/*  overflow-x: hidden;*/
/*}*/

.dashboard-assistance-area {
  display: flex;
  flex-direction: column;
  height: 100%;

  .contentForm {
    flex: 1 1 0;
  }
  .required:after {
    content: " *";
    color: red;
    font-weight: 100;
  }

  #myTabContent {
    overflow: auto;
    flex: 1 1 0;
  }
  .footer-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.dashboard-block-segment {
  padding-left: 6px;
  position: relative;
}
.dashboard-block-segment .position-relative {
  margin-left: 10px!important;
}
.dashboard-block-segment:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 100%;
}

.block-segment-gray:after {
  background: gray;
  border: 1px solid #333
}
.block-segment-blue:after {
  background: #eedcf2;
  border: 1px solid #333
}
.block-segment-pink:after {
  background: pink;
  border: 1px solid #333
}
.block-segment-green:after {
  background: green;
  border: 1px solid #333
}
.block-segment-white:after {
  background: white;
  border: 1px solid #333
}
.content-scroll {
    max-height: 353px;
    overflow-y: scroll;
    overflow-x: hidden
}

.text-red {
    color: red;
}

</style>
